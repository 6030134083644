import React, { Fragment, useState, useEffect, useContext } from "react";
import { UserContext } from "../app"
import mixpanel from "mixpanel-browser";
import { OrigNavBar } from "../components";

const Originator = () => {
  const {userProfile, setUser} = useContext(UserContext)
  const [display, setDisplay] = useState(false)

  useEffect(() => {
    mixpanel.track('Escrow Home Page', { 'action': 'pageview' })
  }, [,])
  useEffect (() => {
    if (userProfile.profileRole == 'creator'){
      setDisplay(true)
    } 
  }, []);
  return(
  <Fragment>
    {display 
    ? <OrigNavBar/>
    : <div class="d-flex justify-content-center mb-4 mt-4">
          <div className="spinner-border" role="status">
          <   span className="visually-hidden"></span>
          </div>
      </div>
    }
  </Fragment>
  )
};

export default Originator;