import React from "react"

const CloseOutButton = (props) => {

return(
    <div className='row rounded bg-white border border-light'>
        <button className="close ml-3 mb-2 mt-2" aria-label="Close" onClick={() => {props.onClickHandler()}}>
            <span aria-hidden='false'>&times;</span>
        </button>
    </div>
)
}
export default CloseOutButton;