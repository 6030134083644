// src/components/notary-file-upload.js

import React, { useState, Suspense, useEffect, Fragment} from 'react';

import { RestHelper } from '../../helper/restHelper';


const AdminAssignDirect = (data) => {

    const [showFileName, setShowFileName] = useState(true)
    const [showFiles, setShowFiles] = useState(false)
    const [notaryList, setNotaryList] = useState([])
    const [notaryCount, setNotaryCount] = useState('')
    const [notaryLastName, setNotaryLastName] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [searchForm, setSearchForm] = useState(true)
    const [showResults, setShowResults] = useState(false)
    const [selectedValue, setSelectedValue] = useState('')
    const [selectedNotary, setSelectedNotary] = useState('')
    const [responseMessage, setResponseMessage] = useState()
    const [showResponseMessage, setShowResponseMessage] = useState(false)

    const api = RestHelper()


    const findNotary = () => {
        const options = {}
        const reqData = {'action' : 'find', 'filter' : notaryLastName }
        api
            .postCreate('/manage-users-admin', options, reqData)
            .then(res => setNotaryResults(res))
            .catch(err => console.log(err))
      }

      const assignNotary = () => {
        setIsLoading(true)
        setShowResults(false)
        const options = {}
        const reqData = {'action' : 'assignNotary', 'newNotary' : selectedNotary, 'request_id': data.requestId }
        api
            .postCreate('/manage-appts-admin', options, reqData)
            .then(res => setResponse(res))
            .catch(err => console.log(err))
      }

    const setResponse = (res) => {
        setResponseMessage(res['message'])
        setIsLoading(false)
        setShowResponseMessage(true)
    }
    
      const onChangeEvent = (evt) => {
        const value = evt.target.value;
        setNotaryLastName(value)
    }
    const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true)
        setSearchForm(false)
        findNotary()
    }
    const setNotaryResults = (res) => {
        setNotaryList(res['notaryList'])
        setNotaryCount(res['notaryCount'])
        setIsLoading(false)
        setShowResults(true)
    }
    function searchAgain() {
        setNotaryLastName('')
        setShowResults(false)
        setSearchForm(true)
    }
    function handleSelect(evt) {
        setSelectedValue(evt)
        setSelectedNotary(evt.email)
        console.log(evt.email)
      }

  return (
    <div>
        {searchForm && (
        <form onSubmit={onSubmitHandler} className="needs-validation">
            <div className="row">
                <div className="col mb-3 mt-2">
                    <label htmlFor="notary" className='form-label'>Enter Signing Agent Last Name to Search:</label>
                    <input className='form-control' id="notary" onChange={(e)=>onChangeEvent(e)} type="text" value={notaryLastName} required/>
                </div>
            </div>
            <input className='btn btn-secondary rounded btn-md mb-3' type="submit" value="Search"/>
        </form>)}
        {showResults && (
                <div>
                    <h6 className="mx-2 mb-2 mt-2">Signing Agent Name: {notaryLastName} | Found Agents: {notaryCount}</h6>
                    <div className="container-fluid rounded">
                        <div >
                            <div style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                            <table className="table">
                                <thead>
                                    <tr scope="row">
                                        <th scope="col">Agent Name</th>
                                        <th scope="col">Location</th>
                                        <th scope="col">Phone</th>
                                        <th scope="col">Email</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {notaryList.map((items =>
                                        <tr 
                                            scope="row"
                                            key={items.RequestId}
                                            style={{
                                                cursor: 'pointer',
                                                backgroundColor: selectedValue === items ? 'lightgray' : 'white',
                                            }}
                                            onClick={() => handleSelect(items)}>
                                            <td>{items.firstName} {items.lastName}</td>
                                            <td>{items.city}, {items.notaryState}</td>
                                            <td>{items.mobilePhone}</td>
                                            <td>{items.email}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            </div> 
                        </div>
                     </div>
                    <div className="row">
                        <button className='btn btn-light border-secondary rounded btn-md mx-3 mb-3 col col-md-4' type='button' onClick={() => searchAgain()}>Search Again</button>
                        <button className='btn btn-light border-secondary rounded btn-md mx-3 mb-3 col col-md-4' type='button' onClick={() => assignNotary()}>Assign</button>
                    </div>
                </div>)}
        {isLoading && 
            (<div class="d-flex justify-content-center mb-4 mt-4">
                <div className="spinner-border" role="status">
                 <   span className="visually-hidden"></span>
                </div>
            </div>)}
        {showResponseMessage &&
        ( <div>{responseMessage}</div>)}

    </div>
  );
};

export default AdminAssignDirect;