import React, { Fragment } from "react";

import { ContactUsEscrow } from "../components";

const Escrow = () => (
  <Fragment>
    <ContactUsEscrow/>
  </Fragment>
);

export default Escrow;