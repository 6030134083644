import React, { Fragment, useEffect } from "react";
import mixpanel from "mixpanel-browser";
import { AutoHero, HomeContent } from "../components";

const Autos = () => {
  
  useEffect(() => {
    mixpanel.track('Auto Appraisal Home', {'action' : 'pageview'})
}, [,])
  
  return (
  <Fragment>
    <AutoHero />
    <hr />
    <HomeContent />
  </Fragment>
);}

export default Autos;