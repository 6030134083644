// src/components/admin/admin-nav-bar.js

import React, { useState, Fragment, useEffect, useContext } from 'react';
import { TabContent, TabNavItem, ProfNavBar } from '../../components';
import { useAuth0 } from '@auth0/auth0-react';
import { AdminUserMgmt, AdminCreateUser, AdminNewRequest, AdminReviewNotary, AdminViewFeedback, AdminImportUsers, AdminReviewNotaries, AdminManageFirms } from '../../components/admin';
import AdminReviewApptRequests from './admin-review-appt-req';
import { UserContext } from "../../app"


const AdminNavBar = () => {

    const [activeTab, setActiveTab] = useState("tab1")
    const {userProfile, setUser } = useContext(UserContext)
    const[isAdmin, setIsAdmin] = useState(false)
    const { user } = useAuth0();
    const { email } = user;
    const [profileLoaded, setProfileLoaded] = useState(true)

    useEffect (() => {
      if (userProfile.profileRole === 'admin'){
        setIsAdmin(true)
        setActiveTab("tab2")
      }
      else {
        setActiveTab("tab2")
      }
    }, [userProfile, setUser]);
    const setProfileStatus = (val) => {
      setProfileLoaded(val)
    }

    return (
    <div>
      <div className="nav-container mb-3">
        <div className="navbar navbar-expand-sm navbard-light bg-light">
          <nav className="nav nav-tabs nav-fill flex-column flex-sm-row">
            <TabNavItem title="Create New Request" id="tab1" activeTab={activeTab} setActiveTab={setActiveTab}/>
            <TabNavItem title="Assignments" id="tab2" activeTab={activeTab} setActiveTab={setActiveTab}/>
            <TabNavItem title="Create New User" id="tab3" activeTab={activeTab} setActiveTab={setActiveTab}/>
            <TabNavItem title="List Signing Agents" id="tab4" activeTab={activeTab} setActiveTab={setActiveTab}/>
            {isAdmin && <TabNavItem title="Pending Signing Agents" id="tab5" activeTab={activeTab} setActiveTab={setActiveTab}/>}
            <TabNavItem title="Firms" id="tab6" activeTab={activeTab} setActiveTab={setActiveTab}/>
            {isAdmin && <TabNavItem title="Feedback" id="tab7" activeTab={activeTab} setActiveTab={setActiveTab}/>}
          </nav>
        </div>
      </div>
      <div className="tab-content">
        <TabContent id="tab1" activeTab={activeTab}>
         <AdminNewRequest/>
        </TabContent>
        <TabContent id="tab2" activeTab={activeTab}>
          <AdminReviewApptRequests/>
        </TabContent>
        <TabContent id="tab3" activeTab={activeTab}>
          <AdminCreateUser/>
        </TabContent>
        <TabContent id="tab4" activeTab={activeTab}>
          <AdminReviewNotaries/>
        </TabContent>
        {isAdmin && <TabContent id="tab5" activeTab={activeTab}>
         <AdminReviewNotary/>
        </TabContent>}
        <TabContent id="tab6" activeTab={activeTab}>
         <AdminManageFirms/>
        </TabContent>
        {isAdmin && <TabContent id="tab7" activeTab={activeTab}>
         <AdminViewFeedback/>
        </TabContent>}
      </div>
    </div>
  );
};

export default AdminNavBar;