// src/components/notary-pending.js

import React, { useState, Fragment, useEffect, useContext } from 'react';
import { RestHelper } from '../../helper/restHelper'
import { UserContext } from "../../app"




const AdminReviewNotary = (profileEmail) => {
    const {userProfile, setUser } = useContext(UserContext)
    const [apiRes, setApiRes] = useState([])
    const [apiCount, setApiCount] = useState(0)
    const [apiUpdate, setApiUpdate] = useState([])
    const api = RestHelper()
   
    useEffect (() => {
      changeView();
    }, [apiUpdate, setApiUpdate]);
  
    // if Check Pending Requests is selected, we'll query for pending requests
  
    const loadApiResponse = (res) => {
      setApiRes(res['Notary'])
      setApiCount(res['Count'])
    }

    const changeView = () => {
      const options = {}
      api
          .postCreate('/get-pending-notary-registration', options, userProfile.profileEmail)
          .then(res => loadApiResponse(res))
          .catch(err => console.log(err))
      }

      const updateNotary = (props) => {
        const options = {}
        api
            .postCreate('/update-notary-registration', options, props)
            .then(res => setApiUpdate(res))
            .catch(err => console.log(err))
        }  
    
    const onSubmitHandler = (action) => {
        const checklist = []
        const approval = {'action': action}
        apiRes.forEach(item =>{
            const checkValue = document.getElementById(item.email)
            if (checkValue.checked){
                checklist.push({'email' : item.email})
            }
        })
        if (checklist.length > 0) {
            updateNotary([approval,checklist])
        }
    }
 
    return(
     <div className="container flex-grow-1">
          <div className="mt-5">
            <h6 className="muted">There are currently {apiCount} pending agent registrations:</h6>
            <div className="container-fluid rounded">
              <div className="row">
                <table className="table">
                <tbody>
                  <tr scope="row">
                  <th className="text-center" scope="col">Select</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Years Experience</th>
                  <th scope="col">State 1</th>
                  <th scope="col">State 2</th>
                  <th scope="col">State 3</th>
                  <th scope="col">State 4</th>
                  <th scope="col">State 5</th>
                  </tr>
                  {apiRes.map((items =>
                      <tr scope="row">
                          <td className="checkbox-inline text-center"><input className='form-check-input' id={items.email} type="checkbox" /></td>
                          <td>{items.firstName}</td>
                          <td>{items.lastName}</td>
                          <td>{items.notaryYears}</td>
                          <td>{items.certifiedState1}</td>
                          <td>{items.certifiedState2}</td>
                          <td>{items.certifiedState3}</td>
                          <td>{items.certifiedState4}</td>
                          <td>{items.certifiedState5}</td>
                      </tr>
                  ))}
                </tbody></table>
                <button className="btn btn-secondary mx-3 mb-3 border rounded" onClick={()=>onSubmitHandler('approve')}>Approve</button>
                <button className="btn btn-light mx-3 mb-3 border rounded" onClick={()=>onSubmitHandler('decline')}>Decline</button>
              </div>
            </div>
          </div>
    </div>
    );
}
export default AdminReviewNotary;