import React, { Fragment, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { RestHelperNoAuth } from "../helper/restHelperNoAuth";
import mixpanel from "mixpanel-browser";

import { Hero, HomeContent } from "../components";

const Preview = () => {

    const [message, setResponse] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [isInitialLoading, setIsInitialLoading] = useState(true)
    const [showAppt, setShowAppt] = useState(false)
    const [showClaimForm, setShowClaimForm] = useState(false)
    const [showCounterForm, setShowCounterForm] = useState(false)
    const [updateResponse, setUpdateResponse] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [isPending, setIsPending] = useState(false)
    const [isScheduled, setIsScheduled] = useState(false)
    const [counterBid, setCounterBid] = useState('')
    const [initialBid, setInitialBid] = useState('')
    const api = RestHelperNoAuth()

    const setInitialResponse = (res) => {
      setIsOpen(res[0]['status'])
      setIsPending(res[0]['statusPending'])
      setIsScheduled(res[0]['statusScheduled'])
      setInitialBid(res[0]['apptOffer'])
      setIsInitialLoading(false)
      setResponse(res)
    }

  useEffect(() => {
    mixpanel.track('Notary Preview', { 'action': 'preview' })
  }, [,])

    const getRequest = (params) => {
     api
      .get('/get-basic-request?' + params)
      .then(res => setInitialResponse(res))
      .catch(err => console.log(err))
    setIsLoading(false)
    setShowAppt(true)
    }
    const { id } = useParams();
    useEffect (() => {
        setIsLoading(true)
        getRequest(id);
      }, []);

    const updateRequest = (action, bid) => {
      const updateParams = {id, 'action': action, 'bid': bid}
      const options = {}
      mixpanel.track('Notary Preview', {'action': action})
      api
        .postCreate('/update-open-request', options,updateParams)
        .then(res => setUpdateResponse(res))
        .catch(err => console.log(err))
      setShowClaimForm(true)
    }

    const onChangeEvent = (evt) => {
      var value = evt.target.value;
      value = value.replace('$', '')
      setCounterBid(value)
    }

    const dateFormat = (inputDate) => {
      const dateArray = inputDate.split("-")
      const date = dateArray[1] + '/' + dateArray[2] + '/' + dateArray[0]
      return date
    }

    function convertTimeToAMPM(time24) {
      if (time24 === 'TBD') {
        return 'TBD'
      }
      var hours = parseInt(time24.substr(0, 2));
      var minutes = parseInt(time24.substr(3));
      var ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      var minutesStr = minutes < 10 ? '0' + minutes : minutes;
      var time12 = hours + ':' + minutesStr + ' ' + ampm;
      return time12;
    }

    const setFee = () => {
      const fee = '$' + counterBid
      return fee
  }

  const scanBackCheck = (checkFlag) => {
    var scanBackValue = ''
    if (checkFlag === 'true') {
      scanBackValue = 'SCAN BACKS ARE REQUIRED'
    }
    else{
      scanBackValue = 'NONE'
    }
    return scanBackValue

  }

  function setTransactionType(setValue) {
    if (setValue === '0') {
      return 'Not Defined'
    }
    else if (setValue === '1') {
      return 'Purchase'
    }
    else if (setValue === '2') {
      return 'Refinance'
    }
    else if (setValue === '3') {
      return 'Sellers Deed Set'
    }
    else if (setValue === '4') {
      return 'Other'
    }
    else {
      return 'Not Defined'
    }
  }

  function setFinanceType(setValue) {
    if (setValue === '10') {
      return 'Not Defined'
    }
    else if (setValue === '11') {
      return 'FHA'
    }
    else if (setValue === '12') {
      return 'Conventional'
    }
    else if (setValue === '13') {
      return 'Reverse'
    }
    else if (setValue === '14') {
      return 'HELOC'
    }
    else if (setValue === '15') {
      return 'Commercial'
    }
    else if (setValue === '16') {
      return 'VA'
    }
    else {
      return 'Not Defined'
    }
  }

    const onSubmitHandler = () => {
      setIsLoading(true)
      setShowClaimForm(false)
      setShowAppt(false)
      setShowCounterForm(false)
      updateRequest('counter', counterBid)
    }

    useEffect (() => {
      setIsLoading(false)
    }, [updateResponse, setUpdateResponse])

    const onClickHandler = (action) => {
      if (action == 'claim') {
        setIsLoading(true)
        setShowClaimForm(false)
        setShowAppt(false)
        updateRequest(action, initialBid)
      }
      if (action == 'counter') {
        setShowClaimForm(false)
        setShowAppt(false)
        setShowCounterForm(true)
      }
      if (action == 'decline') {
        setIsLoading(true)
        setShowClaimForm(false)
        setShowAppt(false)
        updateRequest(action, '')
      }
    }

    return (
        <Fragment>
          {isOpen
          ? <div>{showAppt && (
            <div className="container rounded border bg-white mt-4 mb-4">
             <div className="card mb-3 mt-3">
               <div className="card-body rounded border bg-light">
                 {message.map((items =>
                  <div>
                  <h5 className="card-title">Hi {items.apptNotary}, are you available for this signing?</h5>
                   <div className='card-body'>
                    <div className="row mb-3">
                     <label htmlFor="appt_date" className='form-label col'>When:</label>
                     <div className='col text-muted mx-3'>{dateFormat(items.apptDate)} at {convertTimeToAMPM(items.apptTime)} {items.apptTimeZone}</div>
                    </div>
                    <div className="row mb-3">
                     <label htmlFor="appt_city" className='form-label col'>Where:</label>
                     <div className='col text-muted mx-3'>{items.apptCity}, {items.apptState}  {items.apptZip}</div>
                    </div>
                    <div className="row mb-3">
                     <label htmlFor="appt_name" className='form-label col'>Notary Fee:</label>
                     <div className='col text-muted mx-3'>${items.apptOffer}</div>
                    </div>
                    <div className="row mb-3">
                    <label htmlFor="appt_lender" className='form-label col'>Lender:</label>
                     <div className='col text-muted mx-3'>{items.apptLender}</div>
                    </div>
                    <div className="row mb-3">
                     <label htmlFor="appt_lender" className='form-label col'>Scan Backs:</label>
                      <div className='col text-muted mx-3'>{scanBackCheck(items.scanBack)}</div>
                    </div>
                    <div className="row mb-3">
                     <label htmlFor="appt_lender" className='form-label col'>Transaction Type:</label>
                      <div className='col text-muted mx-3'>{setTransactionType(items.transactionType)}</div>
                    </div>
                    <div className="row mb-3">
                     <label htmlFor="appt_lender" className='form-label col'>Finance Type:</label>
                      <div className='col text-muted mx-3'>{setFinanceType(items.financeType)}</div>
                    </div>
                    <div className="row mb-3">
                     <label htmlFor="appt_name" className='form-label col'>Extra Notes:</label>
                     <div className='col text-muted mx-3'>{items.apptMessages}</div>
                    </div>
                    <div className="row mb-3">
                     <label htmlFor="appt_name" className='form-label col'>Status:</label>
                     <div className='col text-muted mx-3'>{items.apptNotaryApproval}</div>
                    </div>
                 </div>
                 </div>
                ))}
              <div className="card-body rounded">
                <div className="row mb-2">
                 <h6 className="card-subtitle mb-4 text-muted">Please select an option below to either claim the offer, propose a counter offer, or decline this offer:</h6>
                </div>
                <div className="row btn-group">
                 <button type='button' className="btn btn-dark rounded btn-default mx-3 mb-4" onClick={()=>onClickHandler('claim')}>Claim</button>
                 <button type='button' className="btn btn-white border-secondary rounded btn-default mx-3 mb-4" onClick={()=>onClickHandler('counter')}>Counter</button>
                 <button type='button' className="btn btn-secondary rounded btn-default mx-3 mb-4" onClick={()=>onClickHandler('decline')}>Decline</button>
                </div>
              </div>
            </div>  
           </div>
          </div>)}</div>
          : <div>
            {isPending  // if this is true a bid has been submitted but is pending approval
              ? (<div className="container rounded border bg-white mt-4 mb-4">
              <div className="card mb-3 mt-3">
                <div className="card-body rounded border bg-light">
                  {message.map((items =>
                   <div>
                   <h5 className="card-title">Hi {items.apptNotary}, this signing is currently in a pending status:</h5>
                    <div className='card-body'>
                     <div className="row mb-3">
                      <label htmlFor="appt_date" className='form-label col'>When:</label>
                      <div className='col text-muted mx-3'>{items.apptDate} at {convertTimeToAMPM(items.apptTime)} {items.apptTimeZone}</div>
                     </div>
                     <div className="row mb-3">
                      <label htmlFor="appt_city" className='form-label col'>Where:</label>
                      <div className='col text-muted mx-3'>{items.apptCity}, {items.apptState}  {items.apptZip}</div>
                     </div>
                     <div className="row mb-3">
                     <label htmlFor="appt_lender" className='form-label col'>Lender:</label>
                      <div className='col text-muted mx-3'>{items.apptLender}</div>
                     </div>
                     <div className="row mb-3">
                     <label htmlFor="appt_lender" className='form-label col'>Scan Backs:</label>
                      <div className='col text-muted mx-3'>{scanBackCheck(items.scanBack)}</div>
                     </div>
                     <div className="row mb-3">
                     <label htmlFor="appt_lender" className='form-label col'>Transaction Type:</label>
                      <div className='col text-muted mx-3'>{setTransactionType(items.transactionType)}</div>
                    </div>
                    <div className="row mb-3">
                     <label htmlFor="appt_lender" className='form-label col'>Finance Type:</label>
                      <div className='col text-muted mx-3'>{setFinanceType(items.financeType)}</div>
                    </div>
                     <div className="row mb-3">
                      <label htmlFor="appt_name" className='form-label col'>Extra Notes:</label>
                      <div className='col text-muted mx-3'>{items.apptMessages}</div>
                     </div>
                     <div className="row mb-3">
                      <label htmlFor="appt_name" className='form-label col'>Status:</label>
                      <div className='col text-muted mx-3'>{items.apptNotaryApproval}</div>
                     </div>
                    </div>
                   </div>))}
                </div>
                </div>
              </div>)
                : <div>{isScheduled  // if this is true a bid has been approved and scheduled
                  ? (<div className="container rounded border bg-white mt-4 mb-4">
                      <div className="card mb-3 mt-3">
                      <div className="card-body rounded border bg-light">
                      {message.map((items =>
                       <div>
                       <h5 className="card-title">Hi {items.apptNotary}, your offer has been approved and the signing is assigned to you:</h5>
                        <div className='card-body'>
                         <div className="row mb-3">
                          <label htmlFor="appt_date" className='form-label col'>When:</label>
                          <div className='col text-muted mx-3'>{dateFormat(items.apptDate)} at {convertTimeToAMPM(items.apptTime)} {items.apptTimeZone}</div>
                         </div>
                         <div className="row mb-3">
                          <label htmlFor="appt_city" className='form-label col'>Where:</label>
                          <div className='col text-muted mx-3'>{items.apptCity}, {items.apptState}  {items.apptZip}</div>
                         </div>
                         <div className="row mb-3">
                         <label htmlFor="appt_lender" className='form-label col'>Lender:</label>
                          <div className='col text-muted mx-3'>{items.apptLender}</div>
                         </div>
                         <div className="row mb-3">
                          <label htmlFor="appt_lender" className='form-label col'>Scan Backs:</label>
                          <div className='col text-muted mx-3'>{scanBackCheck(items.scanBack)}</div>
                        </div>
                        <div className="row mb-3">
                          <label htmlFor="appt_lender" className='form-label col'>Transaction Type:</label>
                          <div className='col text-muted mx-3'>{setTransactionType(items.transactionType)}</div>
                        </div>
                        <div className="row mb-3">
                          <label htmlFor="appt_lender" className='form-label col'>Finance Type:</label>
                          <div className='col text-muted mx-3'>{setFinanceType(items.financeType)}</div>
                        </div>
                         <div className="row mb-3">
                          <label htmlFor="appt_name" className='form-label col'>Extra Notes:</label>
                          <div className='col text-muted mx-3'>{items.apptMessages}</div>
                         </div>
                         <div className="row mb-3">
                          <label htmlFor="appt_name" className='form-label col'>Status:</label>
                          <div className='col text-muted mx-3'>{items.apptNotaryApproval}</div>
                         </div>
                      </div>
                      </div>))}
                      </div>
                      </div>
                      </div>
                      )
                  : <div>{isInitialLoading
                    ? (<div className="d-flex justify-content-center mb-4 mt-4">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden"></span>
                        </div>
                      </div>)
                    : ( <div className="container rounded border bg-white mt-4 mb-4">
                           <div className="card mb-3 mt-3">
                           <h5 className="card-title mx-3 mt-3 mb-3">This order has already been assigned.</h5>
                            <div className="card-body rounded border bg-light mx-2 mt-2 mb-2">
                              <h6 className="card-title text-muted">Thank you for your interest, however this order has already been assigned.  Please be on the lookout for future orders!</h6>
                              <h6 className="card-title">Pinpoint Field Services Team</h6>
                            </div>
                           </div>    
                          </div>)}
                  </div>}
              </div>
            }
            </div>}
          {showClaimForm &&
            (<div className="container rounded border bg-white mt-4 mb-4">
             <div className="card mb-3 mt-3">
               <div className="card-body rounded border bg-light">
                <h5 className="card-title text-muted">{updateResponse}</h5>
               </div>
            </div>            
            </div>)
          }
          {showCounterForm &&
            <div className="container rounded border bg-white mt-4 mb-4">
              <div className="card mb-3 mt-3">
                <div className="card-body rounded border bg-light">
                   <h5 className="card-title">Counter Offer</h5>
                   <form onSubmit={onSubmitHandler} className="needs-validation">
                   <div className="col mb-3 mt-2">
                    <label htmlFor="Bid" className='form-label'>Enter an offer in a whole dollar amount (Current Offer = ${initialBid}):</label>
                    <input className='form-control' id="counterOffer" onChange={(e)=>onChangeEvent(e)} pattern="^\$?\d+(\.\d{1,2})?$" type="tel" value={setFee()} required/>
                   </div>
                   <input className='btn btn-secondary rounded btn-md mb-3' type="submit" value="Submit"/>
                   </form>
                </div>
              </div>            
             </div>
          }
          {isLoading && 
                (<div className="d-flex justify-content-center mb-4 mt-4">
                    <div className="spinner-border" role="status">
                    <   span className="visually-hidden"></span>
                    </div>
                </div>)}
        </Fragment>
    )
   };

export default Preview;