import React, { Fragment, useEffect, useState, useContext} from "react";

import { RestHelper } from "../../helper/restHelper";
import { UserContext } from "../../app"
import mixpanel from "mixpanel-browser";


const AdminNewRequest = () => {

    const {userProfile, setUser } = useContext(UserContext)
    const [request, setRequest] = useState({
        apptName:"",
        apptDate:"",
        apptTime:"",
        apptTimeTBD: false,
        apptTimeZone: 'CT',
        apptStreet:"",
        apptCity:"",
        apptState:"",
        apptZip:"",
        apptOffer:"",
        email:"",
        appt_fee:"",
        comments:"",
        apptLender:"",
        userProfile:"",
        contactName:"",
        contactPhone1:"",
        contactPhone2:"",
        contactEmail1:"",
        contactEmail2:"",
        fileNumber:"",
        scanBack: false,
        transactionType: "",
        financeType: ""
    })
    const [profileLoaded, setProfileLoaded] = useState(false)
    const [editFlag, setEditFlag] = useState(true)
    const [errorMessage, setErrorMessage] = useState(false)
    const [showForm, setShowForm] = useState(true)
    const [apiMessage, setApiMessage] = useState('')
    const [apiDetails, setApiDetails] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [requireTime, setRequireTime] = useState(true)


    const api = RestHelper()

    const onChangeEvent = (evt) => {
        var value = evt.target.value;
        if (evt.target.id == 'apptOffer') {
            value = value.replace('$', '')
        }
        if (evt.target.id == 'appt_fee') {
            value = value.replace('$', '')
        }
        setRequest({
            ...request,
            [evt.target.id]: value
        })
    }
    
    useEffect(() => {
        mixpanel.track('Admin New', { 'action': 'pageview' })
    }, [,])

    useEffect(() => {
        setRequest({
            ...request,
            ['userProfile']: userProfile.profileEmail
        })
        const idArray = ['apptState']
        const stateArray = ['State', 'AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'HI', 
        'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 
        'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 
        'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY']
        idArray.forEach(value => {
            const stateVar = document.getElementById(value)
            stateArray.forEach(value2 => {
                const option = document.createElement('option')
                option.innerHTML = value2
                stateVar.appendChild(option)
            })
        })
    },[profileLoaded,setProfileLoaded]);
    //const stateVar = document.getElementById('certifiedState1Select')
    
    const clearErrorMessage = () => {
        setErrorMessage(false)
        setShowForm(true)
        setRequireTime(true)
        setProfileLoaded(!profileLoaded)
        setRequest({
            ...request,
            ['apptName'] : '',
            ['apptDate'] : '',
            ['apptTime'] : '',
            ['apptTimeTBD'] : false,
            ['apptTimeZone'] : 'CT',
            ['apptStreet'] : '',
            ['apptCity'] : '',
            ['apptState'] : '',
            ['apptZip'] : '',
            ['apptOffer'] : '',
            ['email'] : '',
            ['appt_fee'] : '',
            ['comments'] : '',
            ['apptLender'] : '',
            ['contactName'] : '',
            ['contactPhone1'] : '',
            ['contactPhone2'] : '',
            ['contactEmail1'] : '',
            ['contactEmail2'] : '',
            ['fileNumber'] : '',
            ['scanBack'] : false
        })
    }

    const updateTimeField = () => {

        if(requireTime){
          setRequireTime(false)
          setRequest({
            ...request,
            ['apptTime']: '00:00',
            ['apptTimeTBD'] : true
        })
        }
        else{
          setRequireTime(true)
          setRequest({
            ...request,
            ['apptTime']: '',
            ['apptTimeTBD'] : false
        })
        }
      }
    const addState = (evt) => {
        const value = evt.target.id
        setRequest({
            ...request,
            [value] : evt.target.value
        })
    }

    const setApiRes = (res) => {
        setErrorMessage(true)
        setShowForm(false)
        setIsLoading(false)
        mixpanel.track('Admin New', { 'action': 'order created' })
        setApiDetails(res['details'])
        setApiMessage(res['returnMessage'])
    }
    
    const setFee = () => {
        const fee = '$' + request.apptOffer
        return fee
    }

    const setServiceFee = () => {
        const fee = '$' + request.appt_fee
        return fee
    }


    const callApi = () => {
        const options = {}
        setIsLoading(true)
        setShowForm(false)
        api
        .postCreate('/create-appointment-new', options, JSON.stringify(request))
        .then(res => setApiRes(res))
        .catch(err => setApiRes(err))
    }

    const convertToDate = (updatedTime) => {
        const date = new Date(updatedTime*1000)
        return date
    }

    const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // If editFlag is true, the preview button was selected and the updated form values are ready for review
        if ( editFlag ){
            setEditFlag(false)
        }
        // If editFlag was false, the form was in a preview mode and ready to be submitted
        else {
            callApi()
            setEditFlag(true)
        }
    }

    return (
        <Fragment>
            {errorMessage && (
                <div className="container border rounded">
                <div className='row rounded bg-white'>
                 <button className="close ml-3 mb-2 mt-2" aria-label="Close" onClick={() =>clearErrorMessage()}>
                  <span aria-hidden='false'>&times;</span>
                 </button>
                </div>
                <div className="card mb-3 mt-3">
                    <div className="card-body rounded-border bg-white">
                        <h5 className='card-title'>Appointment Status</h5>
                        <h6 className='card-subtitle mb-2'>{ apiMessage }</h6>
                        <h5 className='card-subtitle mb-2'>{ apiDetails }</h5>
                    </div>
              </div>
              </div>
            )}
            {showForm && (
            <div className="container rounded border bg-white mt-4 mb-4" id="myInput">
                <div className='mb-3 mt-3 mx-3'><h4>Create New Event</h4></div>
            <div>
            <form onSubmit={(e)=>onSubmitHandler(e)} className="needs-validation">
             <div className="card mb-3 mt-3">
             <div className="card-body rounded border bg-light">
              <h5 className="card-title">Event Details</h5>
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2">
               <div className="col mb-3 mt-2">
                <label htmlFor="apptName" className='form-label'>Appointment Name</label>
                {editFlag
                ? <input className='form-control' id="apptName" onChange={(e)=>onChangeEvent(e)} type="text" value={request.apptName} required />
                : <input className='form-control' id="apptName" onChange={(e)=>onChangeEvent(e)} type="text" value={request.apptName} required readOnly/>}
               </div>
               <div className="col mb-3 mt-2">
                <label htmlFor="fileNumber" className='form-label'>File Number</label>
                {editFlag
                ? <input className='form-control' id="fileNumber" onChange={(e)=>onChangeEvent(e)} type="text" value={request.fileNumber} />
                : <input className='form-control' id="fileNumber" onChange={(e)=>onChangeEvent(e)} type="text" value={request.fileNumber} readOnly/>}
               </div>
              </div>
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-lg-4">
               <div className="col mb-3 mt-2">
                <label htmlFor="apptDate" className='form-label'>Date *</label>
                {editFlag
                ? <input className='form-control' id="apptDate" onChange={(e)=>onChangeEvent(e)} type="date" value={request.apptDate} required/>
                : <input className='form-control' id="apptDate" onChange={(e)=>onChangeEvent(e)} type="date" value={request.apptDate} required readOnly/>}
               </div>
               <div className="form-check col col-auto mb-3 mt-2">
                    <label className="form-check-label">
                        Time TBD
                    </label>
                    {editFlag
                    ? <input className="form-check-input mx-2" type="checkbox" value="" id="flexCheckDefault" onChange={() => {updateTimeField()}}/>
                    : <input className="form-check-input mx-2" type="checkbox" value="" id="flexCheckDefault" disabled/>}
                </div>
               <div className="col mb-3 mt-2">
                {requireTime
                ?(<div><label htmlFor="apptTime" className='form-label'>Time *</label>
                {editFlag
                ? <input className='form-control' id="apptTime" onChange={(e)=>onChangeEvent(e)} type="time" value={request.apptTime} required/>
                : <input className='form-control' id="apptTime" onChange={(e)=>onChangeEvent(e)} type="time" value={request.apptTime} required readOnly/>}</div>)
                : <div><label htmlFor="apptTime" className='form-label'>Time *</label><input className='form-control' id='apptTimeTBD' value='TBD' readOnly/></div>}
               </div>
               <div className="col mb-3 mt-2">
                <label htmlFor="apptTimeZone" className='form-label'>Timezone</label>
                {editFlag
                ? <select className='form-select form-select-sm mb-3 col' aria-label='form-select-sm' id="apptTimeZone" onChange={(e)=>onChangeEvent(e)}>
                  <option value='ET'>Eastern</option>
                    <option value='CT' selected>Central</option>
                    <option value='MT'>Mountain</option>
                    <option value='PT'>Pacific</option>
                    <option value='HT'>Hawaii</option>
                    <option value='AK'>Alaska</option>
                  </select>
                : <select className='form-select form-select-sm mb-3 col' aria-label='form-select-sm' id="apptTimeZone" onChange={(e)=>onChangeEvent(e)} disabled>
                    <option value='ET'>Eastern</option>
                    <option value='CT' selected>Central</option>
                    <option value='MT'>Mountain</option>
                    <option value='PT'>Pacific</option>
                    <option value='HT'>Hawaii</option>
                    <option value='AK'>Alaska</option>
                 </select>}
                </div>
              </div> 
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2">
               <div className="col mb-3 mt-2">
                <label htmlFor="apptStreet" className='form-label'>Appointment Location</label>
                {editFlag
                ? <input className='form-control' id="apptStreet" onChange={(e)=>onChangeEvent(e)} type="text" value={request.apptStreet} required />
                : <input className='form-control' id="apptStreet" onChange={(e)=>onChangeEvent(e)} type="text" value={request.apptStreet} required readOnly/>}
               </div>
              </div>
              <div className="row row-cols-1 row-cols-sm-1 row-cols-md-4 row-cols-lg-4">
               <div className="col mb-3 mt-2">
                <label htmlFor="apptCity" className='form-label'>City</label>
                {editFlag
                ? <input className='form-control' id="apptCity" onChange={(e)=>onChangeEvent(e)} type="text" value={request.apptCity} required/>
                : <input className='form-control' id="apptCity" onChange={(e)=>onChangeEvent(e)} type="text" value={request.apptCity} required readOnly/>}
               </div>
               <div className="col mb-3 mt-2">
               <label htmlFor="apptState" className='form-label col'>State</label>
                  {editFlag
                  ? <select className='form-select form-select-sm mb-3 col' aria-label='form-select-sm' id="apptState" onChange={(e)=>addState(e)}></select>
                  : <select className='form-select form-select-sm mb-3 col' aria-label='form-select-sm' id="apptState" onChange={(e)=>addState(e)} disabled></select>}
               </div>
               <div className="col mb-3 mt-2">
                <label htmlFor="apptZip" className='form-label'>Zip Code</label>
                {editFlag
                ? <input className='form-control' id="apptZip" maxLength="5" onChange={(e)=>onChangeEvent(e)} type="tel" value={request.apptZip} required/>
                : <input className='form-control' id="apptZip" onChange={(e)=>onChangeEvent(e)} type="tel" value={request.apptZip} required readOnly/>}
               </div>
              </div>
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3">
               <div className="col mb-3 mt-2">
                <label htmlFor="apptOffer" className='form-label'>Offered Closing Fee</label>
                {editFlag
                ? <input className='form-control' id="apptOffer" onChange={(e)=>onChangeEvent(e)} type="text" value={setFee()} />
                : <input className='form-control' id="apptOffer" onChange={(e)=>onChangeEvent(e)} type="text" value={setFee()} readOnly/>}
               </div>
               <div className="col mb-3 mt-2">
                <label htmlFor="apptLender" className='form-label'>Lender</label>
                {editFlag
                ? <input className='form-control' id="apptLender" onChange={(e)=>onChangeEvent(e)} type="text" value={request.apptLender} />
                : <input className='form-control' id="apptLender" onChange={(e)=>onChangeEvent(e)} type="text" value={request.apptLender} readOnly/>}
               </div>
              </div>
              <hr/>
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 mx-2">
                <label className="form-label mx-2 col">SCAN BACK REQUIRED?</label>
                <label className="form-label mx-2 col">
                {editFlag 
                ?   (<div><input className="mx-2" type="radio" name="scanBack" id="scanBack" onChange={(e)=>onChangeEvent(e)} value={true} required/>Required</div>)
                :   (<div><input className="mx-2" type="radio" name="scanBack" id="scanBack" onChange={(e)=>onChangeEvent(e)} value={true} disabled/>Required</div>)}
                </label>
                <label className="form-label mx-2 col">
                {editFlag
                ?   (<div><input className="mx-2" type="radio" name="scanBack" id="scanBack" onChange={(e)=>onChangeEvent(e)} value={false} required/>Not Required</div>)
                :   (<div><input className="mx-2" type="radio" name="scanBack" id="scanBack" onChange={(e)=>onChangeEvent(e)} value={false} disabled/>Not Required</div>)}
                </label>
              </div>
              <hr/>
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 mx-2">
                <label className="form-label mx-2 col">TYPE OF TRANSACTION:</label>
                <label className="form-label mx-2 col">
                {editFlag 
                ?   (<div><input className="mx-2" type="radio" name="transactionType" id="transactionType" onChange={(e)=>onChangeEvent(e)} value={'1'} required/>Purchase</div>)
                :   (<div><input className="mx-2" type="radio" name="transactionType" id="transactionType" onChange={(e)=>onChangeEvent(e)} value={'1'} disabled/>Purchase</div>)}
                </label>
                <label className="form-label mx-2 col">
                {editFlag
                ?   (<div><input className="mx-2" type="radio" name="transactionType" id="transactionType" onChange={(e)=>onChangeEvent(e)} value={'2'} required/>Refinance</div>)
                :   (<div><input className="mx-2" type="radio" name="transactionType" id="transactionType" onChange={(e)=>onChangeEvent(e)} value={'2'} disabled/>Refinance</div>)}
                </label>
                <label className="form-label mx-2 col">
                {editFlag
                ?   (<div><input className="mx-2" type="radio" name="transactionType" id="transactionType" onChange={(e)=>onChangeEvent(e)} value={'3'} required/>Sellers Deed Set</div>)
                :   (<div><input className="mx-2" type="radio" name="transactionType" id="transactionType" onChange={(e)=>onChangeEvent(e)} value={'3'} disabled/>Sellers Deed Set</div>)}
                </label>
                <label className="form-label mx-2 col">
                {editFlag
                ?   (<div><input className="mx-2" type="radio" name="transactionType" id="transactionType" onChange={(e)=>onChangeEvent(e)} value={'4'} required/>Other</div>)
                :   (<div><input className="mx-2" type="radio" name="transactionType" id="transactionType" onChange={(e)=>onChangeEvent(e)} value={'4'} disabled/>Other</div>)}
                </label>
              </div>
              <hr/>
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 mx-2">
                <label className="form-label mx-2 col">TYPE OF FINANCING: (Optional)</label>
                <label className="form-label mx-2 col">
                {editFlag 
                ?   (<div><input className="mx-2" type="radio" name="financeType" id="financeType" onChange={(e)=>onChangeEvent(e)} value={'11'} />FHA</div>)
                :   (<div><input className="mx-2" type="radio" name="financeType" id="financeType" onChange={(e)=>onChangeEvent(e)} value={'11'} disabled/>FHA</div>)}
                </label>
                <label className="form-label mx-2 col">
                {editFlag 
                ?   (<div><input className="mx-2" type="radio" name="financeType" id="financeType" onChange={(e)=>onChangeEvent(e)} value={'16'} />VA</div>)
                :   (<div><input className="mx-2" type="radio" name="financeType" id="financeType" onChange={(e)=>onChangeEvent(e)} value={'16'} disabled/>VA</div>)}
                </label>
                <label className="form-label mx-2 col">
                {editFlag
                ?   (<div><input className="mx-2" type="radio" name="financeType" id="financeType" onChange={(e)=>onChangeEvent(e)} value={'12'} />Conventional</div>)
                :   (<div><input className="mx-2" type="radio" name="financeType" id="financeType" onChange={(e)=>onChangeEvent(e)} value={'12'} disabled/>Conventional</div>)}
                </label>
                <label className="form-label mx-2 col">
                {editFlag
                ?   (<div><input className="mx-2" type="radio" name="financeType" id="financeType" onChange={(e)=>onChangeEvent(e)} value={'13'} />Reverse</div>)
                :   (<div><input className="mx-2" type="radio" name="financeType" id="financeType" onChange={(e)=>onChangeEvent(e)} value={'13'} disabled/>Reverse</div>)}
                </label>
                <label className="form-label mx-2 col">
                {editFlag
                ?   (<div><input className="mx-2" type="radio" name="financeType" id="financeType" onChange={(e)=>onChangeEvent(e)} value={'14'} />HELOC</div>)
                :   (<div><input className="mx-2" type="radio" name="financeType" id="financeType" onChange={(e)=>onChangeEvent(e)} value={'14'} disabled/>HELOC</div>)}
                </label>
                <label className="form-label mx-2 col">
                {editFlag
                ?   (<div><input className="mx-2" type="radio" name="financeType" id="financeType" onChange={(e)=>onChangeEvent(e)} value={'15'} />Commercial</div>)
                :   (<div><input className="mx-2" type="radio" name="financeType" id="financeType" onChange={(e)=>onChangeEvent(e)} value={'15'} disabled/>Commercial</div>)}
                </label>
              </div>
              <hr/>
              <div className="mb-3 mt-2">
               <label htmlFor="comments" className='form-label'>Additional Comments (max length 400 characters)</label>
               {editFlag
               ? <input className='form-control' id="comments" maxLength='400' onChange={(e)=>onChangeEvent(e)} type="text" value={request.comments} />
               : <input className='form-control' id="comments" maxLength='400' onChange={(e)=>onChangeEvent(e)} type="text" value={request.comments} readOnly/>}
              </div>
              <div className="mb-3 mt-2">
               <label htmlFor="email" className='form-label'>Title Company Email Address</label>
               {editFlag
               ? <input className='form-control' id="email" onChange={(e)=>onChangeEvent(e)} type="email" value={request.email} />
               : <input className='form-control' id="email" onChange={(e)=>onChangeEvent(e)} type="email" value={request.email} readOnly/>}
              </div>
              <div className="mb-3 mt-2">
              <label htmlFor="appt_fee" className='form-label'>Title Company Service Fee</label>
                {editFlag
                ? <input className='form-control' id="appt_fee" onChange={(e)=>onChangeEvent(e)} type="text" value={setServiceFee()} />
                : <input className='form-control' id="appt_fee" onChange={(e)=>onChangeEvent(e)} type="text" value={setServiceFee()} readOnly/>}
               </div>
              <div className="mb-3 mt-2">
               <label htmlFor="contactName" className='form-label'>Signer Contact Name</label>
               {editFlag
               ? <input className='form-control' id="contactName" onChange={(e)=>onChangeEvent(e)} type="text" value={request.contactName} />
               : <input className='form-control' id="contactName" onChange={(e)=>onChangeEvent(e)} type="text" value={request.contactName} readOnly/>}
              </div>
              <div className="row">
               <div className="col mb-3 mt-2">
                <label htmlFor="contactPhone1" className='form-label'>Signer Contact Phone 1</label>
                {editFlag
                ? <input className='form-control' id="contactPhone1" onChange={(e)=>onChangeEvent(e)} type="tel" value={request.contactPhone1} />
                : <input className='form-control' id="contactPhone1" onChange={(e)=>onChangeEvent(e)} type="tel" value={request.contactPhone1} readOnly/>}
               </div>
               <div className="col mb-3 mt-2">
                <label htmlFor="contactPhone2" className='form-label'>Signer Contact Phone 2</label>
                {editFlag
                ? <input className='form-control' id="contactPhone2" onChange={(e)=>onChangeEvent(e)} type="tel" value={request.contactPhone2} />
                : <input className='form-control' id="contactPhone2" onChange={(e)=>onChangeEvent(e)} type="tel" value={request.contactPhone2} readOnly/>}
               </div>
              </div>
              <div className="row">
               <div className="col mb-3 mt-2">
                <label htmlFor="contactEmail1" className='form-label'>Signer Email Address 1</label>
                {editFlag
                ? <input className='form-control' id="contactEmail1" onChange={(e)=>onChangeEvent(e)} type="email" value={request.contactEmail1} />
                : <input className='form-control' id="contactEmail1" onChange={(e)=>onChangeEvent(e)} type="email" value={request.contactEmail1} readOnly/>}
               </div>
               <div className="col mb-3 mt-2">
                <label htmlFor="contactEmail2" className='form-label'>Signer Email Address 2</label>
                {editFlag
                ? <input className='form-control' id="contactEmail2" onChange={(e)=>onChangeEvent(e)} type="email" value={request.contactEmail2} />
                : <input className='form-control' id="contactEmail2" onChange={(e)=>onChangeEvent(e)} type="email" value={request.contactEmail2} readOnly/>}
               </div>
              </div> 
              </div>
              </div>
             {editFlag
             ? (<div className='row'><input className='form-input btn btn-secondary btn-md mb-3 mx-3 rounded' type="submit" value="Preview"/></div>)
             : <div><input className='btn btn-secondary btn-md mb-3 rounded' type="submit" value="Submit"/><button className='btn btn-light btn-outline-secondary mx-3 mb-3 rounded' onClick={() => setEditFlag(true)}>Edit</button></div>
             }
            </form>
            </div>
            </div>
            )}
            {isLoading && 
                (<div class="d-flex justify-content-center mb-4 mt-4">
                    <div className="spinner-border" role="status">
                    <   span className="visually-hidden"></span>
                    </div>
                </div>)}
        </Fragment>
    )
   };

export default AdminNewRequest;