import React, { Fragment } from "react";
import mixpanel from "mixpanel-browser";

import { HeroAboutUs, HomeContent } from "../components";

const AboutUs = () => {
  
mixpanel.track('About Us')
  
  return (
  <Fragment>
    <HeroAboutUs />
    <hr />
    <HomeContent />
  </Fragment>
);
}

export default AboutUs;
