// src/components/admin/admin-send-email-message.js

import React, { useState, Fragment, useEffect, useContext } from 'react';
import { RestHelper } from '../../helper/restHelper'
import { UserContext } from "../../app"




const AdminSendEmail = (data) => {

    const [textMessage, setTextMessage] = useState('')
    const [showResults, setShowResults] = useState(false)
    const [showMessageForm, setShowMessageForm] = useState(true)
    const [submitReply, setSubmitReply] = useState('')
    const api = RestHelper()

    const submitNotify = (email, phone) => {
        setSubmitReply('This is not yet working.')
        setShowResults(true)
        setShowMessageForm(false)
      }

    const onChangeEvent = (evt) => {
        var value = evt.target.value;
        setTextMessage(value)     
        }

    return (
        <div>
            {showMessageForm && (
                <div className='container mb-2'>
                    <h6 className="mt-2 mx-3 mb-2">Create new email message to {data.profile.firstName} {data.profile.lastName}:  CAUTION - THIS IS NOT BUILT YET</h6>
                    <div className = "row mx-3">
                        <textarea className='col' id="replyMsg" maxLength='140' onChange={(e)=>onChangeEvent(e)} type="text" value={textMessage} rows='3'></textarea>
                    </div>
                    <div className='row mx-3'>
                        <button className='btn btn-light btn-outline-secondary mx-3 mt-3 mb-3 rounded' onClick={() => submitNotify((data.profile.email),(data.profile.mobilePhone))}>Send Message</button>
                    </div>
                </div>)}
            {showResults && (
                <div className='container mb-2'>
                    {submitReply}
                </div>
            )}
        </div>
    );

}
export default AdminSendEmail;