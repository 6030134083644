import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import {NotaryProfile} from "../components"

const ProfilePopupOverlay = (props) => {
  const [showModal, setShowModal] = useState(true);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    props.clearModal()
  };

  const handleConfirm = () => {
    // Handle your confirmation logic here
    closeModal();
    props.confirmAction(props.passedObject)
    props.clearModal()
  };

  return (
    <div className="popup-container">
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Notary Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body><NotaryProfile profile={props.email}/></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ProfilePopupOverlay;
