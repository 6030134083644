import React, { Fragment, useEffect, useState, useContext} from "react";

import { RestHelper } from "../../helper/restHelper";
import { UserContext } from "../../app"
import {AdminAssignDirect} from "../../components/admin";


const AdminEditRequest = (data) => {

    const {userProfile, setUser } = useContext(UserContext)
    const [request, setRequest] = useState({
        apptName:"",
        apptDate:"",
        apptTime:"",
        apptTimeTBD: false,
        apptTimeZone: "CT",
        apptStreet:"",
        apptCity:"",
        apptState:"",
        apptZip:"",
        apptOffer:"",
        email:"",
        comments:"",
        apptLender:"",
        fileNumber:"",
        userProfile:"",
        contactName:"",
        contactPhone1:"",
        contactPhone2:"",
        contactEmail1:"",
        contactEmail2:"",
        unique_id:"",
        appt_fee:"",
        scanBack: false,
        transactionType: '',
        financeType: '',
        notaryCount: 0,
        preferredNotary: []
    })
    const [profileLoaded, setProfileLoaded] = useState(false)
    const [editFlag, setEditFlag] = useState(true)
    const [notaryFeeEditFlag, setNotaryFeeEditFlag] = useState(true)
    const [errorMessage, setErrorMessage] = useState(false)
    const [showForm, setShowForm] = useState(true)
    const [apiMessage, setApiMessage] = useState('')
    const [apiDetails, setApiDetails] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [requireTime, setRequireTime] = useState(true)
    const [editedContent, setEditedContent] = useState(false)
    const [directlyAssign, setDirectlyAssign] = useState(false)


    const api = RestHelper()

    useEffect(() => {
        if (!isLoaded) {
            setIsLoading(true)
            setShowForm(false)
            const reqData = {'action' : 'get', 'request_id' : data['requestId'] }
            const options = {}
            api
                .postCreate('/manage-appts-admin', options, reqData)
                .then(res => initialLoad(res))
                .catch(err => console.log(err))
        }
    },[])

    const initialLoad = (res) => {
        setRequest({
            ...request,
            ['apptName'] : res['appt_name'],
            ['apptDate'] : res['appt_date'],
            ['apptTime'] : res['appt_time'],
            ['apptTimeTBD'] : res['appt_time_TBD'],
            ['apptTimeZone'] : res['appt_timeZone'],
            ['apptStreet'] : res['appt_address'],
            ['apptCity'] : res['appt_city'],
            ['apptState'] : res['appt_state'],
            ['apptZip'] : res['appt_zip'],
            ['apptOffer'] : res['appt_offer'],
            ['email'] : res['appt_escrow_email'],
            ['appt_firm_name'] : res['appt_firm_name'],
            ['appt_escrow'] : res['appt_escrow'],
            ['comments'] : res['appt_notes'],
            ['fileNumber'] : res['fileNumber'],
            ['apptLender'] : res['appt_lender'],
            ['userProfile'] : res['userProfile'],
            ['contactName'] : res['appt_contactName'],
            ['contactPhone1'] : res['appt_contactPhone1'],
            ['contactPhone2'] : res['appt_contactPhone2'],
            ['contactEmail1'] : res['appt_contactEmail1'],
            ['contactEmail2'] : res['appt_contactEmail2'],
            ['unique_id'] : res['unique_id'],
            ['appt_fee'] : res['appt_fee'],
            ['scanBack'] : res['scanBack'],
            ['transactionType'] : res['transactionType'],
            ['financeType'] : res['financeType'],
            ['notaryCount'] : res['notaryCount'],
            ['preferredNotary'] : res['preferredNotary']
        })
        
        setIsLoading(false)
        setShowForm(true)
        setCheckBox(res['appt_time_TBD'])
        setScanBack(res['scanBack'])
        setTransactionType(res['transactionType'])
        setFinanceType(res['financeType'])
        setEditFlag(false)
        setIsLoaded(true)
        
    }

    const onChangeEvent = (evt) => {
        setEditedContent(true)
        var value = evt.target.value;
        if (evt.target.id == 'apptOffer') {
            value = value.replace('$', '')
        }
        if (evt.target.id == 'appt_fee') {
            value = value.replace('$', '')
        }
        setRequest({
            ...request,
            [evt.target.id]: value
        })
        
    }
    
    const setScanBack = (initialValue) => {
        if (initialValue === 'true') {
            var radioButton = document.querySelector("input[value='true']")
            radioButton.checked = true;
        }
        else {
            var radioButton = document.querySelector("input[value='false']")
            radioButton.checked = true;
        }
    }

    const setTransactionType = (initialValue) => {
        if (initialValue === '1') {
            var radioButton = document.querySelector("input[value='1']")
            radioButton.checked = true;
        }
        else if (initialValue === '2') {
            var radioButton = document.querySelector("input[value='2']")
            radioButton.checked = true;
        }
        else if (initialValue === '3') {
            var radioButton = document.querySelector("input[value='3']")
            radioButton.checked = true;
        }
        else if (initialValue === '4') {
            var radioButton = document.querySelector("input[value='4']")
            radioButton.checked = true;
        }
    }

    const setFinanceType = (initialValue) => {
        if (initialValue === '11') {
            var radioButton = document.querySelector("input[value='11']")
            radioButton.checked = true;
        }
        else if (initialValue === '12') {
            var radioButton = document.querySelector("input[value='12']")
            radioButton.checked = true;
        }
        else if (initialValue === '13') {
            var radioButton = document.querySelector("input[value='13']")
            radioButton.checked = true;
        }
        else if (initialValue === '14') {
            var radioButton = document.querySelector("input[value='14']")
            radioButton.checked = true;
        }
        else if (initialValue === '15') {
            var radioButton = document.querySelector("input[value='15']")
            radioButton.checked = true;
        }
        else if (initialValue === '16') {
            var radioButton = document.querySelector("input[value='16']")
            radioButton.checked = true;
        }
    }

    const setCheckBox = (initialValue) => {
        const checkboxArray = ['apptTimeTBD']
        checkboxArray.forEach(value => {
            const checkBoxVar = document.getElementById(value)
            if (initialValue){
                if (initialValue != checkBoxVar.checked){
                    checkBoxVar.click()}
                }
        })
    }

    useEffect(() => {
        setRequest({
            ...request,
            ['userProfile']: userProfile.profileEmail
        })
        document.getElementById(request.apptTimeZone).selected = true
        const idArray = ['apptState']
        const stateArray = ['State', 'AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'HI', 
        'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 
        'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 
        'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY']
        idArray.forEach(value => {
            const stateVar = document.getElementById(value)
            stateArray.forEach(value2 => {
                const option = document.createElement('option')
                option.innerHTML = value2
                if (request[value] == value2) {
                    option.setAttribute('selected','selected')
                }
                stateVar.appendChild(option)
            })
        })
    },[isLoaded, setIsLoaded]);
    //const stateVar = document.getElementById('certifiedState1Select')
    
    const clearErrorMessage = () => {
        setErrorMessage(false)
        setShowForm(true)
        setProfileLoaded(!profileLoaded)
    }

    const updateTimeFieldInitial = (value) => {

        if(value){
          setRequireTime(false)
          setRequest({
            ...request,
            ['apptTime']: '00:00'
        })
        }
      }

    const setStateValues = () => {
        document.getElementById(request.apptTimeZone).selected = true
        const idArray = ['apptState']
        const stateArray = ['State', 'AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'HI', 
        'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 
        'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 
        'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY']
        idArray.forEach(value => {
            const stateVar = document.getElementById(value)
            stateArray.forEach(value2 => {
                const option = document.createElement('option')
                option.innerHTML = value2
                if (request[value] == value2) {
                    option.setAttribute('selected','selected')
                }
                stateVar.appendChild(option)
            })
        })
    }

    const updateTimeField = () => {

        if(requireTime){
          setRequireTime(false)
          setRequest({
            ...request,
            ['apptTime']: '00:00',
            ['apptTimeTBD'] : true
        })
        }
        else{
          setRequireTime(true)
          setRequest({
            ...request,
            ['apptTime']: '',
            ['apptTimeTBD'] : false
        })
        }
      }
    const addState = (evt) => {
        const value = evt.target.id
        setRequest({
            ...request,
            [value] : evt.target.value
        })
    }

    const setApiRes = (res) => {
        setErrorMessage(true)
        setShowForm(false)
        setIsLoading(false)
        setApiDetails(res['details'])
        setApiMessage(res['returnMessage'])
    }
    
    const updateEditFlags = (e) => {
        setEditFlag(e)
        setNotaryFeeEditFlag(e)
    }
   
    const callApi = () => {
        const options = {}
        const reqData = {'action' : 'update', 'request_id' : data['requestId'], 'data' : request }
        setIsLoading(true)
        setShowForm(false)
        setEditedContent(false)
        api
        .postCreate('/manage-appts-admin', options, reqData)
        .then(res => setApiRes(res))
        .catch(err => setApiRes(err))
    }

    const submitNotify = () => {
        const options = {}
        const reqData = {'action' : 'notify', 'request_id' : data['requestId']}
        setIsLoading(true)
        setShowForm(false)
        api
        .postCreate('/manage-appts-admin', options, reqData)
        .then(res => setApiRes(res))
        .catch(err => setApiRes(err))
    }

    const submitPreferNotify = () => {
        const options = {}
        const reqData = {'action' : 'notifyPrefer', 'request_id' : data['requestId']}
        setIsLoading(true)
        setShowForm(false)
        api
        .postCreate('/manage-appts-admin', options, reqData)
        .then(res => setApiRes(res))
        .catch(err => setApiRes(err))
    }

    const assignDirect = () => {
        setShowForm(false)
        setDirectlyAssign(true)
    }

    const setFee = () => {
        const fee = '$' + request.apptOffer
        return fee
    }

    const setServiceFee = () => {
        const fee = '$' + request.appt_fee
        return fee
    }

    const convertToDate = (updatedTime) => {
        const date = new Date(updatedTime*1000)
        return date
    }

    const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // If editFlag is true, the preview button was selected and the updated form values are ready for review
        if ( notaryFeeEditFlag ){
            setEditFlag(false)
            setNotaryFeeEditFlag(false)
        }
        // If editFlag was false, the form was in a preview mode and ready to be submitted
        else {
            callApi()
            setEditFlag(true)
            setNotaryFeeEditFlag(true)
        }
    }

    return (
        <Fragment>
            {errorMessage && (
                <div className="container border rounded">
                <div className='row rounded bg-white'>
                 <button className="close ml-3 mb-2 mt-2" aria-label="Close" onClick={() =>clearErrorMessage()}>
                  <span aria-hidden='false'>&times;</span>
                 </button>
                </div>
                <div className="card mb-3 mt-3">
                    <div className="card-body rounded-border bg-white">
                        <h5 className='card-title'>Appointment Status</h5>
                        <h6 className='card-subtitle mb-2'>{ apiMessage }</h6>
                        <h5 className='card-subtitle mb-2'>{ apiDetails }</h5>
                    </div>
              </div>
              </div>
            )}
            {showForm && (
            <div className="container rounded border bg-white mt-4 mb-4" id="myInput">
                <div className='mb-3 mt-3 mx-3'><h4>Review and Edit Submitted Appointment</h4></div>
            <div>
            <form onSubmit={(e)=>onSubmitHandler(e)} className="needs-validation">
             <div className="card mb-3 mt-3">
             <div className="card-body rounded border bg-light">
              <h5 className="card-title">Event Details ( * required field)</h5>
              <div className="row">
               <div className="col mb-3 mt-2">
                <label htmlFor="apptName" className='form-label'>Appointment Name *</label>
                {editFlag
                ? <input className='form-control' id="apptName" onChange={(e)=>onChangeEvent(e)} type="text" value={request.apptName} required />
                : <input className='form-control' id="apptName" onChange={(e)=>onChangeEvent(e)} type="text" value={request.apptName} required readOnly/>}
               </div>
              </div>
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-lg-5">
               <div className="col mb-3 mt-2">
                <label htmlFor="apptDate" className='form-label'>Date *</label>
                {editFlag
                ? <input className='form-control' id="apptDate" onChange={(e)=>onChangeEvent(e)} type="date" value={request.apptDate} required/>
                : <input className='form-control' id="apptDate" onChange={(e)=>onChangeEvent(e)} type="date" value={request.apptDate} required readOnly/>}
               </div>
               <div className="form-check col col-auto mb-3 mt-2">
                    <label className="form-check-label">
                        Time TBD
                    </label>
                    {editFlag
                    ? <input className="form-check-input mx-2" type="checkbox" value="" id="apptTimeTBD" onChange={() => {updateTimeField()}}/>
                    : <input className="form-check-input mx-2" type="checkbox" value="" id="apptTimeTBD" disabled/>}
                </div>
               <div className="col mb-3 mt-2">
                {requireTime
                ?(<div><label htmlFor="apptTime" className='form-label'>Time *</label>
                {editFlag
                ? <input className='form-control' id="apptTime" onChange={(e)=>onChangeEvent(e)} type="time" value={request.apptTime} required/>
                : <input className='form-control' id="apptTime" onChange={(e)=>onChangeEvent(e)} type="time" value={request.apptTime} required readOnly/>}</div>)
                : <div><label htmlFor="apptTime" className='form-label'>Time *</label><input className='form-control' id='apptTimeTBDBox' value='TBD' readOnly/></div>}
               </div>
               <div className="col mb-3 mt-2">
                <label for="apptTimeZone" className='form-label'>Timezone</label>
                {editFlag
                ? <select className='form-select form-select-sm mb-3 col' aria-label='form-select-sm' id="apptTimeZone" onChange={(e)=>onChangeEvent(e)}>
                  <option value='ET' id='ET'>Eastern</option>
                    <option value='CT' id='CT' selected>Central</option>
                    <option value='MT' id='MT'>Mountain</option>
                    <option value='PT' id='PT'>Pacific</option>
                    <option value='HT' id='HT'>Hawaii</option>
                    <option value='AK' id='AK'>Alaska</option>
                  </select>
                : <select className='form-select form-select-sm mb-3 col' aria-label='form-select-sm' id="apptTimeZone" onChange={(e)=>onChangeEvent(e)} disabled>
                    <option value='ET' id='ET'>Eastern</option>
                    <option value='CT' id='CT' selected>Central</option>
                    <option value='MT' id='MT'>Mountain</option>
                    <option value='PT' id='PT'>Pacific</option>
                    <option value='HT' id='HT'>Hawaii</option>
                    <option value='AK' id='AK'>Alaska</option>
                 </select>}
                </div>
              </div>
              <div className="row">
               <div className="col mb-3 mt-2">
                <label htmlFor="apptStreet" className='form-label'>Address *</label>
                {editFlag
                ? <input className='form-control' id="apptStreet" onChange={(e)=>onChangeEvent(e)} type="text" value={request.apptStreet} required />
                : <input className='form-control' id="apptStreet" onChange={(e)=>onChangeEvent(e)} type="text" value={request.apptStreet} required readOnly/>}
               </div>
              </div>
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4">
               <div className="col mb-3 mt-2">
                <label htmlFor="apptCity" className='form-label'>City *</label>
                {editFlag
                ? <input className='form-control' id="apptCity" onChange={(e)=>onChangeEvent(e)} type="text" value={request.apptCity} required/>
                : <input className='form-control' id="apptCity" onChange={(e)=>onChangeEvent(e)} type="text" value={request.apptCity} required readOnly/>}
               </div>
               <div className="col mb-3 mt-2">
               <label for="apptState" className='form-label col'>State *</label>
                  {editFlag
                  ? <select className='form-select form-select-sm mb-3 col' aria-label='form-select-sm' id="apptState" onChange={(e)=>addState(e)}></select>
                  : <select className='form-select form-select-sm mb-3 col' aria-label='form-select-sm' id="apptState" onChange={(e)=>addState(e)} disabled></select>}
               </div>
               <div className="col mb-3 mt-2">
                <label htmlFor="apptZip" className='form-label'>Zip Code *</label>
                {editFlag
                ? <input className='form-control' id="apptZip" maxLength="5" onChange={(e)=>onChangeEvent(e)} type="tel" value={request.apptZip} required/>
                : <input className='form-control' id="apptZip" onChange={(e)=>onChangeEvent(e)} type="tel" value={request.apptZip} required readOnly/>}
               </div>
              </div>
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3">
              <div className="col mb-3 mt-2">
                <label htmlFor="apptOffer" className='form-label'>Offered Signing Agent Fee</label>
                {notaryFeeEditFlag
                ? <input className='form-control' id="apptOffer" onChange={(e)=>onChangeEvent(e)} type="text" value={setFee()} required/>
                : <input className='form-control' id="apptOffer" onChange={(e)=>onChangeEvent(e)} type="text" value={setFee()} required readOnly/>}
               </div>
               <div className="col mb-3 mt-2">
                <label htmlFor="apptLender" className='form-label'>Lender</label>
                {editFlag
                ? <input className='form-control' id="apptLender" onChange={(e)=>onChangeEvent(e)} type="text" value={request.apptLender} />
                : <input className='form-control' id="apptLender" onChange={(e)=>onChangeEvent(e)} type="text" value={request.apptLender} readOnly/>}
               </div>
              </div>
              <hr/>
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 mx-2">
                <label className="form-label mx-2 col">SCAN BACK REQUIRED?</label>
                <label className="form-label mx-2 col">
                {editFlag 
                ?   (<div><input className="mx-2" type="radio" name="scanBack" id="scanBack" onChange={(e)=>onChangeEvent(e)} value={true} required/>Required</div>)
                :   (<div><input className="mx-2" type="radio" name="scanBack" id="scanBack" onChange={(e)=>onChangeEvent(e)} value={true} disabled/>Required</div>)}
                </label>
                <label className="form-label mx-2 col">
                {editFlag
                ?   (<div><input className="mx-2" type="radio" name="scanBack" id="scanBack" onChange={(e)=>onChangeEvent(e)} value={false} required/>Not Required</div>)
                :   (<div><input className="mx-2" type="radio" name="scanBack" id="scanBack" onChange={(e)=>onChangeEvent(e)} value={false} disabled/>Not Required</div>)}
                </label>
              </div>
              <hr/>
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 mx-2">
                <label className="form-label mx-2 col">TYPE OF TRANSACTION:</label>
                <label className="form-label mx-2 col">
                {editFlag 
                ?   (<div><input className="mx-2" type="radio" name="transactionType" id="transactionType" onChange={(e)=>onChangeEvent(e)} value={'1'} required/>Purchase</div>)
                :   (<div><input className="mx-2" type="radio" name="transactionType" id="transactionType" onChange={(e)=>onChangeEvent(e)} value={'1'} disabled/>Purchase</div>)}
                </label>
                <label className="form-label mx-2 col">
                {editFlag
                ?   (<div><input className="mx-2" type="radio" name="transactionType" id="transactionType" onChange={(e)=>onChangeEvent(e)} value={'2'} required/>Refinance</div>)
                :   (<div><input className="mx-2" type="radio" name="transactionType" id="transactionType" onChange={(e)=>onChangeEvent(e)} value={'2'} disabled/>Refinance</div>)}
                </label>
                <label className="form-label mx-2 col">
                {editFlag
                ?   (<div><input className="mx-2" type="radio" name="transactionType" id="transactionType" onChange={(e)=>onChangeEvent(e)} value={'3'} required/>Sellers Deed Set</div>)
                :   (<div><input className="mx-2" type="radio" name="transactionType" id="transactionType" onChange={(e)=>onChangeEvent(e)} value={'3'} disabled/>Sellers Deed Set</div>)}
                </label>
                <label className="form-label mx-2 col">
                {editFlag
                ?   (<div><input className="mx-2" type="radio" name="transactionType" id="transactionType" onChange={(e)=>onChangeEvent(e)} value={'4'} required/>Other</div>)
                :   (<div><input className="mx-2" type="radio" name="transactionType" id="transactionType" onChange={(e)=>onChangeEvent(e)} value={'4'} disabled/>Other</div>)}
                </label>
              </div>
              <hr/>
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 mx-2">
                <label className="form-label mx-2 col">TYPE OF FINANCING: (Optional)</label>
                <label className="form-label mx-2 col">
                {editFlag 
                ?   (<div><input className="mx-2" type="radio" name="financeType" id="financeType" onChange={(e)=>onChangeEvent(e)} value={'11'} />FHA</div>)
                :   (<div><input className="mx-2" type="radio" name="financeType" id="financeType" onChange={(e)=>onChangeEvent(e)} value={'11'} disabled/>FHA</div>)}
                </label>
                <label className="form-label mx-2 col">
                {editFlag 
                ?   (<div><input className="mx-2" type="radio" name="financeType" id="financeType" onChange={(e)=>onChangeEvent(e)} value={'16'} />VA</div>)
                :   (<div><input className="mx-2" type="radio" name="financeType" id="financeType" onChange={(e)=>onChangeEvent(e)} value={'16'} disabled/>VA</div>)}
                </label>
                <label className="form-label mx-2 col">
                {editFlag
                ?   (<div><input className="mx-2" type="radio" name="financeType" id="financeType" onChange={(e)=>onChangeEvent(e)} value={'12'} />Conventional</div>)
                :   (<div><input className="mx-2" type="radio" name="financeType" id="financeType" onChange={(e)=>onChangeEvent(e)} value={'12'} disabled/>Conventional</div>)}
                </label>
                <label className="form-label mx-2 col">
                {editFlag
                ?   (<div><input className="mx-2" type="radio" name="financeType" id="financeType" onChange={(e)=>onChangeEvent(e)} value={'13'} />Reverse</div>)
                :   (<div><input className="mx-2" type="radio" name="financeType" id="financeType" onChange={(e)=>onChangeEvent(e)} value={'13'} disabled/>Reverse</div>)}
                </label>
                <label className="form-label mx-2 col">
                {editFlag
                ?   (<div><input className="mx-2" type="radio" name="financeType" id="financeType" onChange={(e)=>onChangeEvent(e)} value={'14'} />HELOC</div>)
                :   (<div><input className="mx-2" type="radio" name="financeType" id="financeType" onChange={(e)=>onChangeEvent(e)} value={'14'} disabled/>HELOC</div>)}
                </label>
                <label className="form-label mx-2 col">
                {editFlag
                ?   (<div><input className="mx-2" type="radio" name="financeType" id="financeType" onChange={(e)=>onChangeEvent(e)} value={'15'} />Commercial</div>)
                :   (<div><input className="mx-2" type="radio" name="financeType" id="financeType" onChange={(e)=>onChangeEvent(e)} value={'15'} disabled/>Commercial</div>)}
                </label>
              </div>
              <hr/>
              <div className="mb-3 mt-2">
               <label htmlFor="fileNumber" className='form-label'>File Number</label>
               {editFlag
               ? <input className='form-control' id="fileNumber" onChange={(e)=>onChangeEvent(e)} type="text" value={request.fileNumber} />
               : <input className='form-control' id="fileNumber" onChange={(e)=>onChangeEvent(e)} type="text" value={request.fileNumber} readOnly/>}
              </div>
              <div className="mb-3 mt-2">
               <label htmlFor="comments" className='form-label'>Additional Comments (max length = 400 characters)</label>
               {editFlag
               ? <input className='form-control' id="comments" maxlength='400' onChange={(e)=>onChangeEvent(e)} type="text" value={request.comments} />
               : <input className='form-control' id="comments" maxlength='400' onChange={(e)=>onChangeEvent(e)} type="text" value={request.comments} readOnly/>}
              </div>
              <div className="mb-3 mt-2">
               <label htmlFor="email" className='form-label'>Title Company Email Address</label>
               {editFlag
               ? <input className='form-control' id="email" onChange={(e)=>onChangeEvent(e)} type="email" value={request.email} />
               : <input className='form-control' id="email" onChange={(e)=>onChangeEvent(e)} type="email" value={request.email} readOnly/>}
              </div>
              <div className="mb-3 mt-2">
               <label htmlFor="appt_firm_name" className='form-label'>Title Company</label>
               {editFlag
               ? <input className='form-control' id="appt_firm_name" onChange={(e)=>onChangeEvent(e)} type="text" value={request.appt_firm_name} />
               : <input className='form-control' id="appt_firm_name" onChange={(e)=>onChangeEvent(e)} type="text" value={request.appt_firm_name} readOnly/>}
              </div>
              <div className="mb-3 mt-2">
              <label htmlFor="appt_fee" className='form-label'>Title Company Service Fee</label>
                {editFlag
                ? <input className='form-control' id="appt_fee" onChange={(e)=>onChangeEvent(e)} type="text" value={setServiceFee()} />
                : <input className='form-control' id="appt_fee" onChange={(e)=>onChangeEvent(e)} type="text" value={setServiceFee()} readOnly/>}
               </div>
              <div className="mb-3 mt-2">
               <label htmlFor="appt_escrow" className='form-label'>Title Company Escrow Officer</label>
               {editFlag
               ? <input className='form-control' id="appt_escrow" onChange={(e)=>onChangeEvent(e)} type="text" value={request.appt_escrow} />
               : <input className='form-control' id="appt_escrow" onChange={(e)=>onChangeEvent(e)} type="text" value={request.appt_escrow} readOnly/>}
              </div>
              <div className="mb-3 mt-2">
               <label htmlFor="contactName" className='form-label'>Signer Contact Name</label>
               {editFlag
               ? <input className='form-control' id="contactName" onChange={(e)=>onChangeEvent(e)} type="text" value={request.contactName} />
               : <input className='form-control' id="contactName" onChange={(e)=>onChangeEvent(e)} type="text" value={request.contactName} readOnly/>}
              </div>
              <div className="row">
               <div className="col mb-3 mt-2">
                <label htmlFor="contactPhone1" className='form-label'>Signer Contact Phone 1</label>
                {editFlag
                ? <input className='form-control' id="contactPhone1" onChange={(e)=>onChangeEvent(e)} type="tel" value={request.contactPhone1} />
                : <input className='form-control' id="contactPhone1" onChange={(e)=>onChangeEvent(e)} type="tel" value={request.contactPhone1} readOnly/>}
               </div>
               <div className="col mb-3 mt-2">
                <label htmlFor="contactPhone2" className='form-label'>Signer Contact Phone 2</label>
                {editFlag
                ? <input className='form-control' id="contactPhone2" onChange={(e)=>onChangeEvent(e)} type="tel" value={request.contactPhone2} />
                : <input className='form-control' id="contactPhone2" onChange={(e)=>onChangeEvent(e)} type="tel" value={request.contactPhone2} readOnly/>}
               </div>
              </div>
              <div className="row">
               <div className="col mb-3 mt-2">
                <label htmlFor="contactEmail1" className='form-label'>Signer Email Address 1</label>
                {editFlag
                ? <input className='form-control' id="contactEmail1" onChange={(e)=>onChangeEvent(e)} type="email" value={request.contactEmail1} />
                : <input className='form-control' id="contactEmail1" onChange={(e)=>onChangeEvent(e)} type="email" value={request.contactEmail1} readOnly/>}
               </div>
               <div className="col mb-3 mt-2">
                <label htmlFor="contactEmail2" className='form-label'>Signer Email Address 2</label>
                {editFlag
                ? <input className='form-control' id="contactEmail2" onChange={(e)=>onChangeEvent(e)} type="email" value={request.contactEmail2} />
                : <input className='form-control' id="contactEmail2" onChange={(e)=>onChangeEvent(e)} type="email" value={request.contactEmail2} readOnly/>}
               </div>
              </div> 
              <div className='mx-3'>
                Agent Count In Region: {request.notaryCount}
                <br/>
                Available Preferred Agents: {request.preferredNotary.map((items => 
                <div>{items.notary_firstName} {items.notary_lastName} {items.notary_email} {items.notaryPhone}</div>))}
              </div>
              </div>

              </div>
             {notaryFeeEditFlag
             ? (<div className='row'><input className='form-input btn btn-secondary btn-md mb-3 mx-3 rounded' type="submit" value="Preview"/></div>)
             : <div><input className='form-input btn btn-secondary btn-md mb-3 mx-3 rounded' type="submit" value="Update"/><button className='btn btn-light btn-outline-secondary mx-3 mb-3 rounded' onClick={() => updateEditFlags(true)}>Edit</button>
             {editedContent
             ?<button className='btn btn-light btn-outline-secondary mx-3 mb-3 rounded' onClick={() => submitNotify()} disabled>Send to Notaries</button>
             :(<div><button className='btn btn-light btn-outline-secondary mx-3 mb-3 rounded' onClick={() => submitNotify()}>Send to Notaries</button>
             <button className='btn btn-light btn-outline-secondary mx-3 mb-3 rounded' onClick={() => submitPreferNotify()}>Send to Preferred Agents</button>
             <button className='btn btn-light btn-outline-secondary mx-3 mb-3 rounded' onClick={() => assignDirect()}>Directly Assign Notary</button></div>)
            }
             </div>
             }
            </form>
            </div>
            </div>
            )}
            {directlyAssign &&
            <Fragment>
                <AdminAssignDirect requestId={data.requestId}/>
            </Fragment>}
            {isLoading && 
                (<div class="d-flex justify-content-center mb-4 mt-4">
                    <div className="spinner-border" role="status">
                    <   span className="visually-hidden"></span>
                    </div>
                </div>)}
        </Fragment>
    )
   };

export default AdminEditRequest;