import React, { useState, useEffect, useCallback} from 'react';

import { RestHelper } from '../../helper/restHelper';


const AdminFirmEscrowList = (data) => {
    const [apiRes, setApiRes] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const api = RestHelper()

    const getEscrow = useCallback(() => {
        const options = {}
        const reqData = {'action' : 'getEscrow', 'filter' : data.OrgId }
        api
            .postCreate('/manage-users-admin', options, reqData)
            .then(res => setApiRes(res))
            .catch(err => console.log(err))
      })

      function formatPhoneNumber(phoneNumber) {
        // Remove any non-digit characters from the phone number
        var cleaned = phoneNumber.replace(/\D/g, '');
        var len = cleaned.length
        if (len > 10){
            var temp = cleaned.substring(0,10)
            cleaned = temp
        }
        if (len < 10){
            while(cleaned.length<10){
                cleaned +=9
            }
        }

        // Format the cleaned phone number as (123) 456-7890
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
    useEffect(() =>{
        getEscrow()
      },[data])



    return(
        <div className="container-fluid rounded">
            <div className="row mt-2">
                <h4 >{data.OrgName}</h4>
            </div>
            <div className="table-responsive">
                <table className="table table-hover">
                    <tbody>
                        <tr>
                            <th scope="col">Escrow Agent</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone</th>
                            <th scope="col">Status</th>
                        </tr>
                        {apiRes.map((items =>
                            <tr >
                                <td>{items.user_first_name} {items.user_last_name}</td>
                                <td>{items.user_email}</td>
                                <td>{formatPhoneNumber(items.user_phone)}</td>
                                <td>{items.user_status}</td>
                            </tr>
                        ))}
                    </tbody></table>
            </div>
        </div>
    )

};

export default AdminFirmEscrowList;