// src/components/admin/admin-create-new-user.js

import React, { useState, Suspense, useEffect, Fragment, useContext} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { RestHelper } from '../../helper/restHelper'
import { UserContext } from "../../app"
import mixpanel from 'mixpanel-browser';


const AdminCreateUser = () => {
    const {userProfile, setUser } = useContext(UserContext)
    const [fullAdmin, setFullAdmin] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [apiUpdate, setApiUpdate] = useState([])
    const [orgList, setOrgList] = useState ({})
    const [populateStateInfo, setPopulateStateInfo] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [initialFirmList, setInitialFirmList] = useState([])
    const [showForm, setShowForm] = useState(true)
    const [selectedFirmId, setSelectedFirmId] = useState()
    const [apiResponse, setApiResponse] = useState('')
    const [apiMessage, setApiMessage] = useState('')
    const [apiDetails, setApiDetails] = useState('')
    const [formInput, setFormInput] = useState({
    userFirstName: "",
    userLastName: "",
    userCity: "",
    userState: "",
    userFirm: "",
    userPhone: "",
    userEmail: "",
    userRole: "notary",
    userZip: "",
    userFirmName: "",
  })
 
  const api = RestHelper()

  useEffect (() => {
    if (userProfile.profile === 'admin'){
      setFullAdmin(true)
    }
    mixpanel.track('Create New User')
    getFirmList();
  }, [apiUpdate, setApiUpdate]);

  const getFirmList = () => {
    const options = {}
    const passedData = {'activeUser' : userProfile.profileEmail, 'action' : 'create'}
    api
       .postCreate('/get-firm-list', options, passedData)
       .then(res => setInitialFirmList(res['firms']))
       .catch(err => console.log(err))
   }

  useEffect(() => {
    const idArray = ['userState']
    const stateArray = ['State', 'AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'HI', 
    'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 
    'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 
    'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY']
    idArray.forEach(value => {
        const stateVar = document.getElementById(value)
        stateArray.forEach(value2 => {
            const option = document.createElement('option')
            option.innerHTML = value2
            if (formInput[value] == value2) {
                option.setAttribute('selected','selected')
            }
            stateVar.appendChild(option)
        })
    })
  },[populateStateInfo,setPopulateStateInfo]);

   useEffect(() => {
    const idArray = ['userFirm']
    const firmArray = initialFirmList
    const firmDict = {}
    firmArray.forEach(value => {
        firmDict[value['org_name']] = value['org_id']
    })
    idArray.forEach(value => {
        const firmVar = document.getElementById(value)
        firmArray.forEach(value2 => {
            const option = document.createElement('option')
            option.innerHTML = value2['org_name']
            if (value2['org_id']== 0) {
              option.setAttribute('selected','selected')
              setFormInput({
                ...formInput,
                ['userFirm'] : 0,
                ['userFirmName'] : 'Independent notary'
            })}
            firmVar.appendChild(option)
            setOrgList({
                ...orgList,
                firmDict
            })
        })
    })
  },[initialFirmList,setInitialFirmList]);

  const onChangeEvent = (evt) => {
    const value = evt.target.value;
    setFormInput({
        ...formInput,
        [evt.target.id]: value
    })
  }

  const addState = (evt) => {
    const value = evt.target.id
    console.log(value)
    setFormInput({
        ...formInput,
        [value] : evt.target.value
    })
  }

  const setUserRole = (evt) => {
    const value = evt.target.id
    console.log(value)
    setFormInput({
        ...formInput,
        [value] : evt.target.value
    })
  }

  const clearErrorMessage = () => {
    setErrorMessage(false)
    setShowForm(true)
  }

  const setApiRes = (res) => {
    setIsLoading(false)
    setErrorMessage(true)
    setApiResponse(res['status'])
    setApiDetails(res['details'])
    setApiMessage(res['message'])
}
const callApi = () => {
    const options = {}
    setIsLoading(true)
    setShowForm(false)
    api
    .postCreate('/create-new-user', options, formInput)
    .then(res => setApiRes(res))
    .catch(err => console.log(err))
}
  
  const selectFirm = (firm) => {
    const firmInfo = document.getElementById(firm.target.id)
    setSelectedFirmId(orgList['firmDict'][firmInfo.value])
    setFormInput({
      ...formInput,
      ['userFirm'] : orgList['firmDict'][firmInfo.value],
      ['userFirmName'] : firmInfo.value
  })
  }

  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    callApi()
}
 
  

  return(
   <div>
        {showForm &&
        <div className="card mb-3">
        <form onSubmit={onSubmitHandler}>
          <div className="card-body rounded border bg-light mt-3 mb-3 mx-3">
            <h5 className='card-title mx-3 mb-3'>Create New User</h5>
          <div className="mb-3 col">
            <label htmlFor="userFirstName" className='form-label'>First Name</label>
            <input className='form-control' id="userFirstName" onChange={(e)=>onChangeEvent(e)} type="text" value={formInput.userFirstName}/>
          </div>
          <div className="mb-3 col">
            <label htmlFor="userLastName" className='form-label'>Last Name</label>
            <input className='form-control' id="userLastName" onChange={(e)=>onChangeEvent(e)} type="text" value={formInput.userLastName}/>
          </div>
          <div className="mb-3 col">
           <label htmlFor="userEmail" className='form-label'>Email Address</label>
           <input className='form-control' id="userEmail" onChange={(e)=>onChangeEvent(e)} type="email" value={formInput.userEmail}/>
          </div>  
          <div className="mb-3 col">
            <label htmlFor="userCity" className='form-label'>City</label>
            <input className='form-control' id="userCity" onChange={(e)=>onChangeEvent(e)} type="text" value={formInput.userCity}/>
          </div>
          <div className="col mb-3">
               <label for="userState" className='form-label'>State</label>
                  <select className='form-select form-select-sm mb-3 col' aria-label='form-select-sm' id="userState" onChange={(e)=>addState(e)}></select>
             </div>
          <div className="mb-3 col">
           <label htmlFor="userZip" className='form-label'>User Zip Code</label>
           <input className='form-control' id="userZip" onChange={(e)=>onChangeEvent(e)} type="tel" value={formInput.userZip}/>
          </div>
          <div className="mb-3 col">
            <label htmlFor="userPhone" className='form-label'>Mobile Phone Number</label>
            <input className='form-control' id="userPhone" onChange={(e)=>onChangeEvent(e)} type="tel" value={formInput.userPhone}/>
         </div> 
          <div className="col mb-3 mt-2">
               <label for="userFirm" className='form-label col'>Firm</label>
                  <select className='form-select form-select-sm mb-3 col' aria-label='form-select-sm' id="userFirm" onChange={(e)=>selectFirm(e)}></select>
             </div>
          <div className="mb-3 col">
           <label htmlFor="userRole" className="form-label">User Role</label>
           <select class="form-select mx-3" id="userRole" onChange={(e)=>setUserRole(e)}>
            <option value="creator">Escrow Officer</option>
            <option selected value="notary">Signing Agent</option>
            {fullAdmin && <option value="admin">Admin</option>}
            <option value="creator_admin">Org Admin</option>
           </select>
          </div>

          <input className='btn btn-secondary btn-sm mx-3 mb-3' type="submit" value="Create"/>
        </div>
    </form>
    </div>}
    <div>
    {isLoading && 
                (<div class="d-flex justify-content-center mb-4 mt-4">
                    <div className="spinner-border" role="status">
                    <   span className="visually-hidden"></span>
                    </div>
    </div>)}
    </div>
    <div>
    {errorMessage && (
                <div className="container border rounded">
                <div className='row rounded bg-white'>
                 <button className="close ml-3 mb-2 mt-2" aria-label="Close" onClick={() =>clearErrorMessage()}>
                  <span aria-hidden='false'>&times;</span>
                 </button>
                </div>
                <div className="card mb-3 mt-3">
                    <div className="card-body rounded-border bg-white">
                        <h5 className='card-title'>Registration Status</h5>
                        <h6 className='card-subtitle mb-2'>{ apiResponse }</h6>
                        <h6 className='card-subtitle mb-2'>{ apiMessage }</h6>
                        <h5 className='card-subtitle mb-2'>{ apiDetails }</h5>
                    </div>
              </div>
              </div>
            )}
    </div>
    </div>
  )
};

export default AdminCreateUser;