import React from "react";

//const logo = "https://cdn.auth0.com/blog/auth0-react-sample/assets/logo.png";
//const logo = "/img/radiant_tech_logo.jpg";
//<div className='container rounded' style={{ backgroundImage: "url(/img/office-pic.jpg)", backgroundRepeat: "no-repeat", backgroundSize: 'cover', opacity: 0.9, width: '75vw', height: '50vh', backgroundPosition: 'center'}}></div>
//<div className='hero-container rounded mt-2 mb-2'><img className='hero-img' src='/img/pfs_full_logo.png'/></div>

const logo = "/img/office-pic.jpg";

const Hero = () => (
  <div className="d-flex flex-column h-100">
     
     <div className='container rounded' style={{ backgroundImage: "url(/img/office-pic.jpg)", backgroundRepeat: "no-repeat", backgroundSize: 'cover', opacity: 0.9, width: '75vw', height: '50vh', backgroundPosition: 'center'}}></div>
 
 
  <div className="rounded bg-white mx-4 mt-4">
    <p className="lead mx-3">
      Pinpoint Field Services provides you peace of mind that your closing or inspection will meet your deadline!
      You provide us the preferred location and time and our service will find a qualified Signing Agent or Field Inspector to handle your assignment!
    </p>
  </div>
</div>
);

export default Hero;
