// src/components/appt-notes-handler.js

import React, {useEffect, useState} from 'react';
import { RestHelper } from '../../helper/restHelper'


const AdminApptNotesHandler = (data) => {
    const [message, setMessage] = useState('')
    const [storedMessages, setStoredMessages] = useState([{}])
    const [dataFetched, setDataFetched] = useState(false)
    const api = RestHelper()


    function getNotes() {
      setMessage('')
      const reqData = { 'action' : 'read', 'unique_id' : data.requestId}
      const options = {}
      api
        .postCreate('/note-manage-all', options, reqData)
        .then(res => processResponse(res))
        .catch(err => console.log(err))
     }

     function processResponse(res) {
        setStoredMessages(res)
        setDataFetched(true)
        var submitVar = document.getElementById('addNotesButton')
        submitVar.removeAttribute("disabled")
       }

     function addNotes() {
      var submitVar = document.getElementById('addNotesButton')
      submitVar.setAttribute("disabled", "disabled")
      const reqData = { 'action' : 'create', 'unique_id' : data.requestId, 'message' : message}
      const options = {}
      api
        .postCreate('/note-manage-all', options, reqData)
        .then(res => getNotes())
        .catch(err => console.log(err))
     }

     function removeNotes(object) {
      const reqData = { 'action' : 'remove', 'unique_id' : data.requestId, 'timeStamp':object}
      const options = {}
      api
        .postCreate('/note-manage-all', options, reqData)
        .then(res => getNotes())
        .catch(err => console.log(err))
     }

    const convertToSimpleDate = (updatedTime) => {
        const date = new Date(updatedTime*1000)
        const dateString = date.toLocaleString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true
        })
        return dateString
      }

      useEffect (() => {
        getNotes();
      }, []);

    const onChangeEvent = (evt) => {
        const value = evt.target.value;
        setMessage(value)
    }

    function handleProductNavigation(event) {
      const btnRef = document.getElementById("refresh")
      btnRef.blur()
      getNotes()
    }

    const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
        // event.preventDefault();
        // If editFlag is true, the preview button was selected and the updated form values are ready for review
        addNotes()
        console.log(storedMessages)
    }


  return (
    <div>

        <div className="container border border-dark rounded mt-2 mb-2">
        <div className="row">
          <p className="mx-2 mt-2 mb-2"><b>Message Center</b></p>
        </div>
        <div className="row justify-content-end">
            <button className="btn-light btn mt-3 mb-4 mx-5" title="Refresh messages" id="refresh" style={{ backgroundImage: "url(/img/arrow-repeat.svg)", backgroundSize: 'cover', backgroundRepeat: "no-repeat", width: '14px', height: '28px'}} onClick={() => handleProductNavigation()}>
            </button>
          </div>
    {dataFetched && (<div>
    {storedMessages.map((items =>
        <div>
          <hr/>
         <div className="row mt-3">
          <p className="fs-5 col"><b>{items.creator} {convertToSimpleDate(items.timeStamp)} wrote:</b></p>
          <button className="btn-light btn mt-3 mb-4 mx-5" title="Remove message" id="refresh" style={{ backgroundImage: "url(/img/trash.svg)", backgroundSize: 'cover', backgroundRepeat: "no-repeat", width: '14px', height: '28px'}}  onClick={() => removeNotes(items.timeStamp)}></button>
         </div>
         <div className="row">
          <p className="fs-4 col">{items.message}</p>
         </div>
         <div className="row row-cols-3 justify-content-end">
         <div className="col">
           {items.escrowRead
            ?(<p className="fs-6"><i>Message reviewed by {items.escrowName}</i></p>)
            :(<p className="fs-6"><i>Message unread by Escrow Officer</i></p>)}
          </div>
          <div className="col">
           {items.notaryRead
            ?(<p className="fs-6"><i>Message reviewed by {items.notaryName}</i></p>)
            :(<p className="fs-6"><i>Message unread by Signing Agent</i></p>)}
          </div>
          <div className="col">
           {items.adminRead
            ?(<p className="fs-6"><i>Message reviewed by Pinpoint Field Services</i></p>)
            :(<p className="fs-6"><i>Message unread by Pinpoint Field Services</i></p>)}
          </div>
         </div>
         <hr/>
        </div>
    ))}</div>)}
      <hr/>
         <div className="row mt-2 mx-2">
         <label htmlFor="newMessage" className='form-label'>Add new message:</label>
         </div>
          <div className="row mb-3 mt-2 mx-2">
           <textarea className='form-control' id="message" onChange={(e)=>onChangeEvent(e)} type="textbox" value={message} rows="3"></textarea>
          </div>
          <div className="row justify-content-end mb-2 mx-2">
            <button className="btn btn-sm btn-secondary btn-rounded" id='addNotesButton' onClick={() => onSubmitHandler()}>Add New Message</button>
          </div>
        </div>
    </div>
  );
};

export default AdminApptNotesHandler;