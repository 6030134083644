// src/components/tab/tab-nav-item.js

import React from "react";
const TabNavItem = ({ id, title, activeTab, setActiveTab }) => {
 
 const handleClick = () => {
  setActiveTab(id);
 };

 
return (
   <li onClick={handleClick} className={activeTab === id ? "nav-link bg-secondary text-light ml-3 active" : "nav-link ml-3"}>
     { title }
   </li>
 );
};
export default TabNavItem;