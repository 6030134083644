import { useAuth0 } from '@auth0/auth0-react';

export const RestHelper = () => {
    
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const env = process.env.REACT_APP_ENVIRONMENT;
    const { getAccessTokenSilently } = useAuth0();

    const callAPI = async (endpointurl, options = {}, data) => {
        const defaultHTTPMethod = "GET"
        var apiservice = endpointurl
        endpointurl = serverUrl + env + apiservice
        const defaultHTTPHeaders = {  //set defaultHeaders of Http request
            'Content-Type': "application/json",
            mode: 'cors',
            Accept: 'application/json', 'Content-Type': 'application/json',
        }
        const controller = new AbortController() //using  AbortController to cancel ongoing fetch requests
        options.signal = controller.signal

        options.method = options.method || defaultHTTPMethod

        options.headers = options.headers
            ? { ...defaultHTTPHeaders, ...options.headers }
            : defaultHTTPHeaders

        options.body = JSON.stringify(options.body) || false
        if (!options.body) delete options.body

        setTimeout(() => { // cancel request if it will take more then 5s 
            controller.abort()
        }, 5000)

       // try {
       //     const mytoken = await getAccessTokenSilently();
       //     const requestOptions = {
       //       method: 'POST',
       //       mode: 'cors',
       //       body: JSON.stringify(data),
       //       headers: {
       //        Authorization: `Bearer ${mytoken}`,
       //        Accept: 'application/json', 'Content-Type': 'application/json',
       //      }
       //     }
       // } catch(err){
       //     return err
       // }
        //console.log("these are the request options:", requestOptions)


        
        try {
            const mytoken = await getAccessTokenSilently({
                authorizationParams: {
                  audience: 'https://31wcgdwrz3.execute-api.us-east-1.amazonaws.com/', // Value in Identifier field for the API being called.
                  scope: 'read:appointments role:notary role:admin role:title', // Scope that exists for the API being called. You can create these through the Auth0 Management API or through the Auth0 Dashboard in the Permissions view of your API.
                }
              });
            options = {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify(data),
                headers: {
                 Authorization: `Bearer ${mytoken}`,
                 Accept: 'application/json', 'Content-Type': 'application/json',
               }
              }
            const apiResponse = await fetch(endpointurl, options)
            return await apiResponse.json()
        } catch (err) {
            console.log('error getting user info')
            return err
        }
    }

    //calling get API For fetching data
    const get = (endpointurl, options = {}) => callAPI(endpointurl, options)

    //Post to insert 
    const postCreate = (endpointurl, options, data) => {
        options.method = "POST"
        options.body = data
        return callAPI(endpointurl, options, data)
    }

    const fileCreate = (endpointurl, data) => {
        return fileUpload(endpointurl,data)
    }
    const fileUpload = async (endpointurl, data) => {
    try {
        const options = {
            method: 'POST',
            body: data
          }
        const apiResponse = await fetch(endpointurl, options)
        return await apiResponse.json()
    } catch (err) {
        return err
    } }


    //Put Api calling
    const putUpdate = (endpointurl, options) => {
        options.method = "PUT"
        return callAPI(endpointurl, options)
    }

    //Delete Api calling
    const deletedata = (endpointurl, options) => {
        options.method = "DELETE"
        return callAPI(endpointurl, options)
    }

    return {
        get,
        postCreate,
        putUpdate,
        deletedata,
        fileCreate
    }
}