// src/components/notary-profile.js

import React, {useEffect, useState} from 'react';
import { RestHelper } from '../helper/restHelper'


const NotaryProfile = (data) => {

    const api = RestHelper()
    const [showProfile, setShowProfile] = useState(true)
    const [isPreferred, setIsPreferred] = useState(false)
    const [showPreferredStatusForm, setShowPreferredStatusForm] = useState(false)
    const [notary, setNotary] = useState({
        firstName:"",
        lastName:"",
        mobilePhone:"",
        companyName:"",
        address1:"",
        address2:"",
        city:"",
        notaryState:"",
        zip:"",
        laserPrinter:false,
        dualTrayPrinter:false,
        smsOptIn:false,
        certifiedState1:"",
        certifiedState2:"",
        certifiedState3:"",
        certifiedState4:"",
        certifiedState5:"",
        email:"",
        notaryYears:"",
        registrationStatus:"",
        updatedTime:"",
        completedCount:0,
        scheduledCount:0,
        preferredNotary: false
    })
    useEffect(() => {
      getApi()
      },[]);

    const onChangeEvent = (evt) => {
        const value = evt.target.value;
        setNotary({
            ...notary,
            [evt.target.id]: value
        })
    }

    const onToggle = () => {

    }

    const onSubmitHandler = () => {

    }

    const convertToSimpleDate = (updatedTime) => {
        const date = new Date(updatedTime*1000)
        const dateString = date.toLocaleString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true
        })
        return dateString
      }

    const setInitialValues = (res) => {
        setNotary({
            ...notary,
            ['firstName']:res['firstName'],
            ['lastName']:res['lastName'],
            ['mobilePhone']:res['mobilePhone'],
            ['companyName']:res['companyName'],
            ['address1']:res['address1'],
            ['address2']:res['address2'],
            ['city']:res['city'],
            ['notaryState']:res['notaryState'],
            ['zip']:res['zip'],
            ['laserPrinter']:res['laserPrinter'],
            ['dualTrayPrinter']:res['dualTrayPrinter'],
            ['smsOptIn']:res['smsOptIn'],
            ['certifiedState1']:res['certifiedState1'],
            ['certifiedState2']:res['certifiedState2'],
            ['certifiedState3']:res['certifiedState3'],
            ['certifiedState4']:res['certifiedState4'],
            ['certifiedState5']:res['certifiedState5'],
            ['email']:res['email'],
            ['notaryYears']:res['notaryYears'],
            ['registrationStatus']:res['registrationStatus'],
            ['updatedTime']:convertToSimpleDate(res['updatedTime']),
            ['completedCount']:res['completedCount'],
            ['scheduledCount']:res['scheduledCount'],
            ['preferredNotary']: res['preferred_notary']
        })
        setIsPreferred(res['preferred_notary'])
    }   
    const getApi = () => {
        const options = {}
        var action = 'getNotary'
        var type = ''
        var filter = data.profile
        const passedData = {'action' : action, 'type' : type, 'filter' : filter}
        api
            .postCreate('/manage-users-admin', options, passedData)
            .then(res => setInitialValues(res))
            .catch(err => console.log(err))
        }

        const updatePreferredStatus = (e) => {
          const options = {}
          var action = 'setPreferred'
          var preferredStatus = e
          var type = ''
          var filter = data.profile
          const passedData = {'action' : action, 'type' : type, 'filter' : filter, 'preferredStatus' : preferredStatus}
          api
              .postCreate('/manage-users-admin', options, passedData)
              .then(res => showProfileForm())
              .catch(err => console.log(err))
          }

    function showPreferredStatus(status){
      if (status){
        return (' - PREFERRED AGENT')
      }
      else{
        return (' - NOT SET')
      }
    }
    function showStatusForm(){
      setShowProfile(false)
      setShowPreferredStatusForm(true)
    }

    function showProfileForm(){
      setShowProfile(true)
      setShowPreferredStatusForm(false)
      getApi()
    }

    const convertToDate = (updatedTime) => {
           const date = new Date(updatedTime*1000)
           return date
       }


  return (
    <div>
      {showProfile && (
            <div className="container rounded border bg-white mt-4 mb-4" id="myInput">
                <div className='mb-3 mt-3 mx-3'><h4>Profile Details{showPreferredStatus(notary.preferredNotary)}</h4>
                <div><button className="btn-secondary btn mx-2 rounded" onClick={() => showStatusForm()}>Set Preferred Status: </button></div>
                </div>

            <div>
            <form onSubmit={(e)=>onSubmitHandler(e)} className="needs-validation">
             <div className="card mb-3 mt-3">
             <div className="card-body rounded border bg-light">
              <h5 className="card-title">Contact Info</h5>
              <div>Completed = {notary.completedCount} Scheduled = {notary.scheduledCount}</div>
              <div className="row">
               <div className="col mb-3 mt-2">
                <label htmlFor="appt_name" className='form-label'>First Name</label>
                <input className='form-control' id="firstName" onChange={(e)=>onChangeEvent(e)} type="text" value={notary.firstName} required readOnly/>
               </div> 
               <div className="col mb-3 mt-2">
                <label htmlFor="appt_name" className='form-label'>Last Name</label>
                <input className='form-control' id="lastName" onChange={(e)=>onChangeEvent(e)} type="text" value={notary.lastName} required readOnly/>
               </div>
              </div>
              <div className="row">
               <div className="col mb-3 mt-2">
                <label htmlFor="city" className='form-label'>City</label>
                <input className='form-control' id="city" onChange={(e)=>onChangeEvent(e)} type="text" value={notary.city} required readOnly/>
               </div>
              </div>
              <div className="row">
               <div className="col mb-3 mt-2">
               <label for="state" className='form-label col'>State</label>
                <input className='form-control' id="notaryState" onChange={(e)=>onChangeEvent(e)} type="text" value={notary.notaryState} required readOnly/>
               </div>
               <div className="col mb-3 mt-2">
                <label htmlFor="zip" className='form-label'>Zip Code</label>
                <input className='form-control' id="zip" onChange={(e)=>onChangeEvent(e)} type="tel" value={notary.zip} required readOnly/>
               </div>
              </div>
              <div className="mb-3 mt-2">
               <label htmlFor="appt_name" className='form-label'>Email Address</label>
               <input className='form-control' id="email" onChange={(e)=>onChangeEvent(e)} type="email" value={notary.email} required readOnly/>
              </div> 
              <div className="mb-3 mt-2">
               <label htmlFor="appt_name" className='form-label'>Mobile Phone Number</label>
               <input className='form-control' id="mobilePhone" onChange={(e)=>onChangeEvent(e)} type="tel" value={notary.mobilePhone} required readOnly/>
              </div>
              <div className="form-check form-check-reverse">
                <input className='form-check-input' id="smsOptIn" onChange={(e)=>onToggle(e)} type="checkbox" checked={notary.smsOptIn} disabled/>
               <label htmlFor="smsOptIn" className='form-check-label mx-2' for="smsOptIn">Opt in to receive notifications via SMS to this number</label>
              </div>
              <div className="mb-3 mt-2">
               <label htmlFor="updatedTime" className='form-label'>Last Updated Time</label>
               <input className='form-control' id="updatedTime" type="text" value={notary.updatedTime} readOnly/>
              </div>
              <div className="mb-3 mt-2">
               <label htmlFor="registrationStatus" className='form-label'>Registration Status</label>
               <input className='form-control' id="registrationStatus" type="text" value={notary.registrationStatus} readOnly/>
              </div>
              </div>
              </div>
              <div className="card mb-3 mt-3">
               <div className="card-body rounded border bg-light">
                <h5 className="card-title">Notary Details</h5>
                <div className="mb-3 mt-2">
                 <label htmlFor="notaryYears" className='form-label'>Years of experience</label>
                 <input className='form-control' id="notaryYears" onChange={(e)=>onChangeEvent(e)} type="tel" value={notary.notaryYears} readOnly/>
                </div>
                <div className="mb-3 mt-2">
                 <label htmlFor="companyName" className='form-label'>Company Name</label>
                 <input className='form-control' id="companyName" onChange={(e)=>onChangeEvent(e)} type="text" value={notary.companyName} readOnly/>
                </div>
                <h5 className="mb-3">Please identify up to five states in which you are a certified notary:</h5>
                <div className="row g-3">
                <div className="col-md-5">
                  <label for="certifiedState1" className='form-label col'>First State</label>
                  <input className='form-control' id="certifiedState1" onChange={(e)=>onChangeEvent(e)} type="text" value={notary.certifiedState1} required readOnly/>
                </div>
                <div className="col-md-5">
                  <label for="certifiedState2" className='form-label col'>Second State</label>
                  <input className='form-control' id="certifiedState2" onChange={(e)=>onChangeEvent(e)} type="text" value={notary.certifiedState2} required readOnly/>
                </div>
                <div className="col-md-5">
                  <label for="certifiedState3" className='form-label col'>Third State</label>
                  <input className='form-control' id="certifiedState3" onChange={(e)=>onChangeEvent(e)} type="text" value={notary.certifiedState3} required readOnly/>
                </div>
                <div className="col-md-5">
                  <label htmlFor="certifiedState4" className='form-label col'>Fourth State</label>
                  <input className='form-control' id="certifiedState4" onChange={(e)=>onChangeEvent(e)} type="text" value={notary.certifiedState4} required readOnly/>
                </div>
                <div className="col-md-5">
                  <label htmlFor="certifiedState5" className='form-label col'>Fifth State</label>
                  <input className='form-control' id="certifiedState5" onChange={(e)=>onChangeEvent(e)} type="text" value={notary.certifiedState5} required readOnly/>
                </div>
                </div>
                <div className="form-check form-check-reverse">
                  <input className='form-check-input' id="laserPrinter" onChange={(e)=>onToggle(e)} type="checkbox" checked={notary.laserPrinter} disabled/>
                 <label htmlFor="laserPrinter" className='form-check-label' for="laserPrinter">Laser Printer</label>
                </div>
                <div className="form-check form-check-reverse">
                  <input className='form-check-input' id="dualTrayPrinter" onChange={(e)=>onToggle(e)} type="checkbox" checked={notary.dualTrayPrinter} disabled/>
                 <label htmlFor="dualTrayPrinter" className='form-check-label' for="dualTrayPrinter">Dual Tray Printer</label>
                </div>
               </div>
 
             </div>
             </form>
            </div>
            </div>
      )}
      {showPreferredStatusForm &&
      <div>
          <div className="card mb-3 mt-3">
             <div className="card-body rounded border bg-light">
              Preferred Status for {notary.firstName} {notary.lastName}: {showPreferredStatus(notary.preferredNotary)}
              <div>
              {isPreferred
              ?<button className='btn-secondary border btn mt-2 mx-2 rounded' onClick={()=> updatePreferredStatus(false)}>Remove Preferred Status</button>
              :<button className='btn-secondary border btn mt-2 mx-2 rounded' onClick={()=> updatePreferredStatus(true)}>Set As Preferred</button>}
              </div>
              </div>
          </div>
        </div>
        }
    </div>
  );
};

export default NotaryProfile;