// src/components/login-button.js

import React, { useState, Suspense, useEffect} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { RestHelper } from '../helper/restHelper'
//import CallSecureApi from '../helper/apiHelper';
//const CallApi = React.lazy(() => import('./apiPost'));
//const CallApi = React.lazy(() => import('../helper/apiHelper'));

var myToken = ''


const ProfileForm2 =  () => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [sendApi, setSendApi] = useState(false)
  const [formInput, setformInput] = useState('')
  const [message, setApiRes] = useState('')

  const api = RestHelper()

  interface FormDataType {firstName:string, lastName: string, email: string}
  const responseBody: FormDataType = {firstName: "", lastName: "", email: "0"}

  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      responseBody.firstName = firstName
      responseBody.lastName = lastName
      responseBody.email = email
      setSendApi(true)
      setformInput({firstName:responseBody.firstName,lastName:responseBody.lastName,email:responseBody.email})
      const myOptions = {}
      const datatosend = (responseBody)
      api
        //.get({formInput})
       // .get('get-basic-response')
        .postCreate('/hello-world-python',myOptions, datatosend)
        .then(res => setApiRes(res))
        .catch(err => console.log(err))

  //Form submission happens here
  }
  const inputChangeHandler = (setFunction: React.Dispatch<React.SetStateAction<string>>, event: React.ChangeEvent<HTMLInputElement>) => {
      setFunction(event.target.value)
  }

 //if (sendApi) {
 // return(
 //       <div className="container rounded">
 //           <Suspense fallback={<div>Loading...</div>}>
 //           <CallApi data={formInput}/>
 //           </Suspense>
 //       </div>
 //   )
 // }
  return(
      
      <div>
      <form className="rounded text-light bg-dark p-4" onSubmit={onSubmitHandler}>
          <div className="form-group"><label htmlFor="first_name">First Name</label></div>
          <div><input id="first_name" onChange={(e)=>inputChangeHandler(setFirstName, e)} type="text"/></div>
          <div className="form-group"><label htmlFor="last_name">Last Name</label></div>
          <div><input id="last_name" onChange={(e)=>inputChangeHandler(setLastName, e)} type="text"/></div>
          <div className="form-group"><label htmlFor="email">Email</label></div>
          <div><input id="email" onChange={(e)=>inputChangeHandler(setEmail, e)} type="email" placeholder='Enter valid email'/></div>
          <input type="submit"/>
      </form>
      {message && (
        <div className="mt-5">
          <h6 className="muted">Result</h6>
          <div className="container-fluid rounded">
            <div className="row">
              <code className="col-12 text-light bg-dark p-4 rounded">{message.message}</code>
            </div>
          </div>
        </div>
      )}
      </div>
  )
};

export default ProfileForm2;