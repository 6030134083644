import React, { Fragment, useEffect, useState, useContext} from "react";

import { RestHelper } from "../../helper/restHelper";
import { UserContext } from "../../app"


const AdminPickNewNotary = (data) => {
    const [errorMessage, setErrorMessage] = useState(false)
    const [showForm, setShowForm] = useState(true)
    const [notaryState, setNotaryState] = useState('10 miles')
    const [zipCode, setZipCode] = useState('')
    const [apiRes, setApiRes] = useState([])
    const [notaryCount, setNotaryCount] = useState('')
    const [showResults, setShowResults] = useState(false)
    const [apiMessage, setApiMessage] = useState('')
    const [apiDetails, setApiDetails] = useState('')
    const [newNotary, setNewNotary] = useState('')
    const [newNotaryEmail, setNewNotaryEmail] = useState('')
    const [notaryMessage, setNotaryMessage] = useState('')
    const [notarySelected, setNotarySelected] = useState(false)
    const [selectedValue, setSelectedValue] = useState('')
    const [showResponse, setShowResponse] = useState(false)
    const [updateResponse, setUpdateResponse] = useState([])
    const api = RestHelper()

    useEffect(() => {
        getNotaryList()
    },[])

      const setResponse = (res) => {
        setApiRes(res)
      }
      const addState = (evt, zip) => {
        const value = evt.target.value
        setNotaryState(evt.target.value)
        setZipCode(zip)
      }

      function handleSelect(evt, email) {
        setSelectedValue(evt)
        setNewNotary(evt.notary_first_name + ' ' + evt.notary_last_name)
        setNewNotaryEmail(email)
        setNotarySelected(true)
        console.log(email)
      }

      function updateNotary() {
        assignNewNotary()
      }
  
      const onClickHandler = () => {
        getNotaryList()
      }

      const onChangeEvent = (e) => {
        setNotaryMessage(e.target.value)
      }
      const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        getApi()
    }

    const getNotaryList = () => {
        const options = {}
        const params = {'request_id': data.requestId, 'action' : 'getNotaryBidList'}
        api
            .postCreate('/manage-appts-admin', options, params)
            .then(res => setResponse(res))
            .catch(err => console.log(err))
            setShowResults(true)
            setShowResponse(true)
    }
    const getApi = () => {
        const options = {}
        const action = 'get'
        const type = 'notary'
        const filter = notaryState
        const passedData = {'action' : action, 'type' : type, 'filter' : filter, 'zip' : zipCode}
        api
            .postCreate('/manage-users-admin', options, passedData)
            .then(res => setResponse(res))
            .catch(err => console.log(err))
        setShowResults(true)
        setShowResponse(true)
        }

    const assignNewNotary = () => {
        const options = {}
        const params = {'request_id': data.requestId, 'action' : 'updateNotary', 'newNotary' : newNotaryEmail, 'notaryNotes' : notaryMessage}
        api
            .postCreate('/manage-appts-admin', options, params)
            .then(res => setUpdateResponse(res))
            .catch(err => console.log(err))
        }

    function clearErrorMessage () {

    }
    return (
        <Fragment>
            {errorMessage && (
                <div className="container border rounded">
                <div className='row rounded bg-white'>
                 <button className="close ml-3 mb-2 mt-2" aria-label="Close" onClick={() =>clearErrorMessage()}>
                  <span aria-hidden='false'>&times;</span>
                 </button>
                </div>
                <div className="card mb-3 mt-3">
                    <div className="card-body rounded-border bg-white">
                        <h5 className='card-title'>Appointment Status</h5>
                        <h6 className='card-subtitle mb-2'>{ apiMessage }</h6>
                        <h5 className='card-subtitle mb-2'>{ apiDetails }</h5>
                    </div>
              </div>
              </div>
            )}
            {showForm && (
                <div className='container border rounded'>
                    <div>
                    {data.notary.map((items =>
                    <div>
                        <div className="row mx-1 mt-3 mb-3">
                            <h4 className="col">Update Signing Agent Assignment</h4>
                        </div>
                        <div className='row mx-3'>
                        <label htmlFor="notaryNotes" className='form-label'>Notes for displaced signing agent (optional):</label>
                        </div>
                        <div className='row mx-3'>
                        <input className='form-control' id="notaryNotes" onChange={(e)=>onChangeEvent(e)} type="text" value={notaryMessage} />
                        </div>
                        <div className="row mx-3 mt-3 mb-3">
                            <p>Currently Assigned Signing Agent: {items.assigned_notary} </p>
                        </div>
                        <div className='row mx-3 mt-3 mb-3'>
                            <p> New Signing Agent: {newNotary}</p>
                        </div>
                        <div className="row">{notarySelected && <button type='submit' className='btn rounded btn-md btn-secondary mx-3 mb-3' onClick={()=>updateNotary()}>Update Notary</button>}</div>
                    </div>))}
                    {showResponse &&
                    <div>
                        {updateResponse.map((items =>
                            <div className="row mx-3 mb-3">
                                {items.message}
                            </div>
                        ))}
                    </div>
                }
                </div></div>)}
            
            {showResults && (
                <div>
                    <div className="container-fluid rounded">
                        <div >
                            <div style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                            <table className="table">
                                <thead>
                                    <tr scope="row">
                                        <th scope="col">Signing Agent Name</th>
                                        <th scope="col">Phone</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Bid</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {apiRes.map((items =>
                                        <tr 
                                            scope="row"
                                            key={items.RequestId}
                                            style={{
                                                cursor: 'pointer',
                                                backgroundColor: selectedValue === items ? 'lightgray' : 'white',
                                            }}
                                            onClick={() => handleSelect(items, items.notary_email)}>
                                            <td>{items.notary_first_name} {items.notary_last_name}</td>
                                            <td>{items.notary_phone}</td>
                                            <td>{items.notary_email}</td>
                                            <td>${items.notary_bid}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            </div>
                            
                        </div>
                     </div>
                </div>)}
            </Fragment>
    )
   };

export default AdminPickNewNotary;