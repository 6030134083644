

export const RestHelperNoAuth = () => {
    
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const env = process.env.REACT_APP_ENVIRONMENT;

    const callAPI = async (endpointurl, options = {}, data) => {
        const defaultHTTPMethod = "GET"
        var apiservice = endpointurl
        endpointurl = serverUrl + env + apiservice
        const defaultHTTPHeaders = {  //set defaultHeaders of Http request
            'Content-Type': "application/json",
            mode: 'cors',
            Accept: 'application/json', 'Content-Type': 'application/json',
        }
        const controller = new AbortController() //using  AbortController to cancel ongoing fetch requests
        options.signal = controller.signal

        options.method = options.method || defaultHTTPMethod

        options.headers = options.headers
            ? { ...defaultHTTPHeaders, ...options.headers }
            : defaultHTTPHeaders

        options.body = JSON.stringify(options.body) || false
        if (!options.body) delete options.body

        setTimeout(() => { // cancel request if it will take more then 5s 
            controller.abort()
        }, 5000)

       // try {
       //     const mytoken = await getAccessTokenSilently();
       //     const requestOptions = {
       //       method: 'POST',
       //       mode: 'cors',
       //       body: JSON.stringify(data),
       //       headers: {
       //        Authorization: `Bearer ${mytoken}`,
       //        Accept: 'application/json', 'Content-Type': 'application/json',
       //      }
       //     }
       // } catch(err){
       //     return err
       // }
        //console.log("these are the request options:", requestOptions)
        if (options.method == 'POST') {
            try {
                options = {
                    method: 'POST',
                    mode: 'cors',
                    body: JSON.stringify(data),
                    headers: {
                     Accept: 'application/json', 'Content-Type': 'application/json',
                   }
                }
                const apiResponse = await fetch(endpointurl, options)
                return await apiResponse.json()
            } catch (err) {
                return err
            }
        }
        try {
            options = {
                method: 'GET',
                mode: 'cors',
                headers: {
                 Accept: 'application/json', 'Content-Type': 'application/json',
               }
            }
            const apiResponse = await fetch(endpointurl, options)
            return await apiResponse.json()
        } catch (err) {
            return err
        }
    }

    //calling get API For fetching data
    const get = (endpointurl, options = {}) => callAPI(endpointurl, options)

    //Post to insert 
    const postCreate = (endpointurl, options, data) => {
        options.method = "POST"
        options.body = data
        return callAPI(endpointurl, options, data)
    }


    //Put Api calling
    const putUpdate = (endpointurl, options) => {
        options.method = "PUT"
        return callAPI(endpointurl, options)
    }

    //Delete Api calling
    const deletedata = (endpointurl, options) => {
        options.method = "DELETE"
        return callAPI(endpointurl, options)
    }

    return {
        get,
        postCreate,
        putUpdate,
        deletedata,
    }
}