import React from "react";

//const logo = "https://cdn.auth0.com/blog/auth0-react-sample/assets/logo.png";
//const logo = "/img/radiant_tech_logo.jpg";
//<div className='container rounded' style={{ backgroundImage: "url(/img/office-pic.jpg)", backgroundRepeat: "no-repeat", backgroundSize: 'cover', opacity: 0.9, width: '75vw', height: '50vh', backgroundPosition: 'center'}}></div>
//<div className='hero-container rounded mt-2 mb-2'><img className='hero-img' src='/img/pfs_full_logo.png'/></div>

const logo = "/img/office-pic.jpg";

const AutoHero = () => (
    <div className="d-flex flex-column h-100">
        <div className="container d-flex justify-content-center align-items-center" style={{ backgroundSize: 'cover', width: '75vw', height: '5vw', minHeight: '100px', padding: '20px' }}>
            <div className="d-flex justify-content-center align-items-center" style={{ opacity: 1.0 }}>
                <button type="button" className="btn rounded btn-success btn-lg ">Request An Appraisal</button>
            </div>
        </div>
        <div className='container rounded' style={{ backgroundImage: "url(/img/auto-hero-2.jpg)", backgroundRepeat: "no-repeat", backgroundSize: 'cover', opacity: 0.8, width: '75vw', height: '60vh', backgroundPosition: 'center' }}>
            <div className="row mt-4 align-items-center" style={{ opacity: 1.0 }}>
                <div className="col"></div>
                <div className="col-9">
                    <h1 className="text-center text-white"><strong>CERTIFIED AUTO APPRAISAL</strong></h1>
                </div>
                <div className="col">
                </div>
            </div>
            <div className="row mt-1 align-items-center" style={{ opacity: 1.0 }}>
                <h4 className="col text-center text-white"><strong>INSURANCE | FINANCE | MARKET VALUE</strong></h4>
            </div>
        </div>


        <div className="container d-flex" style={{ backgroundSize: 'cover', width: '75vw'}}>
            <div className="row d-flex mt-4 mb-4">
                    <div className="col-md-4 col-12 mb-4 d-flex">
                    <div className="rounded p-3 border bg-dark text-white text-center w-100 d-flex flex-column justify-content-center">
                        <h2>Honest, Fair and Certified Appraisers</h2>
                    </div>
                    </div>
                    <div className="col-md-4 col-12 mb-4 d-flex">
                    <div className="rounded p-3 border bg-dark text-white text-center w-100 d-flex flex-column justify-content-center">
                        <h2>Nationwide Appraisals:</h2>
                        <h2>In-Person or Virtual</h2>
                    </div>
                    </div>
                    <div className="col-md-4 col-12 mb-4 d-flex">
                    <div className="rounded p-3 border bg-dark text-white text-center w-100 d-flex flex-column justify-content-center">
                        <h2>Certified Documents Provided Electronically</h2>
                    </div>
                    </div>
            </div>
        </div>
        <div className='container rounded' style={{ backgroundImage: "url(/img/auto-hero-1.jpg)", backgroundRepeat: "no-repeat", backgroundSize: 'cover', opacity: 0.7, width: '75vw', height: '60vh', backgroundPosition: 'center' }}>

        </div>
        <div className="container" style={{ backgroundSize: 'cover', width: '75vw', height: '10vw', minHeight: '300px', padding: '20px' }}>
            <div className="d-flex justify-content-center align-items-end mt-4" style={{ opacity: 1.0 }}>
                <button type="button" className="btn rounded btn-success btn-lg">Learn More</button>
            </div>
            <div className="d-flex mt-2 align-items-end" style={{ opacity: 1.0}}>
                <div className="col"></div>
                <div className="col-9">
                    <h1 className="text-center" style={{color: "black"}}><strong>Customer Reviews</strong></h1>
                </div>
                <div className="col">
                </div>
            </div>
        </div>
        <div className='container' style={{ 
            backgroundImage: "url(/img/auto-hero-3.jpg)", 
            backgroundRepeat: "no-repeat", 
            backgroundSize: 'cover', 
            opacity: 0.7, 
            width: '75vw', 
            height: '60vh', 
            backgroundPosition: 'center',
            zIndex: 1
            }}></div>
            <div className="container" style={{
                zIndex: 2
            }}>
            <div className="row mt-4 align-items-center" style={{ opacity: 1.0 }}>
                <div className="col">
                    <h1 className="text-center text-black"><strong>CALL US TODAY!</strong></h1>
                </div>
            </div>
            </div>
        </div>
);

export default AutoHero;