// src/components/notary-completed.js

import React, { useState, Fragment, useEffect } from 'react';
import { RestHelper } from '../helper/restHelper'
import { NotaryViewRequestDetails } from '../components'



const NotaryCompleted = (profileEmail) => {
  const [message, setApiRes] = useState([])
  const [viewState, setViewState] = useState(false)
  const [viewRequest, setViewRequest] = useState(false)
  const [requestId, setRequestId] = useState('')
  const [dataFetched, setDataFetched] = useState(false)


  const activeUser = {'activeUser': profileEmail.profileEmail }
  const action = {'action' : 'Completed'}
 
  const api = RestHelper()
 
  useEffect (() => {
    changeView();
  }, []);

  // if Check Pending Requests is selected, we'll query for pending requests


  const changeView = () => {
    if (viewState){
        setViewState(false);
        setViewRequest(false)
        setDataFetched(false)
        setApiRes([])
    }
    else{
    setViewState(true);
    setViewRequest(false)
    const options = {}
    if(!dataFetched){
        api
            .postCreate('/get-notary-open-requests', options, action)
            .then(res => setApiRes(res))
            .catch(err => console.log(err))
        }
        setDataFetched(true)
    }
  }

  const getRequest = (data) => {
    setRequestId(data.RequestId)
    setViewRequest(true);
    setViewState(false)
  }
  

  return(
    <div>
  {viewRequest
    ? (<div className='container mb-3 border rounded bg-light'>
        <div className='row rounded bg-white'>
            <button className="close ml-3 mb-2 mt-2" aria-label="Close" onClick={() =>changeView()}>
                <span aria-hidden='false'>&times;</span>
            </button>
        </div>
        <Fragment>
            <NotaryViewRequestDetails requestId={requestId} status='completed'/>
         </Fragment>
        </div>)
    : <div></div>
    }
  {viewState && (
        <div className="mt-5">
          <h6 className="muted">Here are your recently completed requests:</h6>
          <div className="container-fluid rounded">
            <div className="card mx-2">
                <div className="card-body">
                    {message.map((items =>
                         <div className='container bg-light rounded border'>
                         <button className='btn btn-secondary mt-3 mb-2 rounded' type='button' onClick={() => getRequest(items)}>{items.Appt_Name} Details</button>
                         <div className="row">
                            <div className="col">
                                <h6 classname="card-text">Status:</h6>
                            </div>
                            <div className="col">
                                <p className="card-text">{items.Status}</p>
                            </div>
                        </div>
                        <div className="row">
                          <div className="col">
                                <h6 className="card-text">Location:</h6>
                            </div>
                            <div className="col">
                                <p className="card-text">{items.Appt_City}, {items.Appt_State}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <h6 classname="card-text">Appointment Date:</h6>
                            </div>
                            <div className="col">
                                <p className="card-text">{items.Appt_Date}</p>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col">
                                <h6 classname="card-text">Signing Agent Fee:</h6>
                            </div>
                            <div className="col">
                                 <p className="card-text">${items.Appt_Bid}</p>
                            </div>
                        </div>
                         </div>
                    ))}    
                </div>
            </div>
          </div>
        </div>
      )}
</div>
  )
};

export default NotaryCompleted;