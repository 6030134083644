// src/components/originator-pending.js

import React, { useState, useEffect, Fragment} from 'react'
import { RestHelper } from '../helper/restHelper'
import { ApptNotesHandler, GeneralFileUpload, GeneralUploadStatus } from '../components'



const NotaryViewRequestDetails = (data) => {  

  const [requestDetails, setRequestDetails] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [showDetails, setShowDetails] = useState(true)
  const [showResponse, setShowResponse] = useState(false)
  const [updateResponse, setUpdateResponse] = useState([])
  const [showFileUpload, setShowFileUpload] = useState(false)
  const [showMessageCenter, setShowMessageCenter] = useState(false)
  const [showEscrow, setShowEscrow] = useState(false)
  const api = RestHelper()

  useEffect(() => {
    const reqData = { data }
    const options = {}
    console.log(data)
    if (data.status === 'scheduled') {
      setShowFileUpload(true)
      setShowEscrow(true)
    }
    if (data.status === 'scheduled') {
      setShowMessageCenter(true)
    }
    if(!isLoaded){
    api
        .postCreate('/get-request-details', options, reqData)
        .then(res => loadRequestDetails(res))
        .catch(err => console.log(err))
    setIsLoaded(true)
    }
  },[])

  function loadRequestDetails(res) {
    setRequestDetails(res)
    // notary is allowed to upload files for 72 hours after the file
    if (isWithin72Hours(res[0].apptNumericalTime) && (data.status === 'completed')){
      setShowFileUpload(true)
      setShowMessageCenter(true)
    }
  }


  function isWithin72Hours(epochTime) {
    // Convert epoch time to Date object
    const givenTime = new Date(epochTime*1000);
 
    // Get the current time
    const currentTime = new Date();
  
    // Calculate the difference in milliseconds
    const timeDifference = currentTime - givenTime;
  
    // Convert 72 hours to milliseconds
    const seventyTwoHours = 72 * 60 * 60 * 1000;
  
    // Check if the difference is more than 72 hours
    return timeDifference <= seventyTwoHours;
  }

  function clearResponseMessage() {
    if (data.status === 'scheduled') {
      setShowFileUpload(true)
      setShowEscrow(true)
    }
    const reqData = { data }
    const options = {}
    api
      .postCreate('/get-request-details', options, reqData)
      .then(res => setRequestDetails(res))
      .catch(err => console.log(err))
  }

  const scanBackCheck = (checkFlag) => {
    var scanBackValue = ''
    if (checkFlag === 'true') {
      scanBackValue = 'SCAN BACKS ARE REQUIRED'
    }
    else{
      scanBackValue = 'NONE'
    }
    return scanBackValue

  }

  function setTransactionType(setValue) {
    if (setValue === '0') {
      return 'Not Defined'
    }
    else if (setValue === '1') {
      return 'Purchase'
    }
    else if (setValue === '2') {
      return 'Refinance'
    }
    else if (setValue === '3') {
      return 'Sellers Deed Set'
    }
    else if (setValue === '4') {
      return 'Other'
    }
    else {
      return 'Not Defined'
    }
  }

  function setFinanceType(setValue) {
    if (setValue === '10') {
      return 'Not Defined'
    }
    else if (setValue === '11') {
      return 'FHA'
    }
    else if (setValue === '12') {
      return 'Conventional'
    }
    else if (setValue === '13') {
      return 'Reverse'
    }
    else if (setValue === '14') {
      return 'HELOC'
    }
    else if (setValue === '15') {
      return 'Commercial'
    }
    else if (setValue === '16') {
      return 'VA'
    }
    else {
      return 'Not Defined'
    }
  }

  const dateFormat = (inputDate) => {
    const dateArray = inputDate.split("-")
    const date = dateArray[1] + '/' + dateArray[2] + '/' + dateArray[0]
    return date
  }
  function convertToSimpleDateMessage(updatedTime) {
    const date = new Date(updatedTime*1000)
    const dateString = date.toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    })
    return dateString
  }

  const getTempUrl = (e) => {
    const options = {}
    const fileData = {'unique_id' : data.requestId, 'file_name' : e }
    api
        .postCreate('/get-stored-file', options, fileData)
        .then(res => window.open(res.get_url,"_blank"))
        .catch(err => console.log(err))
    }

    
      const handleDownload = (res, filename) => {
        const fileUrl = res.get_url; 
    
        fetch(fileUrl)
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename); // Replace with your desired file name and extension
            document.body.appendChild(link);
            link.click();
            link.remove();
          })
          .catch((error) => {
            console.error('Error downloading file:', error);
          });
      }; 
      function setAction(passedAction) {
        if (passedAction === 'write') {
          return 'Uploaded'
        }
        else {
          return 'Downloaded'
        }
      }


  return(
   <div className="card-body bg-light border-secondary">
    {showDetails && (<div>
    {requestDetails.map((items =>
            <div>
                <h5 className='card-title'>{items.apptName} Details</h5><i className="bi bi-upload"></i>
                <div className='card-body'>
                <div className="row mt-3">
                  <div className='col mx-3'><h6>Appointment Name:</h6></div>
                  <div className='col mx-3'>{items.apptName}</div>
                </div>
                <div>{items.notaryStatus.map(items2 =>
                <div>
                <div className="row mt-3">
                    <div className='col mx-3'><h6>Status:</h6></div>
                    <div className='col mx-3'>{items2.apptNotaryApproval}</div>
                </div>
                <div className="row mt-3">
                  <div className='col mx-3'><h6>Signing Agent Fee:</h6></div>
                  <div className='col mx-3'>${items2.apptNotaryBid}</div>
                </div>
                </div>
                )}</div>
                <div className="row mt-3">
                  <div className='col mx-3'><h6>When:</h6></div>
                  <div className='col mx-3'>{dateFormat(items.apptDate)} at {items.apptTime} {items.appt_timeZone}</div>
                </div>
                <div className="row mt-3">
                  <div className='col mx-3'><h6>Where:</h6></div>
                  <div className='col mx-3'>{items.apptStreet} {items.apptCity}, {items.apptState} {items.apptZip}</div>
                </div>
                <div className="row mt-3">
                  <div className='col mx-3'><h6>Lender:</h6></div>
                  <div className='col mx-3'>{items.lender}</div>
                </div>
                <div className="row mt-3">
                    <div className="col mx-3"><h6>Scan Backs:</h6></div>
                    <div className="col mx-3">{scanBackCheck(items.scanBack)}</div>
                </div>
                <div className="row mt-3">
                    <div className="col mx-3"><h6>Transaction Type:</h6></div>
                    <div className="col mx-3">{setTransactionType(items.transactionType)}</div>
                </div>
                <div className="row mt-3">
                    <div className="col mx-3"><h6>Finance Type:</h6></div>
                    <div className="col mx-3">{setFinanceType(items.financeType)}</div>
                </div>
                <div className="row mt-3">
                  <div className='col mx-3'><h6>Extra Notes:</h6></div>
                  <div className='col mx-3'>{items.apptMessages}</div>
                </div>
                {showEscrow &&
                (<div><div className="row mt-3">
                  <div className='col mx-3'><h6>Escrow Contact:</h6></div>
                  <div className='col mx-3'>{items.escrowName} - {items.escrowEmail}</div>
                </div>
                
          <div className="row mt-3">
            <div className="col mx-3"><h6>Signer Name:</h6></div>
            <div className="col mx-3">{items.apptContactName}</div>
          </div>
          <div className="row mt-3">
            <div className="col mx-3"><h6>Signer Phone Primary:</h6></div>
            <div className="col mx-3">{items.apptContactPhone1}</div>
          </div>
          <div className="row mt-3">
            <div className="col mx-3"><h6>Signer Phone Secondary:</h6></div>
            <div className="col mx-3">{items.apptContactPhone2}</div>
          </div>
          <div className="row mt-3">
            <div className="col mx-3"><h6>Signer Email Primary:</h6></div>
            <div className="col mx-3">{items.apptContactEmail1}</div>
          </div>
          <div className="row mt-3">
            <div className="col mx-3"><h6>Signer Email Secondary:</h6></div>
            <div className="col mx-3">{items.apptContactEmail2}</div>
          </div></div>)}
                <div className="row mt-3">
                 <div className='col mx-3'><h6>File Attachment List (files may not be accessible via mobile device):</h6></div>
                </div>
                <div className="card bg-light mb-2">
                    {items.fileLogInfo.map(o =>
                        <div className="card-body border">
                        <div className="row">
                          <button className="list-group-item border-light bg-light text-left text-decoration-underline text-primary" title="Download File" type="button" onClick= {() => {getTempUrl(o.fileName)}}>{o.fileName}</button>
                        </div>
                        <div className="card-footer">
                        {o.fileReadInfo.map(info =>
                         <div> <p className="small">{setAction(info.action)} by {info.user} - {convertToSimpleDateMessage(info.loggedTime)} <GeneralUploadStatus confirm ={info.confirmed} action = {info.action}/></p></div>
                         )}
                         </div>
                        </div>
                        )}
                </div>
          {showFileUpload &&
            <Fragment>
              <GeneralFileUpload requestId={data.requestId} clearResponseMessage={clearResponseMessage} />

            </Fragment>
          }
          {showMessageCenter &&
            <Fragment>
              <ApptNotesHandler requestId={data.requestId} />
            </Fragment>}
        </div>
            </div>
            ))}</div>)
  }
  {showResponse &&
   <div>
    {updateResponse.map((items =>
      <div>
        {items.message}
      </div>
    ))}
    </div>
}
            
</div>
  )
};

export default NotaryViewRequestDetails;