// src/views/profile.js

import { React, useState, useEffect, Fragment, useContext } from 'react';

import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Loading, ProfileForm, ProfileForm2 } from '../components';
import { NotaryManageProfile } from '../components/profile';
import { RestHelper } from '../helper/restHelper';
import { UserContext } from "../app"

const ProfileDetails = () => {
    const [message, setApiRes] = useState([])
    const {userProfile, setUser } = useContext(UserContext)
    const [isNotary, setIsNotary] = useState(false)
    const { user } = useAuth0();
    const { email } = user;
    const profileEmail = {'profileEmail' : email}
    const api = RestHelper()
    const options = {}
    
    
    useEffect (() => {
      if (userProfile.profileRole == 'notary') {
        setIsNotary(true)
      }  
      getProfile();
      }, []);
    
    const getProfile = () => {
    api
    .postCreate('/get-profile-details', options, profileEmail)
    .then(res => setApiRes(res))
    .catch(err => console.log(err))
    }


 
  return (
<div>
    {isNotary 
    ? (
    <Fragment>
    <NotaryManageProfile/>
    </Fragment>)
    :
    <div>
    {message.map((items =>
            <div>
                <div className="row mt-3">
                  <div className='col mx-3'><h6>Name:</h6></div>
                  <div className='col mx-3'>{items.profileName}</div>
                </div>
                <div className="row mt-3">
                  <div className='col mx-3'><h6>Organization:</h6></div>
                  <div className='col mx-3'>{items.profileOrganization}</div>
                </div>
                <div className="row mt-3">
                  <div className='col mx-3'><h6>Role:</h6></div>
                  <div className='col mx-3'>{items.profileRole}</div>
                </div>
                <div className="row mt-3">
                  <div className='col mx-3'><h6>Phone Number:</h6></div>
                  <div className='col mx-3'>{items.profilePhone}</div>
                </div>
                <div className="row mt-3">
                  <div className='col mx-3'><h6>E-mail:</h6></div>
                  <div className='col mx-3'>{items.profileEmail}</div>
                  </div>
            </div>
            ))}
            </div>}
</div>
  );
};

export default ProfileDetails;