// src/views/external-api.js

import React, { useState, memo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const CallApi = (messageData) => {

  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [message, setMessage] = useState('')
  
  const { getAccessTokenSilently } = useAuth0();

  console.log({messageData})
  const callSecureApi = async () => {
    try {
      const token = await getAccessTokenSilently();
      const requestOptions = {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(messageData.data),
        headers: {
         Authorization: `Bearer ${token}`,
         Accept: 'application/json', 'Content-Type': 'application/json',
       }
      }
     
      const response = await fetch(
        `${serverUrl}/dev/hello-world-python`, requestOptions);
        
        const responseData = await response.json();
        console.log(responseData.message)
        setMessage(responseData.message)
    } catch (error) {
      const response = 'Error'
    }
    return {message}
  };

  return (
    <div className="container-fluid rounded">
        {callSecureApi().response},
        {message && (
        <div className="mt-5">
          <h6 className="muted">Result</h6>
          <div className="container-fluid rounded">
            <div className="row">
              <code className="col-12 text-light bg-dark p-4 rounded">{message}</code>
            </div>
          </div>
        </div>
      )}
      </div>
  );
};

export default CallApi;
