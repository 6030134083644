import React from "react";
import {NavLink} from "react-router-dom";


const HomeContent = () => (
  <div className="next-steps">
    <h2 className="my-5 text-center">Interested and want to learn more?</h2>

    <div className="row">
      <div className="col-md-5 mb-4">
        <h6 className="mb-3">
        <NavLink
          to="/escrow"
          exact
          className="nav-link"
          activeClassName="router-link-exact-active">
          <i className="fas fa-link mr-2" />
          Register as an Escrow Officer
        </NavLink>
        </h6>
        <p>
          Our decades of experience in the business ensure you won't be worrying if the deal will get completed.  Sign up here to partner with us on your future assignments.
        </p>
      </div>

      <div className="col-md" />

      <div className="col-md-5 mb-4">
        <h6 className="mb-3">
        <NavLink
          to="/sign-up"
          exact
          className="nav-link"
          activeClassName="router-link-exact-active">
          <i className="fas fa-link mr-2" />
          Register as a Signing Agent or Field Inspector
        </NavLink>
        </h6>
        <p>
          Quick and easy registration for signing agents and field inspectors who wish to be alerted for opportunities in their area!
        </p>
      </div>
    </div>

    <div className="row">
      <div className="col-md-5 mb-4">
        <h6 className="mb-3">
        
        <NavLink
          to="/about-us"
          exact
          className="nav-link"
          activeClassName="router-link-exact-active">
          <i className="fas fa-link mr-2" />
        About Us
        </NavLink>
        </h6>
        <p>
          Find out what makes Pinpoint Field Services the preferred solution for your needs and all about our team!
        </p>
      </div>

      <div className="col-md" />

      <div className="col-md-5 mb-4">
        <h6 className="mb-3">
        <NavLink
          to="/support"
          exact
          className="nav-link"
          activeClassName="router-link-exact-active">
          <i className="fas fa-link mr-2" />
          Contact Us
        </NavLink>
        </h6>
        <p>
          Have questions or need more information?  Did you encounter an issue in using our service?  Please contact us through this link and our team will be on it!
        </p>
      </div>
    </div>
  </div>
);

export default HomeContent;
