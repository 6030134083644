// src/components/admin/admin-review-appt-req.js

import React, { useState, Fragment, useEffect, useContext } from 'react';
import { RestHelper } from '../../helper/restHelper'
import { UserContext } from "../../app"
import { AdminEditRequest, AdminFirmEscrowList, AdminViewRequest} from "../../components/admin"
import {CloseOutButton} from "../../components"




const AdminManageFirms = (profileEmail) => {
    const {userProfile, setUser } = useContext(UserContext)
    const [apiRes, setApiRes] = useState([])
    const [apiUpdate, setApiUpdate] = useState([])
    const [orgList, setOrgList] = useState ({})
    const [initialFirmList, setInitialFirmList] = useState([])
    const [searchStatus, setSearchStatus] = useState('Open')
    const [selectedFirmId, setSelectedFirmId] = useState()
    const [showResults, setShowResults] = useState(false)
    const [viewRequest, setViewRequest] = useState(false)
    const [showOpen, setShowOpen] = useState(false)
    const [requestId, setRequestId] = useState('')
    const [escrowList, setEscrowList] = useState(false)
    const [orgId, setOrgId] = useState()
    const [orgName, setOrgName] = useState()
    const api = RestHelper()
   
    useEffect (() => {
      getFirmList();
    }, [apiUpdate, setApiUpdate]);
    

    const changeView = () => {
      setViewRequest(false)
      setShowResults(true)
      getApi()
    }
    const getRequest = (data) => {
      setRequestId(data.RequestId)
      setViewRequest(true);
      setShowResults(false)
    }

    // if Check Pending Requests is selected, we'll query for pending requests
  
    const getApi = () => {
      const options = {}
      const passedData = {'firmId' : selectedFirmId, 'status' : searchStatus}
      api
          .postCreate('/get-admin-pending-appts', options, passedData)
          .then(res => setResponse(res))
          .catch(err => console.log(err))
      setShowResults(true)
      }

    const getFirmList = () => {
     const options = {}
     const passedData = {'activeUser' : userProfile.profileEmail}
     api
        .postCreate('/get-firm-list', options, passedData)
        .then(res => setInitialFirm(res))
        .catch(err => console.log(err))
    }

    const setResponse = (res) => {
      setApiRes(res)
      if (res[0]['Status'] == 'Open'){
        setShowOpen(true)
      }
      else {
        setShowOpen(false)
      }
    }

    const dateFormat = (inputDate) => {
      var date = new Date(inputDate).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })
      return date
    }
    const setInitialFirm = (res) => {
      setInitialFirmList(res['firms'])
      setSelectedFirmId(res['firms'][0]['org_id'])
      setApiRes(res['firms'])
      setShowResults(true)
    }

      const updateNotary = (props) => {
        const options = {}
        api
            .postCreate('/update-notary-registration', options, props)
            .then(res => setResponse(res))
            .catch(err => console.log(err))
        }  
    const selectFirm = (firm) => {
        const firmInfo = document.getElementById(firm.target.id)
        setSelectedFirmId(orgList['firmDict'][firmInfo.value])
    }

    const selectStatus = (status) => {
        const searchPref = document.getElementById(status.target.id)
        setSearchStatus(searchPref.value)
    }

    function showEscrowList (e) {
      setOrgId(e.org_id)
      setOrgName(e.org_name)
      setEscrowList(true)
      setShowResults(false)
    }

    function removeEscrowList () {
      setEscrowList(false)
      setShowResults(true)
    }

    const onClickHandler = () => {
      getApi()
    }
    const onSubmitHandler = (action) => {
        const checklist = []
        const approval = {'action': action}
        apiRes.forEach(item =>{
            const checkValue = document.getElementById(item.email)
            if (checkValue.checked){
                checklist.push({'email' : item.email})
            }
        })
        if (checklist.length > 0) {
            updateNotary([approval,checklist])
        }
    }
 
    return(
     <div className="container flex-grow-1">
          <div className="mt-5">

            {showResults &&
            (<div>
            <h6 className="muted">Firm List:</h6>
            <div className="container-fluid rounded">
              <div className="table-responsive">
                <table className="table table-hover">
                <tbody>
                  <tr scope="row">
                  <th scope="col">Firm Name</th>
                  <th scope="col">Contact Name</th>
                  <th scope="col">Address</th>
                  <th scope="col">City</th>
                  <th scope="col">State</th>
                  <th scope="col">Zip</th>
                  <th scope="col">Service Fee</th>
                  </tr>
                  {apiRes.map((items =>
                      <tr scope="row" key={items.org_id}>
                          <td><button className='btn btn-link' type='button' onClick={() => showEscrowList(items)}>{items.org_name}</button></td>
                          <td>{items.org_contact_name}</td>
                          <td>{items.org_address}</td>
                          <td>{items.org_city}</td>
                          <td>{items.org_state}</td>
                          <td>{items.org_zip}</td>
                          <td>${items.org_fee}</td>
                      </tr>
                  ))}
                </tbody></table>
              </div>
            </div>
          </div>)}
          {escrowList &&
            <Fragment>
              <div className="container-fluid mb-3 border rounded bg-light">
              <CloseOutButton onClickHandler = {removeEscrowList}/>
              <AdminFirmEscrowList OrgId={orgId} OrgName={orgName}/>
              </div>
            </Fragment>
          }
          {viewRequest &&
           (<div className='container mb-3 border rounded bg-light'>
            <div className='row rounded bg-white'>
              <button className="close ml-3 mb-2 mt-2" aria-label="Close" onClick={() =>changeView()}>
                <span aria-hidden='false'>&times;</span>
              </button>
            </div>
            <Fragment>
              {showOpen
              ? <AdminEditRequest requestId={requestId}/>
              : <AdminViewRequest requestId={requestId}/>
              
            }
            </Fragment>
          </div>)}
        </div>
    </div>
    );
}
export default AdminManageFirms;