// src/components/notary-nav-bar.js

import React, { useState, useContext, useEffect, Fragment } from 'react';
import { NotaryOpen, NotaryPending, NotaryScheduled, NotaryCompleted, TabContent, TabNavItem, ProfNavBar } from '../components';
import NotaryPendingCard from './notary-card';
import { useAuth0 } from '@auth0/auth0-react';
import { UserContext } from "../app"


const NotaryNavBar = () => {

    const [activeTab, setActiveTab] = useState("tab3")
    const {userProfile, setUser } = useContext(UserContext)
    const [isActive, setIsActive] = useState(false)
    const { user } = useAuth0();
    const { email } = user;

    useEffect (() => {
      if (userProfile.status == 'active'){
        setIsActive(true)
      }
      if (userProfile.status == 'pending'){
        setIsActive(false)
      } 
      if (userProfile.statis == 'disabled'){
        setIsActive(false)
      } 
    }, [userProfile, setUser]);


    return (
    <div>
    {isActive 
    ? (  
    <div>
      <div className="nav-container mb-3">
        <div className="navbar navbar-expand-sm navbar-light bg-light">
          <nav className="nav nav-tabs nav-fill flex-column flex-sm-row">
            <TabNavItem title="Open Requests" id="tab1" activeTab={activeTab} setActiveTab={setActiveTab}/>
            <TabNavItem title="Pending Requests" id="tab2" activeTab={activeTab} setActiveTab={setActiveTab}/>
            <TabNavItem title="Assigned Requests" id="tab3" activeTab={activeTab} setActiveTab={setActiveTab}/>
            <TabNavItem title="Completed Requests" id="tab4" activeTab={activeTab} setActiveTab={setActiveTab}/>
          </nav>
        </div>
      </div>
      <div className="tab-content">
         <TabContent id="tab1" activeTab={activeTab}>
          <NotaryOpen profileEmail={email}/>
        </TabContent>
        <TabContent id="tab2" activeTab={activeTab}>
          <NotaryPending profileEmail={email}/>
        </TabContent>
        <TabContent id="tab3" activeTab={activeTab}>
          <NotaryScheduled profileEmail={email}/>
        </TabContent>
        <TabContent id="tab4" activeTab={activeTab}>
          <NotaryCompleted profileEmail={email}/>
        </TabContent>
      </div>
    </div>
    )
  : (  
    <div>
      <div className="nav-container mb-3">
        <div className="navbar navbar-expand-sm navbard-light bg-light">
          <nav className="nav nav-tabs nav-fill flex-column flex-sm-row">
            <TabNavItem title="Open Requests" id="tab1" activeTab={activeTab} setActiveTab={setActiveTab}/>
            <TabNavItem title="Pending Requests" id="tab2" activeTab={activeTab} setActiveTab={setActiveTab}/>
            <TabNavItem title="Assigned Requests" id="tab3" activeTab={activeTab} setActiveTab={setActiveTab}/>
            <TabNavItem title="Completed Requests" id="tab4" activeTab={activeTab} setActiveTab={setActiveTab}/>
          </nav>
        </div>
      </div>
      <div className="tab-content">
         <TabContent id="tab1" activeTab={activeTab}>
          <div><h6>Your registration is currently being reviewed and is not yet active.  Please check back soon!</h6></div>
        </TabContent>
        <TabContent id="tab2" activeTab={activeTab}>
         <div><h6>Your registration is currently being reviewed and is not yet active.  Please check back soon!</h6></div>
        </TabContent>
        <TabContent id="tab3" activeTab={activeTab}>
         <div><h6>Your registration is currently being reviewed and is not yet active.  Please check back soon!</h6></div>
        </TabContent>
        <TabContent id="tab4" activeTab={activeTab}>
         <div><h6>Your registration is currently being reviewed and is not yet active.  Please check back soon!</h6></div>
        </TabContent>
      </div>
    </div>
    )  
  }
    </div>);
};

export default NotaryNavBar;