import React, { Fragment, useState } from 'react';
import { RestHelper } from '../../helper/restHelper';

const AdminEditStatus = (props) => {

    const [showResponseMessage, setShowResponseMessage] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [showOptions, setShowOptions] = useState(true)

    const api = RestHelper()
    function handleClick(event) {
        props.onMessage(event)
    }

    function setResponse(res) {
        setResponseMessage(res['message'])
        setIsLoading(false)
        setShowResponseMessage(true)
        
    }

    const updateStatus = (e) => {
        setIsLoading(true)
        setShowOptions(false)
        setShowResponseMessage(false)
        const options = {}
        const reqData = { 'action': e, 'request_id': props.requestId }
        api
            .postCreate('/manage-appts-admin', options, reqData)
            .then(res => setResponse(res))
            .catch(err => console.log(err))
    }

    return (
        <Fragment>
            {showOptions &&
                <div className="container border bg-white rounded">
                    <div className="card-body">
                        <h5 className='card-title'>Please select the status to set:</h5>
                        <button className="mb-2 mt-2 mx-2 btn btn-md border-secondary btn-light rounded" aria-label="Close" onClick={() => updateStatus('setCanceled')}>Set to Canceled</button>
                        <button className="mb-2 mt-2 mx-2 btn btn-md border-secondary btn-light rounded" aria-label="Close" onClick={() => updateStatus('setOpen')}>Set to Open</button>
                        <button className="mb-2 mt-2 mx-2 btn btn-md border-secondary btn-light rounded" aria-label="Close" onClick={() => updateStatus('setOnHold')}>Set to On Hold</button>
                        <button className="mb-2 mt-2 mx-2 btn btn-md border-secondary btn-light rounded" aria-label="Close" onClick={() => updateStatus('setScheduled')}>Set to Scheduled</button>
                    </div>

                </div>}
            {isLoading &&
                (<div class="d-flex justify-content-center mb-4 mt-4">
                    <div className="spinner-border" role="status">
                        <   span className="visually-hidden"></span>
                    </div>
                </div>)}
            {showResponseMessage && <div>{responseMessage}</div>}
        </Fragment>

    );
};

export default AdminEditStatus;