// src/components/notary-view-open-request.js

import React, { useState, Suspense, useEffect, Fragment} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { RestHelper } from '../helper/restHelper'



const NotaryViewOpenRequest = (data, data2) => {  

  const [requestDetails, setRequestDetails] = useState([])
  const [offerDetails, setOfferDetails] = useState([])
  const [offerSent, setOfferSent] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [counter, setCounterBid] = useState('$0.00')
  const [bid, setInitialBid] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [showClaimForm, setShowClaimForm] = useState(false)
  const [showCounterForm, setShowCounterForm] = useState(false)
  const [showOptions, setShowOptions] = useState(false)
  const [showAppt, setShowAppt] = useState(false)
  const [counterWarning, setCounterWarning] = useState(false)
  const api = RestHelper()
  console.log('Incoming data for the view open request', data, data2)

  const onClickHandler = (action) => {
    if (action == 'claim') {
      setIsLoading(true)
      setShowClaimForm(false)
      setShowAppt(false)
      updateRequest({'Action': 'accept', 'Bid' : bid})
    }
    if (action == 'counter') {
      setShowClaimForm(false)
      setShowAppt(false)
      setShowCounterForm(true)
    }
    if (action == 'decline') {
      setIsLoading(true)
      setShowClaimForm(false)
      setShowAppt(false)
      updateRequest({'Action': 'decline', 'Bid' : bid})
    }
  }

  const onSubmitCounterHandler = () => {
    const submitValue = {'Action':'counter', 'Bid' : counter}
    console.log({counter}.counter)
    if ({counter}.counter=='$0.00'){
        console.log('made it here for counter')
        setCounterWarning(true)
        setOfferSent(true)
    }
    else{
        setCounterWarning(false)
        setInitialBid(counter)
        updateRequest(submitValue)
    }
  }
  const inputChangeHandler = (setFunction: React.Dispatch<React.SetStateAction<string>>, event: React.ChangeEvent<HTMLInputElement>) => {
    setFunction(event.target.value)
}
  const reqData = { data, data2 }
  const options = {}
  if(!isLoaded){
    api
        .postCreate('/get-request-details', options, reqData)
        .then(res => setDetails(res))
        .catch(err => console.log(err))
    setIsLoaded(true)
    }
  
    const onChangeEvent = (evt) => {
      const value = evt.target.value;
      setCounterBid(value)
    }

  
    const onSubmitHandler = () => {
      setIsLoading(true)
      setShowClaimForm(false)
      setShowAppt(false)
      setShowCounterForm(false)
      updateRequest({'Action': 'counter', 'Bid' : counter})
    }

    const scanBackCheck = (checkFlag) => {
      var scanBackValue = ''
      if (checkFlag === 'true') {
        scanBackValue = 'SCAN BACKS ARE REQUIRED'
      }
      else{
        scanBackValue = 'NONE'
      }
      return scanBackValue
  
    }

    function setTransactionType(setValue) {
      if (setValue === '0') {
        return 'Not Defined'
      }
      else if (setValue === '1') {
        return 'Purchase'
      }
      else if (setValue === '2') {
        return 'Refinance'
      }
      else if (setValue === '3') {
        return 'Sellers Deed Set'
      }
      else if (setValue === '4') {
        return 'Other'
      }
      else {
        return 'Not Defined'
      }
    }
  
    function setFinanceType(setValue) {
      if (setValue === '10') {
        return 'Not Defined'
      }
      else if (setValue === '11') {
        return 'FHA'
      }
      else if (setValue === '12') {
        return 'Conventional'
      }
      else if (setValue === '13') {
        return 'Reverse'
      }
      else if (setValue === '14') {
        return 'HELOC'
      }
      else if (setValue === '15') {
        return 'Commercial'
      }
      else if (setValue === '16') {
        return 'VA'
      }
      else {
        return 'Not Defined'
      }
    }

    const dateFormat = (inputDate) => {
      const dateArray = inputDate.split("-")
      const date = dateArray[1] + '/' + dateArray[2] + '/' + dateArray[0]
      return date
    }
 const setDetails = (res) => {
      setRequestDetails(res)
      setShowOptions(true)
      setInitialBid(res[0]['apptOffer'])
      setIsLoading(false)
    }
  const updateRequest= (action) => {
    const updateDetails = {reqData, action}
    api
        .postCreate('/update-notary-request-details', options, updateDetails)
        .then(res => setOfferDetails(res))
        .catch(err => console.log(err))
    setOfferSent(true)
    setIsLoading(false)
  }

  return(
    <div className="card mt-2 mb-2">
      {isLoading &&
       (<div class="d-flex justify-content-center mb-4 mt-4">
          <div className="spinner-border" role="status">
            <span className="visually-hidden"></span>
           </div>
         </div>)}
    {offerSent 
        ? offerDetails.map((items =>
            <div className="card-body bg-light"><h6 className="mb-2 mt-2 mx-2">{items.responseMessage}</h6></div>))
        : (        
        <div className="card-body bg-light">
        {requestDetails.map((items =>
                <div>
                    <div className="row mt-3">
                      <div className='col mx-3'><h6>Appointment Name:</h6></div>
                      <div className='col mx-3'>{items.apptName}</div>
                    </div>
                    <div className="row mt-3 mb-3">
                      <div className='col mx-3'><h6>Signing Agent Fee:</h6></div>
                      <div className='col mx-3'>${items.apptOffer}</div>
                    </div>
                    <div className="row mt-3">
                      <div className='col mx-3'><h6>Lender:</h6></div>
                      <div className='col mx-3'>{items.lender}</div>
                    </div>
                    <div className="row mt-3">
                      <div className='col mx-3'><h6>When:</h6></div>
                      <div className='col mx-3'>{dateFormat(items.apptDate)} at {items.apptTime} {items.appt_timeZone}</div>
                    </div>
                    <div className="row mt-3">
                      <div className='col mx-3'><h6>Where:</h6></div>
                      <div className='col mx-3'>{items.apptStreet} {items.apptCity}, {items.apptState} {items.apptZip}</div>
                    </div>
                    <div className="row mt-3">
                      <div className="col mx-3"><h6>Scan Backs:</h6></div>
                      <div className="col mx-3">{scanBackCheck(items.scanBack)}</div>
                    </div>
                    <div className="row mt-3">
                      <div className="col mx-3"><h6>Transaction Type:</h6></div>
                      <div className="col mx-3">{setTransactionType(items.transactionType)}</div>
                    </div>
                    <div className="row mt-3">
                      <div className="col mx-3"><h6>Finance Type:</h6></div>
                      <div className="col mx-3">{setFinanceType(items.financeType)}</div>
                    </div>
                    <div className="row mt-3">
                      <div className='col mx-3'><h6>Extra Notes:</h6></div>
                      <div className='col mx-3'>{items.apptMessages}</div>
                    </div>
                </div>
                ))}
              {showOptions && (
              <div className="card-body rounded">
                <div className="row mb-2">
                 <h6 className="card-subtitle mb-4 text-muted">Please select an option below to either claim the offer, propose a counter offer, or decline this offer:</h6>
                </div>
                <div className="row btn-group">
                 <button type='button' className="btn btn-dark rounded btn-default mx-3 mb-4" onClick={()=>onClickHandler('claim')}>Claim</button>
                 <button type='button' className="btn btn-white border-secondary rounded btn-default mx-3 mb-4" onClick={()=>onClickHandler('counter')}>Counter</button>
                 <button type='button' className="btn btn-secondary rounded btn-default mx-3 mb-4" onClick={()=>onClickHandler('decline')}>Decline</button>
                </div>
              </div>)}
            </div>)}
            {counterWarning && <div className="card-body bg-light"><h6 className="mb-2 mt-2 mx-2">Please enter a valid counter offer.</h6></div>}
            {showClaimForm &&
            <div className="container rounded border bg-white mt-4 mb-4">
             <div className="card mb-3 mt-3">
               <div className="card-body rounded border bg-light">
                <h5 className="card-title text-muted">{updateRequest}</h5>
               </div>
            </div>            
            </div>
          }
          {showCounterForm &&
            <div className="container rounded border bg-white mt-4 mb-4">
              <div className="card mb-3 mt-3">
                <div className="card-body rounded border bg-light">
                   <h5 className="card-title">Counter Offer</h5>
                   <form onSubmit={onSubmitHandler} className="needs-validation">
                   <div className="col mb-3 mt-2">
                    <label htmlFor="Bid" className='form-label'>Enter an offer in a whole dollar amount (Current Offer = {bid}):</label>
                    <input className='form-control' id="counterOffer" onChange={(e)=>onChangeEvent(e)} pattern="^-?[0-9]\d*\.?\d*$" type="tel" placeholder='0.00' required/>
                   </div>
                   <input className='btn btn-secondary rounded btn-md mb-3' type="submit" value="Submit"/>
                   </form>
                </div>
              </div>            
             </div>
          }
</div>
  )
};

export default NotaryViewOpenRequest;