// src/components/orig-nav.js

import React, { useState, Fragment, useEffect, useLayoutEffect } from 'react';
import { OrigNew, OrigNewCurrent, OrigPending, OrigCompleted, TabContent, TabNavItem, ProfNavBar } from '.';
import { useAuth0 } from '@auth0/auth0-react';

const OrigNavBar = () => {
  
  const [activeTab, setActiveTab] = useState("tab1")
  const { user } = useAuth0();
  const { email } = user;
  const [profileLoaded, setProfileLoaded] = useState(true)
  
  const setProfileStatus = (val) => {
    setProfileLoaded(true)
  }

    return (
    <div>
      <div className="nav-container mb-3">
        <div className="navbar navbar-expand-sm navbard-light bg-light">
          <nav className="nav nav-tabs nav-fill flex-column flex-sm-row">
             <TabNavItem title="Create New Request" id="tab1" activeTab={activeTab} setActiveTab={setActiveTab}/>
          </nav>
          <nav className="nav nav-tabs nav-fill flex-column flex-sm-row">
             <TabNavItem title="In Process" id="tab2" activeTab={activeTab} setActiveTab={setActiveTab}/>
          </nav>
          <nav className="nav nav-tabs nav-fill flex-column flex-sm-row">
             <TabNavItem title="Completed" id="tab3" activeTab={activeTab} setActiveTab={setActiveTab}/>
          </nav>
        </div>
      </div>
      <div className="tab-content">
        <TabContent id="tab1" activeTab={activeTab}>
          { profileLoaded ? (<OrigNewCurrent profileEmail={email}/>) : (<div>Loading profile...</div>) }
        </TabContent>
        <TabContent id="tab2" activeTab={activeTab}>
          { profileLoaded ? (<OrigPending profileEmail={email} data='pending'/>) : (<div>Loading profile...</div>) }
        </TabContent>
        <TabContent id="tab3" activeTab={activeTab}>
          { profileLoaded ? (<OrigPending profileEmail={email} data='complete'/>) : (<div>Loading profile...</div>) }
        </TabContent>
      </div>
    </div>
  );
};

export default OrigNavBar;