import React, { Fragment, useEffect, useState, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import { RestHelperNoAuth } from "../helper/restHelperNoAuth";
import mixpanel from "mixpanel-browser";



const ContactUsEscrow = () => {

    const [data, setData] = useState({
        user_email:"",
        user_first_name:"",
        user_last_name:"",
        user_phone:"",
        user_zip:"",
        user_title_company:"",
        comments:""
    })

    useEffect(() => {
        mixpanel.track('Escrow Create User', { 'action': 'pageview' })
    }, [,])

    const onChangeEvent = (evt) => {
        const value = evt.target.value;
        setData({
            ...data,
            [evt.target.id]: value
        })
    }
    const [apiMessage, setApiMessage] = useState('')
    const [apiDetails, setApiDetails] = useState('')
    const [apiStatus, setApiStatus] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [showForm, setShowForm] = useState(true)
    const [errorMessage, setErrorMessage] = useState(false)


    const api = RestHelperNoAuth()

    const clearErrorMessage = () => {
        setErrorMessage(false)
        setShowForm(true)
        setData({
            ...data,
            ['user_email']: '',
            ['user_first_name'] : '',
            ['user_last_name'] : '',
            ['user_phone'] : '',
            ['user_zip'] : '',
            ['user_title_company'] : '',
            ['comments'] : ''
        })
    }
    const setApiRes = (res) => {
        setErrorMessage(true)
        setIsLoading(false)
        mixpanel.track('Escrow Create User', { 'action': 'new user created' })
        setApiDetails(res['details'])
        setApiMessage(res['message'])
        setApiStatus(res['status'])
    }
    const callApi = () => {
        const options = {}
        setIsLoading(true)
        setShowForm(false)
        api
        .postCreate('/user-create-escrow-public', options, data)
        .then(res => setApiRes(res))
        .catch(err => console.log(err))
    }


    const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        callApi()
    }

    return (
        <Fragment>
            {errorMessage && (
                <div className="container border rounded">
                <div className='row rounded bg-white'>
                 <button className="close ml-3 mb-2 mt-2" aria-label="Close" onClick={() =>clearErrorMessage()}>
                  <span aria-hidden='false'>&times;</span>
                 </button>
                </div>
                <div className="card mb-3 mt-3">
                    <div className="card-body rounded-border bg-white">
                        <h5 className='card-title'>Registration Status</h5>
                        <h6 className='card-subtitle mb-2'>{ apiStatus }</h6>
                        <h6 className='card-subtitle mb-2'>{ apiMessage }</h6>
                        <h5 className='card-subtitle mb-2'>{ apiDetails }</h5>
                    </div>
              </div>
              </div>
            )}
            {showForm && (
            <div className="container rounded border bg-white mt-4 mb-4" id="myInput">
            <div>
            <form onSubmit={onSubmitHandler} className="needs-validation">
             <div className="card mb-3 mt-3">
             <div className="card-body rounded border bg-light">
              <h5 className="card-title">Escrow Registration</h5>
              <h6 className="card-subtitle mb-2 text-muted">Thanks for your interest in partnering with us!  Please fill out the following fields and we'll be in touch to get you registered!</h6>
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2">
               <div className="col mb-3 mt-2">
                <label htmlFor="user_first_name" className='form-label'>First Name</label>
                <input className='form-control' id="user_first_name" onChange={(e)=>onChangeEvent(e)} type="text" value={data.user_first_name}/>
               </div> 
               <div className="col mb-3 mt-2">
                <label htmlFor="user_last_name" className='form-label'>Last Name</label>
                <input className='form-control' id="user_last_name" onChange={(e)=>onChangeEvent(e)} type="text" value={data.user_last_name}/>
               </div>
              </div>
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2">
               <div className="col mb-3 mt-2">
                <label htmlFor="user_email" className='form-label'>Email</label>
                <input className='form-control' id="user_email" onChange={(e)=>onChangeEvent(e)} type="email" value={data.user_email}/>
               </div>
               <div className="col mb-3 mt-2">
                <label htmlFor="user_phone" className='form-label'>Office Phone Number</label>
                <input className='form-control' id="user_phone" onChange={(e)=>onChangeEvent(e)} type="tel" value={data.user_phone}/>
               </div>
              </div>
             <div className="row">
              <div className="col mb-3 mt-2">
               <label htmlFor="user_title_company" className='form-label'>Title Company</label>
               <input className='form-control' id="user_title_company" onChange={(e)=>onChangeEvent(e)} type="text" value={data.user_title_company} required/>
              </div>
             </div>
             <div className="row">
              <div className="col mb-3 mt-2">
               <label htmlFor="comments" className='form-label'>Additional details</label>
               <input className='form-control' id="comments" onChange={(e)=>onChangeEvent(e)} type="text" value={data.comments}/>
              </div>
             </div>
             </div>
             </div>

             <input className='btn btn-secondary rounded btn-md mb-3' type="submit" value="Submit"/>
            </form>
            </div>
            </div>
            )}
            {isLoading && 
                (<div class="d-flex justify-content-center mb-4 mt-4">
                    <div className="spinner-border" role="status">
                    <   span className="visually-hidden"></span>
                    </div>
                </div>)}
        </Fragment>
    )
   };

export default ContactUsEscrow;