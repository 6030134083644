// src/components/general-upload-status.js

import React from 'react';

const GeneralUploadStatus = (props) => {
var imagesrc = '/img/red-x.png'
if (props.action === 'write') {
    var hovertext = 'File upload pending'
}
  if (props.confirm === true) {
    var imagesrc = '/img/green-check.png'
    if (props.action === 'write') {
        hovertext = 'File upload successful'
    }
    else{
        hovertext = 'File download successful'
    }

  }

  return (
    <img src={imagesrc} title={hovertext} id="myId" width='20' height='20'/>
  );
};

export default GeneralUploadStatus;