import React, { Fragment, useEffect, useState, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import { RestHelperNoAuth } from "../helper/restHelperNoAuth";
import mixpanel from "mixpanel-browser";

import { Hero, HomeContent } from "../components";

const SignUp = () => {

    const [notary, setNotary] = useState({
        firstName:"",
        lastName:"",
        mobilePhone:"",
        companyName:"",
        address1:"",
        address2:"",
        city:"",
        notaryState:"",
        zip:"",
        laserPrinter:false,
        dualTrayPrinter:false,
        smsOptIn:false,
        certifiedState1:"",
        certifiedState2:"",
        certifiedState3:"",
        certifiedState4:"",
        certifiedState5:"",
        email:"",
        notaryYears:""
    })
    const [editFlag, setEditFlag] = useState(false)
    const [stateList, setStateList] = useState({
        certifiedState1: false,
        certifiedState2: false,
        certifiedState3: false,
        certifiedState4: false,
        certifiedState5: false,
        maxState: false
    })
    const [errorMessage, setErrorMessage] = useState(false)
    const [showForm, setShowForm] = useState(true)
    const [successMessage, setSuccessMessage] = useState(false)
    const [apiResponse, setApiResponse] = useState('')
    const [apiMessage, setApiMessage] = useState('')
    const [apiDetails, setApiDetails] = useState('')
    const [populateStateInfo, setPopulateStateInfo] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        mixpanel.track('New User Sign Up', {'action' : 'pageview'})
    }, [,])

    const api = RestHelperNoAuth()

    const onChangeEvent = (evt) => {
        const value = evt.target.value;
        setNotary({
            ...notary,
            [evt.target.id]: value
        })
    }
    const ref = useRef(null);
    useEffect(() => {
        const stateVar1 = document.getElementById('certifiedState1')
        const idArray = ['certifiedState1','certifiedState2','certifiedState3','certifiedState4','certifiedState5','notaryState']
        const stateArray = ['State', 'AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'HI', 
        'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 
        'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 
        'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY']
        idArray.forEach(value => {
            const stateVar = document.getElementById(value)
            stateArray.forEach(value2 => {
                const option = document.createElement('option')
                option.innerHTML = value2
                if (notary[value] == value2) {
                    option.setAttribute('selected','selected')
                }
                stateVar.appendChild(option)
            })
        })
    },[populateStateInfo,setPopulateStateInfo]);
    //const stateVar = document.getElementById('certifiedState1Select')

    const onToggle = (evt) => {
        const key = evt.target.id
        const value = notary[key]
        if (value) {
            setNotary({
                ...notary,
                [evt.target.id] : false
            })
        }
        else {
            setNotary({
                ...notary,
                [evt.target.id] : true
            })
        }
    }
    
    const clearErrorMessage = () => {
        setErrorMessage(false)
        setShowForm(true)
        setPopulateStateInfo(!populateStateInfo)
    }

    const addState = (evt) => {
        const value = evt.target.id
        setNotary({
            ...notary,
            [value] : evt.target.value
        })
    }

    const setApiRes = (res) => {
        setErrorMessage(true)
        setIsLoading(false)
        setApiResponse(res['status'])
        setApiDetails(res['details'])
        setApiMessage(res['message'])
    }
    const callApi = () => {
        const options = {}
        setIsLoading(true)
        setShowForm(false)
        api
        .postCreate('/create-notary-user', options, notary)
        .then(res => setApiRes(res))
        .catch(err => console.log(err))
    }


    const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        mixpanel.track('New User Sign Up', {'action' : 'signup'})
        callApi()
    }

    return (
        <Fragment>
            {errorMessage && (
                <div className="container border rounded">
                <div className='row rounded bg-white'>
                 <button className="close ml-3 mb-2 mt-2" aria-label="Close" onClick={() =>clearErrorMessage()}>
                  <span aria-hidden='false'>&times;</span>
                 </button>
                </div>
                <div className="card mb-3 mt-3">
                    <div className="card-body rounded-border bg-white">
                        <h5 className='card-title'>Registration Status</h5>
                        <h6 className='card-subtitle mb-2'>{ apiResponse }</h6>
                        <h6 className='card-subtitle mb-2'>{ apiMessage }</h6>
                        <h5 className='card-subtitle mb-2'>{ apiDetails }</h5>
                    </div>
              </div>
              </div>
            )}
            {showForm && (
            <div className="container rounded border bg-white mt-4 mb-4" id="myInput">
             <div className="row mx-3 mt-3">
                <div className="col">
                <p className="lead text-center">
                    Join Us!
                </p>
                </div>
                <div className="col">
                <p className="lead text-center">
                    Get notified of assignments in your area!  
                </p>
                </div>
                <div className="col">
                <p className="lead text-center">   
                    Register below!
                </p>
                </div>
            </div>
            <div>
            <form onSubmit={onSubmitHandler} className="needs-validation">
             <div className="card mb-3 mt-3">
             <div className="card-body rounded border bg-light">
              <h5 className="card-title">Contact Info</h5>
              <h6 className="card-subtitle mb-2 text-muted">Please complete basic contact info for registration</h6>
              <div className="row">
               <div className="col mb-3 mt-2">
                <label htmlFor="appt_name" className='form-label'>First Name</label>
                {editFlag
                ? <input className='form-control' id="firstName" onChange={(e)=>onChangeEvent(e)} type="text" value={notary.firstName} required readOnly/>
                : <input className='form-control' id="firstName" onChange={(e)=>onChangeEvent(e)} type="text" value={notary.firstName} required/>}
               </div> 
               <div className="col mb-3 mt-2">
                <label htmlFor="appt_name" className='form-label'>Last Name</label>
                <input className='form-control' id="lastName" onChange={(e)=>onChangeEvent(e)} type="text" value={notary.lastName} required/>
               </div>
              </div>
              <div className="row">
               <div className="col mb-3 mt-2">
                <label htmlFor="city" className='form-label'>City</label>
                <input className='form-control' id="city" onChange={(e)=>onChangeEvent(e)} type="text" value={notary.city} required/>
               </div>
              </div>
              <div className="row">
               <div className="col mb-3 mt-2">
               <label for="notaryState" className='form-label col'>State</label>
                  <select className='form-select form-select-sm mb-3 col' aria-label='form-select-sm' id="notaryState" onChange={(e)=>addState(e)}></select>
               </div>
               <div className="col mb-3 mt-2">
                <label htmlFor="zip" className='form-label'>Zip Code</label>
                <input className='form-control' id="zip" maxLength="5" onChange={(e)=>onChangeEvent(e)} type="tel" value={notary.zip} required/>
               </div>
              </div>
              <div className="mb-3 mt-2">
               <label htmlFor="appt_name" className='form-label'>Email Address</label>
               <input className='form-control' id="email" onChange={(e)=>onChangeEvent(e)} type="email" value={notary.email} required/>
              </div> 
              <div className="mb-3 mt-2">
               <label htmlFor="appt_name" className='form-label'>Mobile Phone Number</label>
               <input className='form-control' id="mobilePhone" onChange={(e)=>onChangeEvent(e)} type="tel" value={notary.mobilePhone} required/>
              </div>
              <div className="form-check form-check-reverse">
               <input className='form-check-input' id="smsOptIn" onChange={(e)=>onToggle(e)} type="checkbox" value={notary.smsOptIn}/>
               <label htmlFor="smsOptIn" className='form-check-label mx-2' for="smsOptIn">Opt in to receive notifications via SMS to this number</label>
              </div>
              </div>
              </div>
              <div className="card mb-3 mt-3">
               <div className="card-body rounded border bg-light">
                <h5 className="card-title">Signing Agent / Field Inspector Details</h5>
                <h6 className="card-subtitle mb-2 text-muted">Please provide additional information to help us match you with the best assignments.</h6>
                <div className="mb-3 mt-2">
                 <label htmlFor="notaryYears" className='form-label'>Years of experience</label>
                 <input className='form-control' id="notaryYears" onChange={(e)=>onChangeEvent(e)} type="tel" value={notary.notaryYears}/>
                </div>
                <div className="mb-3 mt-2">
                 <label htmlFor="companyName" className='form-label'>Company Name</label>
                 <input className='form-control' id="companyName" onChange={(e)=>onChangeEvent(e)} type="text" value={notary.companyName}/>
                </div>
                <h5 className="mb-3">Please identify up to five states in which you are certified:</h5>
                <div className="row g-3">
                <div className="col-md-5">
                  <label for="certifiedState1" className='form-label col'>First State</label>
                  <select className='form-select form-select-sm mb-3 col' aria-label='form-select-sm' id="certifiedState1" onChange={(e)=>addState(e)}></select>
                </div>
                <div className="col-md-5">
                  <label for="certifiedState2" className='form-label col'>Second State</label>
                  <select className='form-select form-select-sm mb-3 col' aria-label='form-select-sm' id="certifiedState2" onChange={(e)=>addState(e)}></select>
                </div>
                <div className="col-md-5">
                  <label for="certifiedState3" className='form-label col'>Third State</label>
                  <select className='form-select form-select-sm mb-3 col' aria-label='form-select-sm' id="certifiedState3" onChange={(e)=>addState(e)}></select>
                </div>
                <div className="col-md-5">
                  <label htmlFor="certifiedState4" className='form-label col'>Fourth State</label>
                  <select className='form-select form-select-sm mb-3 col' aria-label='form-select-sm' id="certifiedState4" onChange={(e)=>addState(e)}></select>
                </div>
                <div className="col-md-5">
                  <label htmlFor="certifiedState5" className='form-label col'>Fifth State</label>
                  <select className='form-select form-select-sm mb-3 col' aria-label='form-select-sm' id="certifiedState5" onChange={(e)=>addState(e)}></select>
                </div>
                </div>
                <div className="form-check form-check-reverse">
                 <input className='form-check-input' id="laserPrinter" onChange={(e)=>onToggle(e)} type="checkbox" value={notary.laserPrinter}/>
                 <label htmlFor="laserPrinter" className='form-check-label' for="laserPrinter">Laser Printer</label>
                </div>
                <div className="form-check form-check-reverse">
                 <input className='form-check-input' id="dualTrayPrinter" onChange={(e)=>onToggle(e)} type="checkbox" value={notary.dualTrayPrinter}/>
                 <label htmlFor="dualTrayPrinter" className='form-check-label' for="dualTrayPrinter">Dual Tray Printer</label>
                </div>
               </div>
 
             </div>
             <input className='btn btn-secondary rounded btn-md mb-3' type="submit" value="Register"/>
            </form>
            </div>
            </div>
            )}
            {isLoading && 
                (<div class="d-flex justify-content-center mb-4 mt-4">
                    <div className="spinner-border" role="status">
                    <   span className="visually-hidden"></span>
                    </div>
                </div>)}
        </Fragment>
    )
   };

export default SignUp;