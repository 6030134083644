// src/components/nav-bar.js

import React from 'react';

import MainNav from './main-nav';
import TempNav from './temp-nav';
import AuthNav from './auth-nav';
import { GuestNav, AdminNav, TitleNav, NotaryNav } from '../components'
import {NavLink} from "react-router-dom";
import { useState, useContext, useEffect } from 'react';
import { UserContext } from "../app"
import mixpanel from 'mixpanel-browser';


const NavBar = (props) => {
  const {userProfile, setUser } = useContext(UserContext)
  const[isNotary, setIsNotary] = useState(false)
  const[isAdmin, setIsAdmin] = useState(false)
  const[isTitle, setIsTitle] = useState(false)
  const[isGuest, setIsGuest] = useState(true)
  
 function sendEvent(e)  {
  mixpanel.identify(e.profileEmail)  
  mixpanel.people.set({
      $role: e.profileRole,
      $email: e.profileEmail,
      $name: e.profileName,
      $organization: e.profileOrganization
    })}
  
  useEffect (() => {
    if (userProfile.profileRole === 'admin' || userProfile.profileRole === 'creator' || userProfile.profileRole === 'sub_admin' || userProfile.profileRole === 'notary'){
      sendEvent(userProfile)
    }
    else{
      sendEvent(userProfile)
    }
    if (userProfile.profileRole === 'creator'){
      setIsTitle(true)
      setIsGuest(false)
    }
    if (userProfile.profileRole === 'admin' || userProfile.profileRole === 'sub_admin'){
      setIsAdmin(true)
      setIsGuest(false)
    } 
    if (userProfile.profileRole === 'notary'){
      setIsNotary(true)
      setIsGuest(false)
    } 
  }, [userProfile, setUser]);

  return (
    <div className="nav-container mb-3">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
      <NavLink
        to="/"
        exact
        className="navbar-brand"
        activeClassName="router-link-exact">
    </NavLink>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02">
      <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
          { isNotary &&<NotaryNav/> }
          { isGuest &&<GuestNav/> }
          { isAdmin &&<AdminNav/> }
          { isTitle &&<TitleNav/> }
          <AuthNav />
        </div>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
