// src/components/admin/admin-review-appt-req.js

import React, { useState, Fragment, useEffect, useContext } from 'react';
import { RestHelper } from '../../helper/restHelper'
import { UserContext } from "../../app"
import { AdminEditRequest, AdminViewRequest, AdminSendText, AdminSendEmail} from "../../components/admin"
import {NotaryProfile} from "../../components"




const AdminReviewNotaries = (profileEmail) => {
    const {userProfile, setUser } = useContext(UserContext)
    const [apiRes, setApiRes] = useState([])
    const [profile, setProfile] = useState('')
    const [showProfile, setShowProfile] = useState(false)
    const [showText, setShowText] = useState(false)
    const [showEmail, setShowEmail] = useState(false)
    const [profileInfo, setProfileInfo] = useState({})
    const [apiUpdate, setApiUpdate] = useState([])
    const [orgList, setOrgList] = useState ({})
    const [notaryState, setNotaryState] = useState('Search All')
    const [initialFirmList, setInitialFirmList] = useState([])
    const [searchRadius, setSearchRadius] = useState('0')
    const [searchZipCode, setSearchZipCode] =  useState(false)
    const [searchByName, setSearchByName] = useState(false)
    const [zipCode, setZipCode] = useState('')
    const [agentName, setAgentName] = useState('')
    const [selectedFirmId, setSelectedFirmId] = useState()
    const [showResults, setShowResults] = useState(false)
    const [viewRequest, setViewRequest] = useState(false)
    const [showOpen, setShowOpen] = useState(false)
    const [requestId, setRequestId] = useState('')
    const [notaryCount, setNotaryCount] = useState()
    const api = RestHelper()
   
  
    useEffect(() => {
      const idArray = ['notaryState']
      const stateArray = ['Search All', 'Search By Name','10 miles', '20 miles', '50 miles', '100 miles', '250 miles', 'AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'HI', 
      'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 
      'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 
      'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY']
      idArray.forEach(value => {
          const stateVar = document.getElementById(value)
          stateArray.forEach(value2 => {
              const option = document.createElement('option')
              option.innerHTML = value2
              stateVar.appendChild(option)
          })
      })
    },);
    
    const changeView = () => {
      setViewRequest(false)
      setShowResults(true)
      setShowProfile(false)
      setShowText(false)
      setShowEmail(false)
    }
    const getProfile = (data) => {
      setProfile(data)
      setShowProfile(true)
      setShowResults(false)
    }

    // if Check Pending Requests is selected, we'll query for pending requests
  
    const getApi = () => {
      const options = {}
      var action = ''
      var type = ''
      var filter = ''
      if (notaryState == 'Search By Name'){
        action = 'find'
        type = 'notary'
        filter = agentName
      }
      else{
        action = 'get'
        type = 'notary'
        filter = notaryState
      }
      const passedData = {'action' : action, 'type' : type, 'filter' : filter, 'zip' : zipCode}
      api
          .postCreate('/manage-users-admin', options, passedData)
          .then(res => setResponse(res))
          .catch(err => console.log(err))
      setShowResults(true)
      }

    const getFirmList = () => {
     const options = {}
     const passedData = {'activeUser' : userProfile.profileEmail}
     api
        .postCreate('/get-firm-list', options, passedData)
        .then(res => setInitialFirm(res))
        .catch(err => console.log(err))
    }

    const setResponse = (res) => {
      setApiRes(res['notaryList'])
      setAgentName('')
      setNotaryCount(res['notaryCount'])
      if (res[0]['Status'] == 'Open'){
        setShowOpen(true)
      }
      else {
        setShowOpen(false)
      }
    }

    const dateFormat = (inputDate) => {
      var date = new Date(inputDate).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })
      return date
    }
    const setInitialFirm = (res) => {
      setInitialFirmList(res['firms'])
      setSelectedFirmId(res['firms'][0]['org_id'])
    }

      const updateNotary = (props) => {
        const options = {}
        api
            .postCreate('/update-notary-registration', options, props)
            .then(res => setResponse(res))
            .catch(err => console.log(err))
        }  


    const selectStatus = (status) => {
        const searchPref = document.getElementById(status.target.id)
        console.log(searchPref.value)
        setSearchRadius(searchPref.value)
        if (searchPref.value == '0') {
          setSearchZipCode(false)
          setZipCode('')
        }
        else{
          setSearchZipCode(true)
        }
    }

    const onChangeEvent = (evt) => {
      const value = evt.target.value;
      setZipCode(value)
    }

    const onNameChangeEvent = (evt) => {
      const value = evt.target.value;
      setAgentName(value)
    }

    const addState = (evt) => {
      const value = evt.target.value
      if (['10 miles', '20 miles', '50 miles', '100 miles', '250 miles'].includes(value)){
        setSearchZipCode(true)
        setSearchByName(false)
        setApiRes([])
      }
      else if (['Search By Name'].includes(value)){
        setSearchZipCode(false)
        setZipCode('')
        setSearchByName(true)
        setApiRes([])
      }
      else{
        setSearchZipCode(false)
        setSearchByName(false)
        setZipCode('')
        setApiRes([])
      }
      setNotaryState(evt.target.value)
    }

    const sendTextMessage = (e) => {
      setProfileInfo(e)
      setShowText(true)
      setShowEmail(false)
    }
    const sendEmailMessage = (e) => {
      setProfileInfo(e)
      setShowEmail(true)
      setShowText(false)
    }

    function checkPreferredStatus(data)  {
      if (data){
        return('Preferred')}
      else{
        return('')}
    }

    const onClickHandler = () => {
      
    }
    const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setShowProfile(false)
      getApi()
  }
 
    return(
     <div className="container flex-grow-1">
          <div className="mt-5">
          <form onSubmit={(e)=>onSubmitHandler(e)} className="needs-validation">
            <div className="col col-sm-4 mb-3 mt-2">
             <label for="notaryState" className='form-label'>Search Criteria</label>
                  <select className='form-select form-select-sm mb-3 col' aria-label='form-select-sm' id="notaryState" onChange={(e)=>addState(e)}></select>
            </div>
            <div className="col col-sm-4 mb-3 mt-2">
                {searchZipCode
                ? (<div><label htmlFor="zipCode" className='form-label'>Zip Code</label>
                <input className='form-control' id="zipCode" onChange={(e)=>onChangeEvent(e)} type="text" value={zipCode} required /></div>)
                : <div></div>}
                {searchByName
                ? (<div><label htmlFor="zipCode" className='form-label'>Enter Last Name</label>
                <input className='form-control' id="agentName" onChange={(e)=>onNameChangeEvent(e)} type="text" value={agentName} required /></div>)
                : <div></div>}
            </div>
            <div className='row'>
              <button type='submit' className='btn rounded btn-md btn-secondary mx-3 mb-3' onClick={()=>onClickHandler()}>Search</button>
            </div>
            </form>
            {showResults &&
            (<div>
            <div className="container-fluid rounded">
              <div className="row">
                <h6 className="muted">Signing Agent Count: {notaryCount} </h6>
                <table className="table">
                <tbody>
                  <tr scope="row">
                  <th scope="col">Signing Agent</th>
                  <th scope="col">City</th>
                  <th scope="col">State</th>
                  <th scope="col">Zip</th>
                  <th scope="col">Preferred Agent</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Email</th>
                  </tr>
                  {apiRes.map((items =>
                      <tr scope="row" key={items.RequestId}>
                          <td><button className='btn btn-link' type='button' onClick={() => getProfile(items.email)}>{items.firstName} {items.lastName}</button></td>
                          <td>{items.city}</td>
                          <td>{items.notaryState}</td>
                          <td>{items.zip}</td>
                          <td>{checkPreferredStatus(items.preferred_notary)}</td>
                          <td><button className="btn-light btn mx-2" title="Send Text Message" id={items} style={{ backgroundImage: "url(/img/chat.svg)", backgroundSize: 'contain', backgroundRepeat: "no-repeat", width: '12px', height: '24px'}}  onClick={() => sendTextMessage(items)}></button>{items.mobilePhone}</td>
                          <td><button className="btn-light btn mx-2" title="Send Email" id={items} style={{ backgroundImage: "url(/img/envelope.svg)", backgroundSize: 'contain', backgroundRepeat: "no-repeat", width: '12px', height: '24px'}}  onClick={() => sendEmailMessage(items)}></button>{items.email}</td>
                      </tr>
                  ))}
                </tbody></table>
              </div>
            </div>
          </div>)}
          {showProfile &&
            <Fragment>
              <div className='container border rounded bg-light'>
                <div className='row rounded bg-white'>
                <button className="close ml-3 mb-2 mt-2" aria-label="Close" onClick={() =>changeView()}>
                  <span aria-hidden='false'>&times;</span>
                </button>
                </div>
                <div>
                  <NotaryProfile profile = {profile}/>
                </div>
              </div>
            </Fragment>
          }
          {showText &&
            <Fragment>
              <div className='container border rounded bg-light mb-2'>
                <div className='row rounded bg-white'>
                <button className="close ml-3 mb-2 mt-2" aria-label="Close" onClick={() =>changeView()}>
                  <span aria-hidden='false'>&times;</span>
                </button>
                </div>
                <div>
                  <AdminSendText profile = {profileInfo}/>
                </div>
              </div>
            </Fragment>
          }
          {showEmail &&
            <Fragment>
              <div className='container border rounded bg-light mb-2'>
                <div className='row rounded bg-white'>
                <button className="close ml-3 mb-2 mt-2" aria-label="Close" onClick={() =>changeView()}>
                  <span aria-hidden='false'>&times;</span>
                </button>
                </div>
                <div>
                  <AdminSendEmail profile = {profileInfo}/>
                </div>
              </div>
            </Fragment>
          }
          {viewRequest &&
           (<div className='container mb-3 border rounded bg-light'>
            <div className='row rounded bg-white'>
              <button className="close ml-3 mb-2 mt-2" aria-label="Close" onClick={() =>changeView()}>
                <span aria-hidden='false'>&times;</span>
              </button>
            </div>
            <Fragment>
              {showOpen
              ? <AdminEditRequest requestId={requestId}/>
              : <AdminViewRequest requestId={requestId}/>
              
            }
            </Fragment>
          </div>)}
        </div>
    </div>
    );
}
export default AdminReviewNotaries;