import React, { Fragment, useEffect, useState, useContext} from "react";

import { RestHelper } from "../../helper/restHelper";
import { UserContext } from "../../app"


const NotaryManageProfile = () => {

    const {userProfile, setUser } = useContext(UserContext)
    const [notary, setNotary] = useState({
        firstName:"",
        lastName:"",
        mobilePhone:"",
        companyName:"",
        address1:"",
        address2:"",
        city:"",
        notaryState:"",
        zip:"",
        laserPrinter:false,
        dualTrayPrinter:false,
        smsOptIn:false,
        certifiedState1:"",
        certifiedState2:"",
        certifiedState3:"",
        certifiedState4:"",
        certifiedState5:"",
        email:"",
        notaryYears:"",
        registrationStatus:"",
        updatedTime:""
    })
    const [profileLoaded, setProfileLoaded] = useState(false)
    const [editFlag, setEditFlag] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [showForm, setShowForm] = useState(true)
    const [successMessage, setSuccessMessage] = useState(false)
    const [apiResponse, setApiResponse] = useState('')
    const [apiMessage, setApiMessage] = useState('')
    const [apiDetails, setApiDetails] = useState('')
    const [populateStateInfo, setPopulateStateInfo] = useState(false)
    const [initialLoad, setInitialLoad] = useState(true)
    const [isLoading, setIsLoading] = useState(false)


    const api = RestHelper()

    const onChangeEvent = (evt) => {
        const value = evt.target.value;
        setNotary({
            ...notary,
            [evt.target.id]: value
        })
    }
    
    useEffect(() => {
        if (initialLoad){
            loadData()
        }
        setInitialLoad(false)
    },);
    
    useEffect(() => {
        const idArray = ['certifiedState1','certifiedState2','certifiedState3','certifiedState4','certifiedState5','notaryState']
        const stateArray = ['State', 'AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'HI', 
        'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 
        'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 
        'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY']
        idArray.forEach(value => {
            const stateVar = document.getElementById(value)
            stateArray.forEach(value2 => {
                const option = document.createElement('option')
                option.innerHTML = value2
                if (notary[value] == value2) {
                    option.setAttribute('selected','selected')
                }
                stateVar.appendChild(option)
            })
        })
        
    },[profileLoaded, setProfileLoaded]);
    //const stateVar = document.getElementById('certifiedState1Select')

    useEffect(() => {
        const checkboxArray = ['smsOptIn', 'laserPrinter', 'dualTrayPrinter']
        checkboxArray.forEach(value => {
            const checkBoxVar = document.getElementById(value)
            if (notary[value]){
                if (notary[value] != checkBoxVar.checked){
                    checkBoxVar.click()}
                }
        })
    },[notary, setNotary])

    const onToggle = (evt) => {
        const key = evt.target.id
        const value = notary[key]
        if (value) {
            setNotary({
                ...notary,
                [evt.target.id] : false
            })
        }
        else {
            setNotary({
                ...notary,
                [evt.target.id] : true
            })
        }
    }
    
    const clearErrorMessage = () => {
        setErrorMessage(false)
        setShowForm(true)
        setProfileLoaded(!profileLoaded)
    }

    const addState = (evt) => {
        const value = evt.target.id
        setNotary({
            ...notary,
            [value] : evt.target.value
        })
    }

    const setApiRes = (res) => {
        setErrorMessage(true)
        setShowForm(false)
        setIsLoading(false)
        setApiResponse(res)
        setApiDetails(res['details'])
        setApiMessage(res['message'])
    }
    
    const loadData = () => {
        const options = {}
        api
            .postCreate('/get-notary-profile-details', options, userProfile.profileEmail)
            .then(res => setInitialValues(res))
            .catch(err => console.log(err))
        }

    const setInitialValues = (res) => {
        setNotary({
            ...notary,
            ['firstName']:res['firstName'],
            ['lastName']:res['lastName'],
            ['mobilePhone']:res['mobilePhone'],
            ['companyName']:res['companyName'],
            ['address1']:res['address1'],
            ['address2']:res['address2'],
            ['city']:res['city'],
            ['notaryState']:res['notaryState'],
            ['zip']:res['zip'],
            ['laserPrinter']:res['laserPrinter'],
            ['dualTrayPrinter']:res['dualTrayPrinter'],
            ['smsOptIn']:res['smsOptIn'],
            ['certifiedState1']:res['certifiedState1'],
            ['certifiedState2']:res['certifiedState2'],
            ['certifiedState3']:res['certifiedState3'],
            ['certifiedState4']:res['certifiedState4'],
            ['certifiedState5']:res['certifiedState5'],
            ['email']:res['email'],
            ['notaryYears']:res['notaryYears'],
            ['registrationStatus']:res['registrationStatus'],
            ['updatedTime']:convertToDate(res['updatedTime'])
        })
        setProfileLoaded(true)
    }    
    const callApi = () => {
        const options = {}
        setIsLoading(true)
        setShowForm(false)
        api
        .postCreate('/update-notary-user', options, notary)
        .then(res => setApiRes(res))
        .catch(err => setApiRes(err))
    }

    const convertToDate = (updatedTime) => {
        const date = new Date(updatedTime*1000)
        return date
    }

    const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // If editFlag is true, the Save button was selected and the updated form values will be saved to the backend
        if ( editFlag ){
            callApi()
            setEditFlag(false)
        }
        // If editFlag was false, the Edit button was selected and the form is now set to be edited
        else {
            setEditFlag(true)
        }
    }

    return (
        <Fragment>
            {errorMessage && (
                <div className="container border rounded">
                <div className='row rounded bg-white'>
                 <button className="close ml-3 mb-2 mt-2" aria-label="Close" onClick={() =>clearErrorMessage()}>
                  <span aria-hidden='false'>&times;</span>
                 </button>
                </div>
                <div className="card mb-3 mt-3">
                    <div className="card-body rounded-border bg-white">
                        <h5 className='card-title'>Registration Status</h5>
                        <h6 className='card-subtitle mb-2'>{ apiMessage }</h6>
                        <h5 className='card-subtitle mb-2'>{ apiDetails }</h5>
                    </div>
              </div>
              </div>
            )}
            {showForm && (
            <div className="container rounded border bg-white mt-4 mb-4" id="myInput">
                <div className='mb-3 mt-3 mx-3'><h4>Profile Details</h4></div>
            <div>
            <form onSubmit={(e)=>onSubmitHandler(e)} className="needs-validation">
             <div className="card mb-3 mt-3">
             <div className="card-body rounded border bg-light">
              <h5 className="card-title">Contact Info</h5>
              <div className="row">
               <div className="col mb-3 mt-2">
                <label htmlFor="appt_name" className='form-label'>First Name</label>
                {editFlag
                ? <input className='form-control' id="firstName" onChange={(e)=>onChangeEvent(e)} type="text" value={notary.firstName} required />
                : <input className='form-control' id="firstName" onChange={(e)=>onChangeEvent(e)} type="text" value={notary.firstName} required readOnly/>}
               </div> 
               <div className="col mb-3 mt-2">
                <label htmlFor="appt_name" className='form-label'>Last Name</label>
                {editFlag
                ? <input className='form-control' id="lastName" onChange={(e)=>onChangeEvent(e)} type="text" value={notary.lastName} required/>
                : <input className='form-control' id="lastName" onChange={(e)=>onChangeEvent(e)} type="text" value={notary.lastName} required readOnly/>}
               </div>
              </div>
              <div className="row">
               <div className="col mb-3 mt-2">
                <label htmlFor="city" className='form-label'>City</label>
                {editFlag
                ? <input className='form-control' id="city" onChange={(e)=>onChangeEvent(e)} type="text" value={notary.city} required/>
                : <input className='form-control' id="city" onChange={(e)=>onChangeEvent(e)} type="text" value={notary.city} required readOnly/>}
               </div>
              </div>
              <div className="row">
               <div className="col mb-3 mt-2">
               <label for="state" className='form-label col'>State</label>
                  {editFlag
                  ? <select className='form-select form-select-sm mb-3 col' aria-label='form-select-sm' id="notaryState" onChange={(e)=>addState(e)}></select>
                  : <select className='form-select form-select-sm mb-3 col' aria-label='form-select-sm' id="notaryState" onChange={(e)=>addState(e)} disabled></select>}
               </div>
               <div className="col mb-3 mt-2">
                <label htmlFor="zip" className='form-label'>Zip Code</label>
                {editFlag
                ? <input className='form-control' id="zip" onChange={(e)=>onChangeEvent(e)} type="tel" value={notary.zip} required/>
                : <input className='form-control' id="zip" onChange={(e)=>onChangeEvent(e)} type="tel" value={notary.zip} required readOnly/>}
               </div>
              </div>
              <div className="mb-3 mt-2">
               <label htmlFor="appt_name" className='form-label'>Email Address</label>
               <input className='form-control' id="email" onChange={(e)=>onChangeEvent(e)} type="email" value={notary.email} required readOnly/>
              </div> 
              <div className="mb-3 mt-2">
               <label htmlFor="appt_name" className='form-label'>Mobile Phone Number</label>
               {editFlag
               ? <input className='form-control' id="mobilePhone" onChange={(e)=>onChangeEvent(e)} type="tel" value={notary.mobilePhone} required/>
               : <input className='form-control' id="mobilePhone" onChange={(e)=>onChangeEvent(e)} type="tel" value={notary.mobilePhone} required readOnly/>}
              </div>
              <div className="form-check form-check-reverse">
               {editFlag
                ? <input className='form-check-input' id="smsOptIn" onChange={(e)=>onToggle(e)} type="checkbox" checked={notary.smsOptIn}/>
                : <input className='form-check-input' id="smsOptIn" onChange={(e)=>onToggle(e)} type="checkbox" checked={notary.smsOptIn} disabled/>}
               <label htmlFor="smsOptIn" className='form-check-label mx-2' for="smsOptIn">Opt in to receive notifications via SMS to this number</label>
              </div>
              <div className="mb-3 mt-2">
               <label htmlFor="updatedTime" className='form-label'>Last Updated Time</label>
               <input className='form-control' id="updatedTime" type="text" value={notary.updatedTime} readOnly/>
              </div>
              <div className="mb-3 mt-2">
               <label htmlFor="registrationStatus" className='form-label'>Registration Status</label>
               <input className='form-control' id="registrationStatus" type="text" value={notary.registrationStatus} readOnly/>
              </div>
              </div>
              </div>
              <div className="card mb-3 mt-3">
               <div className="card-body rounded border bg-light">
                <h5 className="card-title">Notary Details</h5>
                <div className="mb-3 mt-2">
                 <label htmlFor="notaryYears" className='form-label'>Years of experience</label>
                 {editFlag
                 ? <input className='form-control' id="notaryYears" onChange={(e)=>onChangeEvent(e)} type="tel" value={notary.notaryYears}/>
                 : <input className='form-control' id="notaryYears" onChange={(e)=>onChangeEvent(e)} type="tel" value={notary.notaryYears} readOnly/>}
                </div>
                <div className="mb-3 mt-2">
                 <label htmlFor="companyName" className='form-label'>Company Name</label>
                 {editFlag
                 ? <input className='form-control' id="companyName" onChange={(e)=>onChangeEvent(e)} type="text" value={notary.companyName}/>
                 : <input className='form-control' id="companyName" onChange={(e)=>onChangeEvent(e)} type="text" value={notary.companyName} readOnly/> }
                </div>
                <h5 className="mb-3">Please identify up to five states in which you are a certified notary:</h5>
                <div className="row g-3">
                <div className="col-md-5">
                  <label for="certifiedState1" className='form-label col'>First State</label>
                  {editFlag
                  ? <select className='form-select form-select-sm mb-3 col' aria-label='form-select-sm' id="certifiedState1" onChange={(e)=>addState(e)}></select>
                  : <select className='form-select form-select-sm mb-3 col' aria-label='form-select-sm' id="certifiedState1" onChange={(e)=>addState(e)} disabled></select>}
                </div>
                <div className="col-md-5">
                  <label for="certifiedState2" className='form-label col'>Second State</label>
                  {editFlag
                  ? <select className='form-select form-select-sm mb-3 col' aria-label='form-select-sm' id="certifiedState2" onChange={(e)=>addState(e)}></select>
                  : <select className='form-select form-select-sm mb-3 col' aria-label='form-select-sm' id="certifiedState2" onChange={(e)=>addState(e)} disabled></select>}
                </div>
                <div className="col-md-5">
                  <label for="certifiedState3" className='form-label col'>Third State</label>
                  {editFlag
                  ? <select className='form-select form-select-sm mb-3 col' aria-label='form-select-sm' id="certifiedState3" onChange={(e)=>addState(e)}></select>
                  : <select className='form-select form-select-sm mb-3 col' aria-label='form-select-sm' id="certifiedState3" onChange={(e)=>addState(e)} disabled></select>}
                </div>
                <div className="col-md-5">
                  <label htmlFor="certifiedState4" className='form-label col'>Fourth State</label>
                  {editFlag
                  ? <select className='form-select form-select-sm mb-3 col' aria-label='form-select-sm' id="certifiedState4" onChange={(e)=>addState(e)}></select>
                  : <select className='form-select form-select-sm mb-3 col' aria-label='form-select-sm' id="certifiedState4" onChange={(e)=>addState(e)} disabled></select>}
                </div>
                <div className="col-md-5">
                  <label htmlFor="certifiedState5" className='form-label col'>Fifth State</label>
                  {editFlag 
                  ? <select className='form-select form-select-sm mb-3 col' aria-label='form-select-sm' id="certifiedState5" onChange={(e)=>addState(e)}></select>
                  : <select className='form-select form-select-sm mb-3 col' aria-label='form-select-sm' id="certifiedState5" onChange={(e)=>addState(e)} disabled></select>}
                </div>
                </div>
                <div className="form-check form-check-reverse">
                 {editFlag
                  ? <input className='form-check-input' id="laserPrinter" onChange={(e)=>onToggle(e)} type="checkbox" checked={notary.laserPrinter}/>
                  : <input className='form-check-input' id="laserPrinter" onChange={(e)=>onToggle(e)} type="checkbox" checked={notary.laserPrinter} disabled/> }
                 <label htmlFor="laserPrinter" className='form-check-label' for="laserPrinter">Laser Printer</label>
                </div>
                <div className="form-check form-check-reverse">
                 {editFlag
                  ? <input className='form-check-input' id="dualTrayPrinter" onChange={(e)=>onToggle(e)} type="checkbox" checked={notary.dualTrayPrinter}/>
                  : <input className='form-check-input' id="dualTrayPrinter" onChange={(e)=>onToggle(e)} type="checkbox" checked={notary.dualTrayPrinter} disabled/>}
                 <label htmlFor="dualTrayPrinter" className='form-check-label' for="dualTrayPrinter">Dual Tray Printer</label>
                </div>
               </div>
 
             </div>
             {editFlag
             ? (<div className='row'><input className='form-input btn btn-secondary btn-md mb-3 mx-3 rounded' type="submit" value="Save"/><button className='btn btn-light btn-outline-secondary mx-3 mb-3 rounded' onClick={() => setEditFlag(false)}>Cancel</button></div>)
             : <input className='btn btn-secondary btn-md mb-3 rounded' type="submit" value="Edit"/> 
             }
            </form>
            </div>
            </div>
            )}
            {isLoading && 
                (<div class="d-flex justify-content-center mb-4 mt-4">
                    <div className="spinner-border" role="status">
                    <   span className="visually-hidden"></span>
                    </div>
                </div>)}
        </Fragment>
    )
   };

export default NotaryManageProfile;