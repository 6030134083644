// src/components/view-notary-details.js

import React, { useState, Suspense, useEffect, Fragment} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { RestHelper } from '../helper/restHelper'



const ViewNotary = (data) => {  

  const [requestDetails, setRequestDetails] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const api = RestHelper()
  console.log(data)

  const reqData = { data }
    const options = {}
    if(!isLoaded){
    api
        .postCreate('/get-notary-details', options, reqData)
        .then(res => setRequestDetails(res))
        .catch(err => console.log(err))
    setIsLoaded(true)
    }

  return(
   <div>
    {requestDetails.map((items =>
            <div>
                <div className="row mt-3">
                  <div className='col mx-3'><h6>Notary Name:</h6></div>
                  <div className='col mx-3'>{items.notaryName}</div>
                </div>
                <div className="row mt-3">
                  <div className='col mx-3'><h6>Phone Number:</h6></div>
                  <div className='col mx-3'>{items.notaryPhone}</div>
                </div>
                <div className="row mt-3">
                  <div className='col mx-3'><h6>E-mail:</h6></div>
                  <div className='col mx-3'>{items.notaryEmail}</div>
                  </div>
            </div>
            ))}
</div>
  )
};

export default ViewNotary;