import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const PopupOverlayMultipleMessage = (props) => {
  const [showModal, setShowModal] = useState(true);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    props.clearModal()
  };

  const handleConfirm = () => {
    // Handle your confirmation logic here
    closeModal();
    props.confirmAction(props.passedObject)
    props.clearModal()
  };

  return (
    <div className="popup-container">
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Action</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.passedMessage1}<br/><br/>{props.passedMessage2}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PopupOverlayMultipleMessage;