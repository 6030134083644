// src/components/orig-nav.js

import React, { useState, Fragment, useEffect, useContext } from 'react';
import {NavLink} from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';
import { RestHelper } from '../helper/restHelper';
import { UserContext } from '../app';



const ProfNavBar = (profileEmail, props) => {
    const [message, setApiRes] = useState([])
    const { user } = useAuth0();
    const { email } = user;
    const { userProfile, setUser } = useContext(UserContext)

const api = RestHelper()
const options = {}

const profileInfo = {'profileEmail' : email}

useEffect (() => {
    getProfile();
  }, []);

const update = (data) => {
  setApiRes(data)
  profileEmail.func(data)
  setUser(data[0])
}

const getProfile = () => {
api
.postCreate('/get-profile-details', options, profileInfo)
.then(res => update(res))
.catch(err => console.log(err))
}

    return (
    <div>
      <div className="container">
        <div>
            {message.map((items =>
            <div>
                <div className="row mt-1 mb-1">
                  <div className='col text-right'>
                        <NavLink
                            to="/profile-details"
                            exact
                            className="nav-link"
                            activeClassName="router-link-exact-active"
    >                       {items.profileName} | {items.profileOrganization}
                        </NavLink>
                    </div>
                </div>
            </div>))
            }
        </div>
      </div>
    </div>
  );
};

export default ProfNavBar;