import React, { useState, Fragment, useEffect} from "react";


const OrigEditRequest = (data) => {

    const [request, setRequest] = useState({
        apptName: data.items.appt_name,
        apptDate: data.items.appt_date,
        apptTime: data.items.appt_time,
        apptTimeTBD: data.items.appt_time_TBD,
        apptTimeZone: data.items.appt_timeZone,
        apptStreet: data.items.appt_street,
        apptCity: data.items.appt_city,
        apptState: data.items.appt_state,
        apptZip: data.items.appt_zip,
        comments: data.items.comments,
        apptLender:data.items.appt_lender,
        contactName: data.items.appt_contactName,
        contactPhone1: data.items.appt_contactPhone1,
        contactPhone2: data.items.appt_contactPhone2,
        contactEmail1: data.items.appt_contactEmail1,
        contactEmail2: data.items.appt_contactEmail2,
        fileNumber:data.items.fileNumber,
        scanBack: data.items.scanBack,
        transactionType: data.items.transactionType,
        financeType: data.items.financeType
    })
    const [changedData, setChangedData] = useState({})
    const [requireTime, setRequireTime] = useState(true)

    const onChangeEvent = (evt) => {
        const value = evt.target.value;
        setRequest({
            ...request,
            [evt.target.id]: value
        })
        setChangedData({
            ...changedData,
            [evt.target.id]: value
        })
    }
    
    useEffect(() => {
        console.log(data.items)
        console.log(request['apptState'])
        setCheckBox(request['apptTimeTBD'])
        setScanBack(request['scanBack'])
        setTransactionType(request['transactionType'])
        setFinanceType(request['financeType'])
        document.getElementById(request['apptTimeZone']).selected = true
        const idArray = ['apptState']
        const stateArray = ['State', 'AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'HI', 
        'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 
        'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 
        'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY']
        idArray.forEach(value => {
            const stateVar = document.getElementById(value)
            stateArray.forEach(value2 => {
                const option = document.createElement('option')
                option.innerHTML = value2
                if (request[value] == value2) {
                    console.log('it matched the state')
                    option.setAttribute('selected','selected')
                }
                stateVar.appendChild(option)
            })
        })
    },[]);
    //const stateVar = document.getElementById('certifiedState1Select')

    const setCheckBox = (initialValue) => {
        const checkboxArray = ['apptTimeTBD']
        console.log(initialValue)
        checkboxArray.forEach(value => {
            const checkBoxVar = document.getElementById(value)
            if (initialValue){
                if (initialValue != checkBoxVar.checked){
                    checkBoxVar.click()}
                }
        })
    }

    const setScanBack = (initialValue) => {
        if (initialValue === 'true') {
            var radioButton = document.querySelector("input[value='true']")
            radioButton.checked = true;
        }
        else {
            var radioButton = document.querySelector("input[value='false']")
            radioButton.checked = true;
        }
    }

    const setTransactionType = (initialValue) => {
        if (initialValue === '1') {
            var radioButton = document.querySelector("input[value='1']")
            radioButton.checked = true;
        }
        else if (initialValue === '2') {
            var radioButton = document.querySelector("input[value='2']")
            radioButton.checked = true;
        }
        else if (initialValue === '3') {
            var radioButton = document.querySelector("input[value='3']")
            radioButton.checked = true;
        }
        else if (initialValue === '4') {
            var radioButton = document.querySelector("input[value='4']")
            radioButton.checked = true;
        }
    }

    const setFinanceType = (initialValue) => {
        if (initialValue === '11') {
            var radioButton = document.querySelector("input[value='11']")
            radioButton.checked = true;
        }
        else if (initialValue === '12') {
            var radioButton = document.querySelector("input[value='12']")
            radioButton.checked = true;
        }
        else if (initialValue === '13') {
            var radioButton = document.querySelector("input[value='13']")
            radioButton.checked = true;
        }
        else if (initialValue === '14') {
            var radioButton = document.querySelector("input[value='14']")
            radioButton.checked = true;
        }
        else if (initialValue === '15') {
            var radioButton = document.querySelector("input[value='15']")
            radioButton.checked = true;
        }
        else if (initialValue === '16') {
            var radioButton = document.querySelector("input[value='16']")
            radioButton.checked = true;
        }
    }

    const updateTimeField = () => {

        if(requireTime){
          setRequireTime(false)
          setRequest({
            ...request,
            ['apptTime']: '00:00',
            ['apptTimeTBD'] : true
        })
            setChangedData({
            ...changedData,
            ['apptTime']: 'TBD',
            ['apptTimeTBD'] : true
        })
        }
        else{
          setRequireTime(true)
          setRequest({
            ...request,
            ['apptTime']: '',
            ['apptTimeTBD'] : false
        })
          setChangedData({
            ...changedData,
            ['apptTime']: '',
            ['apptTimeTBD'] : false
        })
        }
      }

    const addState = (evt) => {
        const value = evt.target.id
        setRequest({
            ...request,
            [value] : evt.target.value
        })
    }

    const convertToDate = (updatedTime) => {
        const date = new Date(updatedTime*1000)
        return date
    }

    function cancelEdit() {
        data.cancelEdit()
    }

    const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // If editFlag is true, the preview button was selected and the updated form values are ready for review
        data.updateOrder({request, changedData})
    }

    return (
        <Fragment>
            <div className="container rounded border bg-white mt-4 mb-4" id="myInput">
                <div className='mb-3 mt-3 mx-3'><h4>Edit Existing Order</h4></div>
            <div>
            <form onSubmit={(e)=>onSubmitHandler(e)} className="needs-validation">
             <div className="card mb-3 mt-3">
             <div className="card-body rounded border bg-light">
              <h5 className="card-title">Order Details ( * required field)</h5>
              <div className="row">
               <div className="col mb-3 mt-2">
                <label htmlFor="apptName" className='form-label'>Appointment Name *</label>
                <input className='form-control' id="apptName" onChange={(e)=>onChangeEvent(e)} type="text" value={request.apptName} required />
               </div>
              </div>
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-lg-5">
               <div className="col mb-3 mt-2">
                <label htmlFor="apptDate" className='form-label'>Date *</label>
                <input className='form-control' id="apptDate" onChange={(e)=>onChangeEvent(e)} type="date" value={request.apptDate} required/>
               </div>
               <div className="form-check col col-auto mb-3 mt-2">
                    <label className="form-check-label">
                        Time TBD
                    </label>
                        <input className="form-check-input mx-2" type="checkbox" value="" id="apptTimeTBD" onChange={() => {updateTimeField()}}/>
                </div>
               <div className="col mb-3 mt-2">
                {requireTime
                ?(<div><label htmlFor="apptTime" className='form-label'>Time *</label>
                <input className='form-control' id="apptTime" onChange={(e)=>onChangeEvent(e)} type="time" value={request.apptTime} required/></div>)
                : <div><label htmlFor="apptTime" className='form-label'>Time *</label><input className='form-control' id='apptTimeTBD' value='TBD' readOnly/></div>}
               </div>
               <div className="col mb-3 mt-2">
                <label for="apptTimeZone" className='form-label'>Timezone</label>
                <select className='form-select form-select-sm mb-3 col' aria-label='form-select-sm' id="apptTimeZone" onChange={(e)=>onChangeEvent(e)}>
                  <option value='ET' id='ET'>Eastern</option>
                    <option value='CT' id = 'CT' selected>Central</option>
                    <option value='MT' id='MT'>Mountain</option>
                    <option value='PT' id='PT'>Pacific</option>
                    <option value='HT' id='HT'>Hawaii</option>
                    <option value='AK' id='AK'>Alaska</option>
                  </select>
                </div>
              </div>
              <div className="row">
               <div className="col mb-3 mt-2">
                <label htmlFor="apptStreet" className='form-label'>Location of Appointment *</label>
                <input className='form-control' id="apptStreet" onChange={(e)=>onChangeEvent(e)} type="text" value={request.apptStreet} required />
               </div>
              </div>
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4">
               <div className="col mb-3 mt-2">
                <label htmlFor="apptCity" className='form-label'>City *</label>
                <input className='form-control' id="apptCity" onChange={(e)=>onChangeEvent(e)} type="text" value={request.apptCity} required/>
               </div>
               <div className="col mb-3 mt-2">
               <label for="apptState" className='form-label col'>State *</label>
                  <select className='form-select form-select-sm mb-3 col' aria-label='form-select-sm' id="apptState" onChange={(e)=>addState(e)}></select>
               </div>
               <div className="col mb-3 mt-2">
                <label htmlFor="apptZip" className='form-label'>Zip Code *</label>
                <input className='form-control' id="apptZip" maxLength="5" onChange={(e)=>onChangeEvent(e)} type="tel" value={request.apptZip} required/>
               </div>
              </div>
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2">
               <div className="col mb-3 mt-2">
                <label htmlFor="fileNumber" className='form-label'>File Number</label>
                <input className='form-control' id="fileNumber" onChange={(e)=>onChangeEvent(e)} type="text" value={request.fileNumber} />
               </div>
               <div className="col mb-3 mt-2">
                <label htmlFor="apptLender" className='form-label'>Lender</label>
                <input className='form-control' id="apptLender" onChange={(e)=>onChangeEvent(e)} type="text" value={request.apptLender} />
               </div>
               </div>
               <hr/>
               <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 mx-2">
                <label className="form-label mx-2 col">SCAN BACK REQUIRED * :</label>
                <label className="form-label mx-2 col">
                <div><input className="mx-2" type="radio" name="scanBack" id="scanBack" onChange={(e)=>onChangeEvent(e)} value={true} required/>Required</div>
                </label>
                <label className="form-label mx-2 col">
                <div><input className="mx-2" type="radio" name="scanBack" id="scanBack" onChange={(e)=>onChangeEvent(e)} value={false} required/>Not Required</div>
                </label>
              </div>
              <hr/>
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 mx-2">
                <label className="form-label mx-2 col">TYPE OF TRANSACTION * :</label>
                <label className="form-label mx-2 col">
                <div><input className="mx-2" type="radio" name="transactionType" id="transactionType" onChange={(e)=>onChangeEvent(e)} value={'1'} required/>Purchase</div>
                </label>
                <label className="form-label mx-2 col">
                    <div><input className="mx-2" type="radio" name="transactionType" id="transactionType" onChange={(e)=>onChangeEvent(e)} value={'2'} required/>Refinance</div>
                </label>
                <label className="form-label mx-2 col">
                <div><input className="mx-2" type="radio" name="transactionType" id="transactionType" onChange={(e)=>onChangeEvent(e)} value={'3'} required/>Sellers Deed Set</div>
                </label>
                <label className="form-label mx-2 col">
                <div><input className="mx-2" type="radio" name="transactionType" id="transactionType" onChange={(e)=>onChangeEvent(e)} value={'4'} required/>Other</div>
                </label>
              </div>
              <hr/>
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 mx-2">
                <label className="form-label mx-2 col">TYPE OF FINANCING: (Optional)</label>
                <label className="form-label mx-2 col">
                    <div><input className="mx-2" type="radio" name="financeType" id="financeType" onChange={(e)=>onChangeEvent(e)} value={'11'} />FHA</div>
                </label>
                <label className="form-label mx-2 col">
                    <div><input className="mx-2" type="radio" name="financeType" id="financeType" onChange={(e)=>onChangeEvent(e)} value={'16'} />VA</div>
                </label>
                <label className="form-label mx-2 col">
                    <div><input className="mx-2" type="radio" name="financeType" id="financeType" onChange={(e)=>onChangeEvent(e)} value={'12'} />Conventional</div>
                </label>
                <label className="form-label mx-2 col">
                    <div><input className="mx-2" type="radio" name="financeType" id="financeType" onChange={(e)=>onChangeEvent(e)} value={'13'} />Reverse</div>
                </label>
                <label className="form-label mx-2 col">
                    <div><input className="mx-2" type="radio" name="financeType" id="financeType" onChange={(e)=>onChangeEvent(e)} value={'14'} />HELOC</div>
                </label>
                <label className="form-label mx-2 col">
                    <div><input className="mx-2" type="radio" name="financeType" id="financeType" onChange={(e)=>onChangeEvent(e)} value={'15'} />Commercial</div>
                </label>
              </div>
              <hr/>
              <div className="mb-3 mt-2">
               <label htmlFor="comments" className='form-label'>Additional Comments (max length = 400 characters)</label>
                <input className='form-control' id="comments" maxLength='400' onChange={(e)=>onChangeEvent(e)} type="text" value={request.comments} />
              </div>
              <div className="mb-3 mt-2">
              <div className="mb-3 mt-2">
               <label htmlFor="contactName" className='form-label'>Signer Contact Name</label>
                <input className='form-control' id="contactName" onChange={(e)=>onChangeEvent(e)} type="text" value={request.contactName} />
              </div>
              <div className="row">
               <div className="col mb-3 mt-2">
                <label htmlFor="contactPhone1" className='form-label'>Signer Contact Phone 1</label>
                    <input className='form-control' id="contactPhone1" onChange={(e)=>onChangeEvent(e)} type="tel" value={request.contactPhone1} />
               </div>
               <div className="col mb-3 mt-2">
                <label htmlFor="contactPhone2" className='form-label'>Signer Contact Phone 2</label>
                <input className='form-control' id="contactPhone2" onChange={(e)=>onChangeEvent(e)} type="tel" value={request.contactPhone2} />
               </div>
              </div>
              <div className="row">
               <div className="col mb-3 mt-2">
                <label htmlFor="contactEmail1" className='form-label'>Signer Email Address 1</label>
                <input className='form-control' id="contactEmail1" onChange={(e)=>onChangeEvent(e)} type="email" value={request.contactEmail1} />
               </div>
               <div className="col mb-3 mt-2">
                <label htmlFor="contactEmail2" className='form-label'>Signer Email Address 2</label>
                <input className='form-control' id="contactEmail2" onChange={(e)=>onChangeEvent(e)} type="email" value={request.contactEmail2} />
               </div>
              </div> 
              </div>
              </div>
                <div className='row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-5 mx-2'>
                    <div className="col col-md mb-3 mt-2"><input className='form-input btn btn-secondary btn-md mb-3 mt-3 rounded' type="submit" value="Update Order"/></div>
                    <div className="col col-md mb-3 mt-2"><button className='btn btn-md btn-light border rounded mt-3 mb-3' type="button" onClick={() => cancelEdit()}>Cancel Edit</button></div>
                </div>
            </div>
            </form>
            </div>
            </div>
        </Fragment>
        );
    }
export default OrigEditRequest;