// src/components/admin/admin-send-text-message.js

import React, { useState, Fragment, useEffect, useContext } from 'react';
import { RestHelper } from '../../helper/restHelper'
import { UserContext } from "../../app"




const AdminSendText = (data) => {

    const [textMessage, setTextMessage] = useState('')
    const [showResults, setShowResults] = useState(false)
    const [showMessageForm, setShowMessageForm] = useState(true)
    const [submitReply, setSubmitReply] = useState('')
    const api = RestHelper()

    const submitNotify = (email, phone) => {
        console.log(textMessage,email,phone, {data})
        var timeStamp = new Date().getTime()
        const responseObj = {'replyMsg' : textMessage, 'phone' : phone, 'id' : timeStamp}
        sendReply(responseObj)
        setTextMessage('')
      }
    const sendReply = (responseObj) => {
        const options = {}
        const passedData = {'action' : 'create', 'message' : responseObj}
        api
            .postCreate('/feedback-reply-text', options, passedData)
            .then(res => setSubmitReply(res))
            .catch(err => console.log(err))
        setShowResults(true)
        setShowMessageForm(false)
        }  
        const onChangeEvent = (evt) => {
            var value = evt.target.value;
            setTextMessage(value)     
          }

    return (
        <div>
            {showMessageForm && (
                <div className='container mb-2'>
                    <h6 className="mt-2 mx-3 mb-2">Create new text message to {data.profile.firstName} {data.profile.lastName}:</h6>
                    <div className = "row mx-3">
                        <textarea className='col' id="replyMsg" maxLength='320' onChange={(e)=>onChangeEvent(e)} type="text" value={textMessage} rows='3'></textarea>
                    </div>
                    <div className='row mx-3'>
                        <button className='btn btn-light btn-outline-secondary mx-3 mt-3 mb-3 rounded' onClick={() => submitNotify((data.profile.email),(data.profile.mobilePhone))}>Send Message</button>
                    </div>
                </div>)}
            {showResults && (
                <div className='container mb-2'>
                    {submitReply}
                </div>
            )}
        </div>
    );

}
export default AdminSendText;