import React, { Fragment, useEffect, useContext, useState } from "react";
import mixpanel from "mixpanel-browser";
import { AdminNavBar } from "../components/admin";
import { UserContext } from "../app"

const Admin = () => {
  const {userProfile, setUser} = useContext(UserContext)
  const [display, setDisplay] = useState(false)

  useEffect(() => {
    mixpanel.track('Admin Home Page', { 'action': 'pageview' })
  }, [,])

  useEffect (() => {
    if (userProfile.profileRole === 'admin' || userProfile.profileRole === 'sub_admin') {
      setDisplay(true)
    }
  }, []);
  return(
  <Fragment>
    {display 
    ? <AdminNavBar/>
    : <div class="d-flex justify-content-center mb-4 mt-4">
        <div className="spinner-border" role="status">
          <span className="visually-hidden"></span>
        </div>
      </div>
    }
  </Fragment>
  )
};

export default Admin;