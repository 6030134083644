import React, { Fragment, useEffect } from "react";
import mixpanel from "mixpanel-browser";

import { ContactUs, FaqHero } from "../components";

const Support = () => {
  
  useEffect(() => {
    mixpanel.track('Contact Us', { 'action': 'pageview' })
  }, [,])
  return(
  <Fragment>
    <FaqHero/>
    <ContactUs/>
  </Fragment>
);}

export default Support;