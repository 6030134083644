// src/components/admin/admin-review-appt-req.js

import React, { useState, Fragment, useEffect, useContext } from 'react';
import { RestHelper } from '../../helper/restHelper'
import { UserContext } from "../../app"




const AdminViewFeedback = (profileEmail) => {
    const [apiRes, setApiRes] = useState([])
    const [showResults, setShowResults] = useState(false)
    const [feedbackReply, setFeedbackReply] = useState('')
    const [viewRequest, setViewRequest] = useState(false)
    const [initialLoad, setInitialLoad] = useState(true)
    const [unique_id, setUnique_Id] = useState('')
    const [comments, setComments] = useState('')
    const [replyMsg, setReplyMsg] = useState('')
    const [returnAll, setReturnAll] = useState(false)
    const [feedback, setFeedback] = useState({
        'unique_id' : '',
        'subject' : '',
        'contact_name' : '',
        'contact_phone' : '',
        'comments' : ''
    })
    const api = RestHelper()

    
    useEffect(() => {
        if (initialLoad) {
            getApi()
            setInitialLoad(false)
        }
    },);

    const changeView = () => {
      setViewRequest(false)
      setShowResults(true)
    }
    const getRequest = (data) => {
      setUnique_Id(convertToDate(data['unique_id']))
      setComments(data['comments'])
      setFeedback({
        ...feedback,
        ['unique_id'] : convertToDate(data['unique_id']),
        ['orig_id'] : data['unique_id'],
        ['subject'] : data['subject'],
        ['contact_name'] : data['contact_name'],
        ['contact_email'] : data['contact_email'],
        ['contact_phone'] : data['contact_phone'],
        ['comments'] : data['comments']})
      setViewRequest(true);
      setShowResults(false)
    }

    const onSubmitHandler = () => {
      const checklist = []
      apiRes.forEach(item =>{
          const checkValue = document.getElementById(item.unique_id)
          if (checkValue.checked){
              checklist.push({'unique_id' : item.unique_id})
          }
      })
      if (checklist.length > 0) {
          markRead(checklist)
      }
  }

    const convertToDate = (updatedTime) => {
        const date = new Date(updatedTime*1000)
        return date
    }

    const convertToSimpleDate = (updatedTime) => {
      const date = new Date(updatedTime*1000)
      const dateString = date.toLocaleString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      })
      return dateString
    }

    const convertToSimpleDateMessage = (updatedTime) => {
      const date = new Date(updatedTime)
      const dateString = date.toLocaleString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      })
      return dateString
    }

    function handleCheckboxChange() {
      setReturnAll(!returnAll)
      getApi()
    }
    const onChangeEvent = (evt) => {
      var value = evt.target.value;
      setReplyMsg(value)     
    }
    const submitNotify = (email, phone, id) => {
      console.log(replyMsg,email,phone, id)
      const responseObj = {'replyMsg' : replyMsg, 'phone' : phone, 'id' : id}
      sendReply(responseObj)
      changeView()
      setReplyMsg('')
    }

    // if Check Pending Requests is selected, we'll query for pending requests
 
    const reload = (res) => {
      setFeedbackReply(res)
      getApi()
    }

    const markRead = (responseObj) => {
      const options = {}
      const passedData = {'action' : 'read', 'message' : responseObj}
      api
          .postCreate('/feedback-reply-text', options, passedData)
          .then(res => reload(res))
          .catch(err => console.log(err))
      setShowResults(true)
      } 
    const sendReply = (responseObj) => {
      const options = {}
      const passedData = {'action' : 'reply', 'message' : responseObj}
      api
          .postCreate('/feedback-reply-text', options, passedData)
          .then(res => setFeedbackReply(res))
          .catch(err => console.log(err))
      setShowResults(true)
      }    
    const getApi = () => {
      const options = {}
      const passedData = {'returnAll' : returnAll}
      api
          .postCreate('/get-admin-feedback', options, passedData)
          .then(res => setApiRes(res))
          .catch(err => console.log(err))
      setShowResults(true)
      }

 
    return(
     <div className="container flex-grow-1">
          <div className="mt-5">
            {showResults &&
            (<div>
            <h6 className="muted">Open comments:</h6>
            <div className="row">
              <button className="btn btn-secondary mx-3 mb-3 border rounded" onClick={()=>onSubmitHandler('approve')}>Mark Selected as Read</button>
              <label className = 'mx-2'>
              <input
              type="checkbox"
              checked={returnAll}
              onChange={handleCheckboxChange}
              className = "mx-2"
              />
              Toggle Read Messages
              </label>
            </div>
            <div className="container-fluid rounded">
              <div className="row">
                <table className="table">
                <tbody>
                  <tr scope="row">
                  <th scope="col"></th>
                  <th scope="col">Subject</th>
                  <th scope="col">Name</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Date</th>
                  </tr>
                  {apiRes.map((items =>
                      <tr scope="row" key={items.RequestId}>
                          <td className="checkbox-inline text-center"><input className='form-check-input' id={items.unique_id} type="checkbox" /></td>
                          <td><button className='btn btn-link' type='button' onClick={() => getRequest(items)}>{items.subject}</button></td>
                          <td>{items.contact_name}</td>
                          <td>{items.contact_phone}</td>
                          <td>{convertToSimpleDate(items.unique_id)}</td>
                      </tr>
                  ))}
                </tbody></table>
              </div>
            </div>
          </div>)}
          {viewRequest &&
           (<div className='container mb-3 border rounded bg-light'>
            <div className='row rounded bg-white'>
              <button className="close ml-3 mb-2 mt-2" aria-label="Close" onClick={() =>changeView()}>
                <span aria-hidden='false'>&times;</span>
              </button>
            </div>
            <div className="mb-3 mt-2">
               <h6 className='mb-3 mt-3 mx-3'>{convertToSimpleDateMessage(feedback.unique_id)}</h6>
              <div className="row">
                <div className="col mb-3 mx-3">
                <h6>From: </h6><h7>{feedback.contact_name}</h7>
                </div>
                <div className="col mb-3 mx-3">
                <h6>Email: </h6><h7>{feedback.contact_email}</h7>
                </div>
                <div className="col mb-3 mx-3">
                <h6>Phone: </h6><h7>{feedback.contact_phone}</h7>
                </div>
              </div>
              <div className="row">
                <div className="col mb-3 mx-3">
                 <h6>Subject: </h6><h7>{feedback.subject}</h7>
                </div>
              </div>
                <h6 className="mx-3 mt-2 mb-2">Message Text:</h6>
                <div className="row mb-3 mx-3">
                 <h7>{feedback.comments}</h7>
                </div>
            </div>
            <hr/>
            <div>
              <h6 className="mt-2 mx-3 mb-2">Send a response text message:</h6>
              <div className = "row mx-3">
                <input className='col' id="replyMsg" maxLength='320' onChange={(e)=>onChangeEvent(e)} type="text" value={replyMsg} />
              </div>
              <div className='row mx-3'>
                <button className='btn btn-light btn-outline-secondary mx-3 mt-3 mb-3 rounded' onClick={() => submitNotify((feedback.contact_email),(feedback.contact_phone),(feedback.orig_id))}>Send Reply</button>
              </div>
            </div>
            </div>)}
        </div>
    </div>
    );
}
export default AdminViewFeedback;