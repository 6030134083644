// src/components/general-file-upload.js

import React, { useState, Suspense, useEffect, Fragment} from 'react';

import { RestHelper } from '../helper/restHelper';


const GeneralFileUpload = (data) => {

    const [showFileName, setShowFileName] = useState(true)
    const [showFiles, setShowFiles] = useState(false)
    const [uploadInfo, setUploadInfo] = useState({})
    const [isMounted, setIsMounted] = useState(false)
    const [uploadResponse, setUploadResponse] = useState()
    const [file, setFile] = useState()
    const [fileLoaded, setFileLoaded] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const api = RestHelper()


    useEffect(() => {
        if (isMounted) {
        const formData = new FormData()
        const key = uploadInfo.fields['key']
        const keyId = uploadInfo.fields['AWSAccessKeyId']
        const policy = uploadInfo.fields['policy']
        const token = uploadInfo.fields['x-amz-security-token']
        const signature = uploadInfo.fields['signature']
        formData.append('key', key)
        formData.append('AWSAccessKeyId', keyId)
        formData.append('policy', policy)
        formData.append('x-amz-security-token', token)
        formData.append('signature', signature)
        formData.append('file', file)
        api
            .fileCreate(uploadInfo.url, formData)
            .then(res => setUploadResponse(res))
            .catch(err => console.log(err))
        setShowFileName(true)
        setShowFiles(false)
        //data.clearResponseMessage()
        //setIsLoading(false)
        sleep(7000).then(() => {data.clearResponseMessage()});
        sleep(7200).then(() => {setIsLoading(false)})
        } else {
            setIsMounted(true)
        }
        },[uploadInfo, setUploadInfo])

    useEffect(() =>{
        if (fileLoaded) {
            uploadHandler()
        }
    },[file,setFile])

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
    const fileChangedHandler = event => {
            setFileLoaded(true)
            setFile(event.target.files[0])
            setShowFileName(false)
          }
    const uploadHandler = () => {
        const options = {}
        setIsLoading(true)
        setFileLoaded(false)
        const fileData = {'unique_id' : data.requestId, 'file_name' : file.name }
        api
            .postCreate('/upload-file-admin', options, fileData)
            .then(res => setUploadInfo(res))
            .catch(err => console.log(err))
      }
    const getTempUrlCurrentFile = () => {
        const options = {}
        const fileData = {'unique_id' : data.requestId, 'file_name' : file.name }
        api
            .postCreate('/get-stored-file', options, fileData)
            .then(res => window.open(res.get_url,"_blank"))
            .catch(err => console.log(err))
      }
  return (
    <div>
        <div className="row mt-3">
            <div className='col mx-3'><h6>Upload New Files:</h6></div>
        </div>
        <div className="border border-secondary rounded bg-white mb-3 mt-1">
            {isLoading
                ? (<div>
                    <div className="d-flex justify-content-center mb-4 mt-4">Please do not close this page while the file is being uploaded! </div>
                    <div class="d-flex justify-content-center mb-4 mt-4">
                    <div className="spinner-border" role="status">
                    <   span className="visually-hidden"></span>
                    </div>
                    </div>
                </div>)
                : (<div>{showFileName
                        ?   <div className="input-group mx-2 mt-3 mb-3 col-md-5">
                                <label className="form-control bg-light text-primary" style={{cursor: 'pointer'}} tabIndex='0'>Click to select file for upload
                                <input className="invisible" type="file" onChange={fileChangedHandler} /></label>
                            </div>
                        : <div>
                            <div className="mx-3 mt-1 mb-2">{file.name}</div>
                            <div className="mx-3 mb-1"><button className='input-group-text border-secondary' onClick={uploadHandler}>Click to Upload File</button></div>
                         </div>}</div>)}
            {showFiles && <div className="mx-3">Attached Files:
                <div className="text-left text-decoration-underline text-primary mx-3">{file.name}<button className="list-group-item bg-light text-left mx-3 mt-1 mb-2 rounded" type="button" onClick={() => {getTempUrlCurrentFile()}}>Download file</button></div></div>}
        </div>
    </div>
  );
};

export default GeneralFileUpload;