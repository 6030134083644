import React, { Fragment, useEffect, useState, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import { RestHelperNoAuth } from "../helper/restHelperNoAuth";



const ContactUs = () => {

    const [data, setData] = useState({
        email:"",
        name:"",
        phone:"",
        subject:"",
        comments:""
    })

    const onChangeEvent = (evt) => {
        const value = evt.target.value;
        setData({
            ...data,
            [evt.target.id]: value
        })
    }
    const [apiMessage, setApiMessage] = useState('')
    const [apiDetails, setApiDetails] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [showForm, setShowForm] = useState(true)
    const [errorMessage, setErrorMessage] = useState(false)


    const api = RestHelperNoAuth()

    const clearErrorMessage = () => {
        setErrorMessage(false)
        setShowForm(true)
        setData({
            ...data,
            ['email']: '',
            ['name'] : '',
            ['phone'] : '',
            ['subject'] : '',
            ['comments'] : ''
        })
    }
    const setApiRes = (res) => {
        setErrorMessage(true)
        setIsLoading(false)
        setApiDetails(res['details'])
        setApiMessage(res)
    }
    const callApi = () => {
        const options = {}
        setIsLoading(true)
        setShowForm(false)
        api
        .postCreate('/create-contact-request', options, data)
        .then(res => setApiRes(res))
        .catch(err => console.log(err))
    }


    const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        callApi()
    }

    return (
        <Fragment>
            {errorMessage && (
                <div className="container border rounded">
                <div className='row rounded bg-white'>
                 <button className="close ml-3 mb-2 mt-2" aria-label="Close" onClick={() =>clearErrorMessage()}>
                  <span aria-hidden='false'>&times;</span>
                 </button>
                </div>
                <div className="card mb-3 mt-3">
                    <div className="card-body rounded-border bg-white">
                        <h5 className='card-title'>Registration Status</h5>
                        <h6 className='card-subtitle mb-2'>{ apiMessage }</h6>
                        <h5 className='card-subtitle mb-2'>{ apiDetails }</h5>
                    </div>
              </div>
              </div>
            )}
            {showForm && (
            <div className="container rounded border bg-white mt-4 mb-4" id="myInput">
            <div>
            <form onSubmit={onSubmitHandler} className="needs-validation">
             <div className="card mb-3 mt-3">
             <div className="card-body rounded border bg-light">
              <h5 className="card-title">Contact Us</h5>
              <h6 className="card-subtitle mb-2 text-muted">Thanks for your interest in contacting us!  <br/><br/>Please fill out the below fields and let us know how we can help you.  Or feel free to give us a call at (888) 530-7592.  <br/><br/> All feedback is appreciated and we'll be in touch soon!</h6>
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3">
               <div className="col mb-3 mt-2">
                <label htmlFor="name" className='form-label'>Name</label>
                <input className='form-control' id="name" onChange={(e)=>onChangeEvent(e)} type="text" value={data.name}/>
               </div> 
               <div className="col mb-3 mt-2">
                <label htmlFor="email" className='form-label'>Email</label>
                <input className='form-control' id="email" onChange={(e)=>onChangeEvent(e)} type="email" value={data.email}/>
               </div>
               <div className="col mb-3 mt-2">
                <label htmlFor="phone" className='form-label'>Phone Number</label>
                <input className='form-control' id="phone" onChange={(e)=>onChangeEvent(e)} type="tel" value={data.phone}/>
               </div>
              </div>
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2">
               <div className="col mb-3 mt-2">
                <label htmlFor="subject" className='form-label'>Subject</label>
                <input className='form-control' id="subject" onChange={(e)=>onChangeEvent(e)} type="text" value={data.subject} required/>
               </div>
              </div>
              <div className="row">
               <div className="col mb-3 mt-2">
                <label htmlFor="comments" className='form-label'>Comments / Feedback / Report an Issue</label>
                <input className='form-control' id="comments" onChange={(e)=>onChangeEvent(e)} type="text" value={data.comments} required/>
               </div>
              </div>
              </div>
              </div>

             <input className='btn btn-secondary rounded btn-md mb-3' type="submit" value="Submit"/>
            </form>
            </div>
            </div>
            )}
            {isLoading && 
                (<div class="d-flex justify-content-center mb-4 mt-4">
                    <div className="spinner-border" role="status">
                    <   span className="visually-hidden"></span>
                    </div>
                </div>)}
        </Fragment>
    )
   };

export default ContactUs;