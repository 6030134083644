// src/app.js

import React, { useLayoutEffect, useState, Fragment, createContext } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { NavBar, Footer, Loading, NavBarNotary, ProfNavBar } from './components';
import { Home, Profile, ExternalApi, AboutUs, Admin, Originator, Notary, Preview, ProfileDetails, SignUp, Support, Escrow, Autos} from './views';
import ProtectedRoute from './auth/protected-route';

import './app.css';
export const UserContext = createContext()

const App = () => {
  const { isLoading } = useAuth0();
  const [isAdmin, setIsAdmin] = useState(false)
  const [isNotary, setIsNotary] = useState(true)
  const [isTitle, setIsTitle] = useState(false)
  const { isAuthenticated } = useAuth0();
  const [profileLoaded, setProfileLoaded] = useState(false)
  const [profileDetails, setProfileDetails] = useState([])
  const [userProfile, setUser] = useState([])
  
  
  const myProps = 'hey there'

  const setProfileStatus = (val) => {
    console.log('updating Context now')
    setProfileDetails(val)
    //setUser(val[0])
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
    <UserContext.Provider value={{ userProfile, setUser }}>
    {isAuthenticated && (
      <div>
        <Fragment>
          <ProfNavBar profileEmail={'something@j.com'} func={setProfileStatus}/>
        </Fragment>
      </div>
    )}
    <div id="app" className="d-flex flex-column h-100">
      <NavBar/>
      <div className="container flex-grow-1">
        <Switch>
          <Route path="/" exact component={Home} />
          <ProtectedRoute path="/profile" component={Profile} />
          <ProtectedRoute path="/originator" component={Originator} />
          <ProtectedRoute path="/notary" component={Notary} />
          <ProtectedRoute path="/admin" component={Admin} />
          <ProtectedRoute path="/external-api" component={ExternalApi} />
          <Route path="/about-us" exact component={AboutUs} />
          <Route path="/escrow" exact component={Escrow} />
          <Route path="/sign-up" exact component={SignUp} />
          <Route path="/preview/:id" exact component={Preview} />
          <Route path="/support" exact component={Support} />
          <Route path="/autos" exact component={Autos} />
          <ProtectedRoute path="/profile-details" component={ProfileDetails} />
        </Switch>
      </div>
      <Footer />
    </div>
    </UserContext.Provider>
    </div>
  );
};

export default App;
