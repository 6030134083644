import React, { Fragment } from 'react';

const AdminEditSelectChoice = (props) => {
  
    function handleClick(event) {
        props.onMessage(event)
    }

    return (
        <Fragment>
            <div className="container border bg-white rounded">
                    <div className="card-body">
                        <h5 className='card-title'>Please select one of the following to edit:</h5>
                        <button className="mb-2 mt-2 mx-2 btn btn-md border-secondary btn-light rounded" aria-label="Close" onClick={() =>handleClick('Details')}>Edit Date/Time or other details</button>
                        <button className="mb-2 mt-2 mx-2 btn btn-md border-secondary btn-light rounded" aria-label="Close" onClick={() =>handleClick('Status')}>Change Status from { props.searchStatus }</button>
                        <button className="mb-2 mt-2 mx-2 btn btn-md border-secondary btn-light rounded" aria-label="Close" onClick={() =>handleClick('Notary')}>Change Assigned Agent</button>
                        <button className="mb-2 mt-2 mx-2 btn btn-md border-secondary btn-light rounded" aria-label="Close" onClick={() =>handleClick('DirectAssign')}>Directly Assign Agent</button>
                    </div>
                
            </div>
        </Fragment>

  );
};

export default AdminEditSelectChoice;