// src/components/originator-pending.js

import React, { useState, Suspense, useEffect, Fragment} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { RestHelper } from '../helper/restHelper'
import { ApptNotesHandler, GeneralFileUpload, OrigEditRequest, PopupOverlay, GeneralUploadStatus } from '../components'



const OrigViewRequest = (data) => {  

  const [requestDetails, setRequestDetails] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [showDetails, setShowDetails] = useState(true)
  const [showResponse, setShowResponse] = useState(false)
  const [updateResponse, setUpdateResponse] = useState([])
  const [showEditForm, setShowEditForm] = useState(false)
  const [passedData, setPassedData] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [editResponse, setEditResponse] = useState([])
  const [showResult, setShowResult] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [passedMessage, setPassedMessage] = useState('')
  const [passedObject, setPassedObject] = useState('')

  const [showFileUpload, setShowFileUpload] = useState(true)
  const api = RestHelper()

  const reqData = { 'action' : 'get', 'id' : data.requestId }
  const options = {}
  if(!isLoaded){
    api
        .postCreate('/appt-manage-escrow', options, reqData)
        .then(res => setRequestDetails(res))
        .catch(err => console.log(err))
    setIsLoaded(true)
    }


 function dateFormat (inputDate) {
       const dateArray = inputDate.split("-")
       const date = dateArray[1] + '/' + dateArray[2] + '/' + dateArray[0]
       return date
    }

 function editRequest(inputData) {
  setPassedData(inputData)
  setShowFileUpload(false)
  setShowDetails(false)
  setShowEditForm(true)
 }

 const getInvoice = (e) => {
  const options = {}
  const fileData = { 'unique_id': data.requestId, 'file_name': e }
  api
    .postCreate('/get-invoice-file', options, fileData)
    .then(res => window.open(res.get_url, "_blank"))
    .catch(err => console.log(err))
}

function clearEditRequest() {
  setShowFileUpload(true)
  setShowDetails(true)
  setShowEditForm(false)
 }

 function clearResponseMessage() {
  setIsLoading(false)
  setShowFileUpload(true)
  setShowDetails(true)
  setShowResponse(false)
  const reqData = { 'action' : 'get', 'id' : data.requestId }
  const options = {}
  api
    .postCreate('/appt-manage-escrow', options, reqData)
    .then(res => setRequestDetails(res))
    .catch(err => console.log(err))
}

 function updateOrder(inputData) {
  setIsLoading(true)
  setShowEditForm(false)
  const reqData = { 'action' : 'update', 'id' : data.requestId, 'data' : inputData.request, 'updates' : inputData.changedData}
  const options = {}
  api
    .postCreate('/appt-manage-escrow', options, reqData)
    .then(res => displayResponse(res))
    .catch(err => console.log(err))
 }

 function displayResponse(res) {
  const response = res
  setUpdateResponse(response)
  setIsLoading(false)
  setShowResponse(true)
 }

 function clearModal(){
  setShowPopup(false)
 }
 function callDeleteFileService(inputData) {
  const reqData = { 'action' : 'deleteFile', 'id' : data.requestId, 'fileName' : inputData}
  const options = {}
  api
    .postCreate('/appt-manage-escrow', options, reqData)
    .then(res => clearResponseMessage())
    .catch(err => console.log(err))
 }

 function deleteFile(e){
  //callDeleteFileService(e)
  var messageToSend = 'Are you sure you wish to delete ' + e + '?'
  setPassedMessage(messageToSend )
  setPassedObject(e)
  setShowPopup(true)
  const btnRef = document.getElementById(e)
  btnRef.blur()
  console.log(e)
 }

 function confirmAction(e){
  callDeleteFileService(e)
 }
 function setAction(passedAction) {
  if (passedAction === 'write') {
    return 'Uploaded'
  }
  else {
    return 'Downloaded'
  }
}   
function convertTimeToAMPM(time24, time_TBD) {
    if (time_TBD){
       return 'TBD'
    }
    var hours = parseInt(time24.substr(0, 2));
    var minutes = parseInt(time24.substr(3));
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    var minutesStr = minutes < 10 ? '0' + minutes : minutes;
    var time12 = hours + ':' + minutesStr + ' ' + ampm;
    return time12;
    }
    function convertToSimpleDateMessage(updatedTime) {
      const date = new Date(updatedTime*1000)
      const dateString = date.toLocaleString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      })
      return dateString
    }
    const getTempUrl = (e) => {
        const options = {}
        const fileData = {'unique_id' : data.requestId, 'file_name' : e }
        api
            .postCreate('/get-stored-file', options, fileData)
            .then(res => window.open(res.get_url,"_blank"))
            .catch(err => console.log(err))
      }
    

  return(
   <div className="card-body bg-light border-secondary">
    {showDetails && (<div>
    {requestDetails.map((items =>
            <div>
                <h5 className='card-title'>Details</h5>
                <div className='card-body'>
                <div className="row mt-1">
                  <div className='col-2 mx-3'><h6>File Number:</h6></div>
                  <div className='col mx-3'>{items.fileNumber}</div>
                </div>
                <div className="row mt-1">
                  <div className='col-2 mx-3'><h6>Status:</h6></div>
                  <div className='col mx-3'>{items.Status}</div>
                </div>
                <div className="row mt-1">
                  <div className='col-2 mx-3'><h6>Signer Name:</h6></div>
                  <div className='col mx-3'>{items.appt_contactName}</div>
                </div>
                <div className="row mt-1">
                  <div className='col-2 mx-3'><h6>Location:</h6></div>
                  <div className='col mx-3'>{items.appt_street}, {items.appt_city}, {items.appt_state} {items.appt_zip}</div>
                </div>
                <div className="row mt-1">
                  <div className='col-2 mx-3'><h6>Date and Time:</h6></div>
                  <div className='col mx-3'>{dateFormat(items.appt_date)} at {convertTimeToAMPM(items.appt_time, items.appt_time_TBD)} {items.appt_timeZone}</div>
                </div>
                <div className="row mt-1">
                  <div className='col-2 mx-3'><h6>Assigned Signing Agent:</h6></div>
                  <div className='col mx-3'>{items.Notary}</div>
                </div>
                <div className="row mt-1">
                  <div className='col-2 mx-3'><h6>Escrow Officer:</h6></div>
                  <div className='col mx-3'>{items.appt_escrow}</div>
                </div>
                <div className="row mt-1">
                  <div className='col-2 mx-3'><h6>Additional Notes:</h6></div>
                  <div className='col mx-3'>{items.comments}</div>
                </div>
                <div className="row mt-1">
                  <div className="col-2 mx-3"><h6>Invoice:</h6></div>
                  <div className="col-2 mx-3"><button className="list-group-item border-light bg-light text-primary text-left" title="Invoice File" type="button" onClick={() => { getInvoice(items.invoiceFileName) }}>{items.invoiceFileName}</button></div>
              </div>
                <div className="row mt-3">
                 <div className='col mx-3'><h6>File Attachment List:</h6></div>
                </div>
                <div className="card bg-light mb-2">
                    {items.fileLogInfo.map(o =>
                        <div className="card-body border">
                        <div className="row">
                          <button className="btn-light btn mt-3 mb-4 mx-5" title="Delete File" id={o.fileName} style={{ backgroundImage: "url(/img/trash.svg)", backgroundSize: 'cover', backgroundRepeat: "no-repeat", width: '14px', height: '28px'}}  onClick={() => deleteFile(o.fileName)}></button>
                          <button className="list-group-item border-light bg-light text-left text-decoration-underline text-primary" title="Download File" type="button" onClick= {() => {getTempUrl(o.fileName)}}>{o.fileName}</button>
                        </div>
                        <div className="card-footer">
                        {o.fileReadInfo.map(info =>
                         <div> <p className="small">{setAction(info.action)} by {info.user} - {convertToSimpleDateMessage(info.loggedTime)} <GeneralUploadStatus confirm ={info.confirmed} action = {info.action}/></p></div>
                         )}
                         </div>
                        </div>
                        )}
                </div>
                {showFileUpload && 
                <Fragment>
                  <GeneralFileUpload requestId={data.requestId} clearResponseMessage = {clearResponseMessage}/>
                </Fragment>}
                <hr/>
                <div class-className='row mt-1'>
                  <button className="btn btn-md btn-secondary border mx-3 rounded" type="button" onClick={() => editRequest(items)}>Edit Order Details</button>
                  <hr/>
                </div>
                </div>
                <Fragment>
                  <ApptNotesHandler requestId={data.requestId}/>
                  {showPopup && <PopupOverlay clearModal = {clearModal} confirmAction = {confirmAction} passedMessage = {passedMessage} passedObject = {passedObject}/>}
                </Fragment>

              </div>))
  }      
</div>
  )}
  {showResponse &&
   <div>
    {updateResponse.map((item =>
      <div>
      <div>
        {item.message}
      </div>
      <div class-className='row mt-3'>
        <hr/>
        <button className="btn btn-md btn-secondary border mx-3 rounded" type="button" onClick={() => clearResponseMessage()}>Return to Order Details</button>
      </div>
      </div>
    ))}
    </div>
  }
  {showEditForm && <OrigEditRequest items={passedData} cancelEdit = {clearEditRequest} updateOrder = {updateOrder}/>
  }
  {isLoading && 
    (<div>
      <div className="d-flex justify-content-center mb-4 mt-4">
      Please wait while we update the order and send out notifications... 
      </div>
    <div class="d-flex justify-content-center mb-4 mt-4">
      <div className="spinner-border" role="status">
        <span className="visually-hidden"></span>
      </div>
     </div>
     </div>)}
  </div>)}

export default OrigViewRequest;