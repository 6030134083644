import React, { Fragment, useEffect } from "react";
import mixpanel from "mixpanel-browser";
import { Hero, HomeContent } from "../components";

const Home = () => {
  
  useEffect(() => {
    mixpanel.track('Home Page', {'action' : 'pageview'})
}, [,])
  
  return (
  <Fragment>
    <Hero />
    <hr />
    <HomeContent />
  </Fragment>
);}

export default Home;
