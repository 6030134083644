// src/components/originator-pending.js

import React, { useState, Suspense, useEffect, Fragment} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { RestHelper } from '../helper/restHelper'
import { OrigViewRequest, ViewNotary } from '../components'



const OrigPending = (profileEmail) => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [sendApi, setSendApi] = useState(false)
  const [formInput, setformInput] = useState('')
  const [message, setApiRes] = useState([])
  const [viewState, setViewState] = useState(false)
  const [viewRequest, setViewRequest] = useState(false)
  const [requestId, setRequestId] = useState('')
  const [notaryId, setNotaryId] = useState('')
  const [viewNotary, setViewNotary] = useState(false)


  const data = {'activeUser': profileEmail.profileEmail, 'action' : profileEmail.data }
 
  const api = RestHelper()
 
  useEffect (() => {
    changeView();
  }, []);

  // if Check Pending Requests is selected, we'll query for pending requests

  const changeView = () => {
    if (viewState){
        setViewState(false);
        setViewRequest(false)
        setViewNotary(false)
        setApiRes([])
    }
    else{
    setViewState(true);
    setViewRequest(false)
    setViewNotary(false)
    const options = {}
    api
        .postCreate('/appt-manage-escrow', options, data)
        .then(res => setApiRes(res))
        .catch(err => console.log(err))
    }
  }

  const getRequest = (data) => {
    setRequestId(data.RequestId)
    setViewRequest(true);
    setViewState(false)
  }

  function dateFormat (inputDate) {
    const dateArray = inputDate.split("-")
    const date = dateArray[1] + '/' + dateArray[2] + '/' + dateArray[0]
    return date
  }

  function convertTimeToAMPM(time24, time_TBD) {
    if (time_TBD){
      return 'TBD'
    }
    var hours = parseInt(time24.substr(0, 2));
    var minutes = parseInt(time24.substr(3));
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    var minutesStr = minutes < 10 ? '0' + minutes : minutes;
    var time12 = hours + ':' + minutesStr + ' ' + ampm;
    return time12;
  }

  const getNotaryDetails = (data) => {
    setNotaryId(data.NotaryID)
    console.log('the data is',data.NotaryID, notaryId)
    setViewNotary(true);
    setViewState(false)
  }
  

  return(
   <div className="container flex-grow-1">
  {viewRequest
    ? (<div className='container mb-3 border rounded bg-light'>
        <div className='row rounded bg-white'>
            <button className="close ml-3 mb-2 mt-2" aria-label="Close" onClick={() =>changeView()}>
                <span aria-hidden='false'>&times;</span>
            </button>
        </div>
        <Fragment>
            <OrigViewRequest requestId={requestId} status={profileEmail.data}/>
         </Fragment>
        </div>)
    : <div></div>
    }
    {viewNotary
    ? (<div className='container mb-3 border rounded bg-light'>
        <div className='row rounded bg-white'>
            <button className="close ml-3 mb-2 mt-2" aria-label="Close" onClick={() =>changeView()}>
                <span aria-hidden='false'>&times;</span>
            </button>
        </div>
        <Fragment>
            <ViewNotary notaryId={notaryId}/>
         </Fragment>
        </div>)
    : <div></div>
    }
  {viewState && (
        <div className="mt-5">
          <h6 className="muted">Here are the {profileEmail.data} requests:</h6>
          <div className="container-fluid rounded">
            <div className="row">
              <table className="table">
              <tbody>
                <tr scope="row">
                <th scope="col">Date</th>
                <th scope="col">Time</th>
                <th scope="col">File Number</th>
                <th scope="col">Escrow Officer</th>
                <th scope="col">Signer Name</th>
                <th scope="col">Status</th>
                <th scope="col">Assigned Signing Agent</th>
                </tr>
                {message.map((items =>
                    <tr scope="row">
                        <td><button className='btn btn-link' type='button' onClick={() => getRequest(items)}>{dateFormat(items.appt_date)}</button></td>
                        <td>{convertTimeToAMPM(items.appt_time, items.appt_time_TBD)} {items.appt_timeZone}</td>
                        <td>{items.fileNumber}</td>
                        <td>{items.appt_escrow}</td>
                        <td>{items.appt_contactName}</td>
                        <td>{items.Status}</td>
                        <td>{items.Notary}</td>

                    </tr>
                ))}
              </tbody></table>
            </div>
          </div>
        </div>
      )}
</div>
  )
};

export default OrigPending;