import React from "react";

const Footer = () => (
  <footer className="bg-light p-3 text-center">
    <div className="navbar-brand mb-2" />
    <p>
      Pinpoint Field Services, LLC
      <br/>
      PO Box 541026
      <br/>
      Omaha, NE 68154
      <br/>
      (888) 530-7592
      <br/>
      <p className='fas mr-2 mx-3'>Follow us on LinkedIn!</p>
      <a href="https://www.linkedin.com/company/pinpoint-field-services" target="_blank" rel="noopener noreferrer">
      <img src="..\img\In-Blue-14@2x.png" alt="LinkedIn Logo" />
    </a>
    </p>
  </footer>
);

export default Footer;
