import React from "react";

//const logo = "https://cdn.auth0.com/blog/auth0-react-sample/assets/logo.png";
//const logo = "/img/radiant_tech_logo.jpg";
const logo = "/img/office-pic.jpg";

const HeroAboutUs = () => (
  <div className="d-flex flex-column h-100">
  <div className="rounded bg-white mx-4 mt-4">
    <h3 className="text-start mt-2 mb-2">Our History</h3>
    <p className="lead mx-3">
    Michael Gibbons had a vision.
    </p>
    <p className="lead mx-3">
    With over twenty years as a Signing Agent himself, he knew that behind every successful closing
    was a knowledgeable, detail-oriented Signing Agent with the passion to ensure the deal got done.  
    </p>
    <p className="lead mx-3">
    His vision was to build a platform that ensured every closing was
    handled by these types of Signing Agents.
    </p>
    <p className="lead mx-3">
    With that vision in mind, Gibbons partnered with an experienced technology expert to build a platform that was designed with security at its foundation while 
    focusing on the user experience to enable ease of use.
    </p>
    <p className="lead mx-3">
    That vision became Pinpoint Field Services, launched out of Omaha, Nebraska in the spring of 2023.
    </p>
    <h3 className="text-start mt-2 mb-2">Today</h3>
    <p className="lead mx-3">
    Pinpoint Field Services is proud of its secure and intuitive web portal that allows Escrow Officers to easily connect with our database of over 8,000 Signing Agents to work together 
    to easily manage every closing.  
    </p>
    <p className="lead mx-3">
    And we ensure that we work with the best Signing Agents by paying a fair fee and prompt payment.  
    </p>
    <p className="lead mx-3">
    But don't just take our word for it, check out what our valued partners
    have to say:
    </p>
    <p className="lead mx-3">
    <a href="https://www.google.com/search?q=pinpoint+field+services" target="_blank">Google Reviews for Pinpoint Field Services</a>
    </p>
  </div>
</div>
);

export default HeroAboutUs;